export const DESCRIPTION_PRIMITIVES = {
    BOOLEAN: 'boolean',
    STRING: 'string',
    NUMBER: 'number',
    DATE: 'date',
    ARRAY: 'array',
    RECTANGLE: 'rectangle',
    OBJECT: 'object',
};
export function getPropertyTypePrimitiveDefault(type) {
    if (type === 'array')
        return [];
    if (type === 'boolean')
        return false;
    if (type === 'number')
        return 0;
    if (type === 'object')
        return {};
    if (type === 'string')
        return '';
    // TODO: not sure about date or rectangle.
    return;
}
// TODO:
export var Designer;
(function (Designer) {
})(Designer || (Designer = {}));
