/**
 * Returns only the truthy values in a list.
 */
export const filterT = (arr) => arr.filter(Boolean);
/**
 * Returns a handler that will pass in `true` as the first arg to a wrapped function.
 */
export const withT = (fn) => (...args) => fn(true, ...args);
/**
 * Returns a handler that will pass in `false` as the first arg to a wrapped function.
 */
export const withF = (fn) => (...args) => fn(false, ...args);
/**
 * Returns a handler that will pass in `null` as the first arg to a wrapped function.
 */
export const withNull = (fn) => (...args) => fn(null, ...args);
/**
 * Returns a handler that will toggle its first arg (boolean) and pass it as the first arg to a wrapped function.
 */
export const toggle = (fn) => (v, ...args) => fn(!v, ...args);
