export const tagTheme = {
    variants: {
        solid: {
            borderColor: 'white',
        },
    },
    sizes: {
        lg: {
            container: {
                minH: 30,
            },
            label: {
                fontSize: 'sm',
            },
        },
    },
};
