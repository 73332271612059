import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Migrate mixins -> effects',
    migrate(state) {
        const runtimeVersion = state.runtimeVersion || 0;
        // Migrate mixins -> effects
        if (runtimeVersion < 16) {
            forEachObject(state, (obj) => {
                if (!obj.componentType || !obj.mixins) {
                    return;
                }
                obj.effects = obj.mixins || [];
                delete obj.mixins;
            });
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
