import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
function forEachBlock(block, callback) {
    callback(block);
    if (Array.isArray(block.blocks)) {
        for (const child of block.blocks) {
            forEachBlock(child, callback);
        }
    }
}
function forEachBlocks(blocks, callback) {
    for (const child of blocks) {
        forEachBlock(child, callback);
    }
}
const migration = {
    description: 'Splits the "begincontact" and "endcontact" events into "beginoverlap" and "endoverlap" events',
    async migrate(state) {
        const physicsObjectIds = {};
        forEachObject(state, (obj) => {
            physicsObjectIds[obj[ID]] = hasPhysics(obj);
        });
        forEachObject(state, (obj) => {
            if (!obj.blocks)
                return;
            forEachBlocks(obj.blocks, (block) => {
                // only migrate trigger blocks
                if (block.type !== 'trigger') {
                    return;
                }
                if (block.event === 'begincontact' || block.event === 'endcontact') {
                    const targetId = block.targetId === -1 ? obj[ID] : block.targetId;
                    let isPhysicsEvent = false;
                    if (physicsObjectIds[targetId]) {
                        isPhysicsEvent = true;
                    }
                    if (block.matcher?.type === 'collisionEventObject') {
                        const matchTargetId = block.matcher?.type === 'collisionEventObject' && block.matcher.targetId === -1
                            ? obj[ID]
                            : block?.matcher?.targetId;
                        // if either the target or the matchTarget is a non-physics object, then it's a non-physics overlap event
                        isPhysicsEvent = isPhysicsEvent && physicsObjectIds[matchTargetId];
                    }
                    if (isPhysicsEvent)
                        return;
                }
                if (block.type === 'trigger' && block.event === 'begincontact') {
                    block.event = 'beginoverlap';
                }
                else if (block.type === 'trigger' && block.event === 'endcontact') {
                    block.event = 'endoverlap';
                }
            });
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
function hasPhysics(component) {
    if (component && component?.parent?.physicsEnabled && component?.parent?.defaultType !== 'none')
        return true;
    return !!(component &&
        component.effects &&
        component.effects.find((e) => e.componentType === 'Play Kit/PhysicsProperties'));
}
