export function forEachView(view, callback, nestingLevel = 0) {
    callback(view, nestingLevel);
    if (view.children) {
        for (const child of view.children) {
            forEachView(child, callback, nestingLevel + 1);
        }
    }
}
// recursive reducing of a view
export function reduceView(view, callback, acc) {
    const ret = callback(acc, view);
    if (view.children) {
        for (const child of view.children) {
            reduceView(child, callback, ret);
        }
    }
    return acc;
}
