export default {
    transparent: 'transparent',
    current: 'currentColor',
    black: '#000000',
    white: '#FFFFFF',
    internalTool: '#A272E8',
    underConstruction: '#FF9103',
    primary: {
        '50': '#F0F4FE', // Not in Figma
        '100': '#DEE7FE',
        '200': '#CBD9FE',
        '300': '#A6BDFD',
        '400': '#82A1FD',
        '500': '#5D84FD',
        '600': '#395BC9',
        '700': '#1D3995',
        '800': '#0B1F61',
        '900': '#010B2D', // Not in Figma
    },
    whiteAlpha: {
        '50': 'rgba(255, 255, 255, 0.04)',
        '100': 'rgba(255, 255, 255, 0.06)',
        '200': 'rgba(255, 255, 255, 0.08)',
        '300': 'rgba(255, 255, 255, 0.16)',
        '400': 'rgba(255, 255, 255, 0.24)',
        '500': 'rgba(255, 255, 255, 0.36)',
        '600': 'rgba(255, 255, 255, 0.48)',
        '700': 'rgba(255, 255, 255, 0.64)',
        '800': 'rgba(255, 255, 255, 0.80)',
        '900': 'rgba(255, 255, 255, 0.92)',
    },
    blackAlpha: {
        '50': 'rgba(0, 0, 0, 0.04)',
        '100': 'rgba(0, 0, 0, 0.06)',
        '200': 'rgba(0, 0, 0, 0.08)',
        '300': 'rgba(0, 0, 0, 0.16)',
        '400': 'rgba(0, 0, 0, 0.24)',
        '500': 'rgba(0, 0, 0, 0.36)',
        '600': 'rgba(0, 0, 0, 0.48)',
        '700': 'rgba(0, 0, 0, 0.64)',
        '800': 'rgba(0, 0, 0, 0.80)',
        '900': 'rgba(0, 0, 0, 0.92)',
    },
    gray: {
        '50': '#F2F2F2',
        '100': '#E7E7E7',
        '200': '#DCDCDC',
        '300': '#CECECE',
        '400': '#A6A5A5',
        '500': '#8B8989',
        '600': '#666464',
        '700': '#4A4A4A',
        '800': '#2E2D30',
        '900': '#1B1A1D',
        '950': '#151B27', // TODO: Needs updating, was not in the spreadsheet 09-10-2024
    },
    grayRgb: {
        '50': '242, 242, 243',
        '100': '235, 236, 237',
        '200': '203, 205, 210',
        '300': '178, 182, 189',
        '400': '154, 162, 176',
        '500': '111, 122, 144',
        '600': '79, 88, 105',
        '700': '57, 67, 86',
        '800': '45, 54, 73',
        '900': '29, 37, 52',
    },
    red: {
        '50': '#FFF0EE',
        '100': '#FFE1DD',
        '200': '#FFD2CC',
        '300': '#FEC3BB',
        '400': '#FEA095',
        '500': '#FD6E5A',
        '600': '#ED5843',
        '700': '#CB4230',
        '800': '#AA2D1E',
        '900': '#891A0E',
    },
    yellow: {
        '50': '#FFFEF5',
        '100': '#FEFDEB',
        '200': '#FEFCD5',
        '300': '#FDFABC',
        '400': '#FDF9A0',
        '500': '#FCF77D',
        '600': '#E1DD70',
        '700': '#C3BF61',
        '800': '#9F9C4F',
        '900': '#716E38',
    },
    green: {
        '50': '#F2FBE9',
        '100': '#E5F7D3',
        '200': '#D8F3BD',
        '300': '#CBEFA7',
        '400': '#ВЗЕВ82',
        '500': '#9AE75D',
        '600': '#86D04B',
        '700': '#72BA3A',
        '800': '#5EA42A',
        '900': '#4A8E1B',
    },
    blue: {
        '50': '#F0F5FF',
        '100': '#E1EBFF',
        '200': '#D2E2FF',
        '300': '#C3D8FF',
        '400': '#A0BFFE',
        '500': '#789EFC',
        '600': '#668AEA',
        '700': '#5376D8',
        '800': '#4062C6',
        '900': '#2D4EB4',
    },
    purple: {
        '50': '#F7F0FF',
        '100': '#EEE1FF',
        '200': '#E5D2FF',
        '300': '#DCC3FF',
        '400': '#CCA5FF',
        '500': '#B686FF',
        '600': '#A272E8',
        '700': '#7A5DC2',
        '800': '#6649AB',
        '900': '#523594',
    },
    pink: {
        '50': '#FFF0FB',
        '100': '#FFE1F7',
        '200': '#FFD2F3',
        '300': '#FEC3EF',
        '400': '#FDA4EC',
        '500': '#FC83E9',
        '600': '#EA61D0',
        '700': '#D23EB1',
        '800': '#BA1B92',
        '900': '#A20073',
    },
};
