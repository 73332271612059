//import colors from './colors.js';
export default {
    internalToolStyle: {
        position: 'absolute',
        top: 6,
        right: 2,
        borderRadius: '50%',
        width: 8,
        height: 8,
        //backgroundColor: colors.internalTool,
        backgroundColor: '#A272E8', // TODO: Hardcoded until I can make Storybook happy with the above import.
    },
    underConstructionStyle: {
        position: 'absolute',
        top: 6,
        right: 2,
        width: 8,
        height: 8,
        borderRadius: '50%',
        //backgroundColor: colors.underConstruction,
        backgroundColor: '#FF9103', // TODO: Hardcoded until I can make Storybook happy with the above import.
    },
};
