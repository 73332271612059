import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["message"];
var __jsx = React.createElement;
import { Box, Text } from '@playful/design_system';
import React from 'react';

// import hatchSpinner from '../assets/hatchAnimated.gif';
import Badge from '../assets/badge.svg';
export function HatchSpinner(_ref) {
  var message = _ref.message,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Box, _extends({
    display: "flex",
    height: "100vh",
    flexDir: "column",
    alignItems: "center",
    justifyContent: "center"
  }, props), __jsx(Badge, {
    width: "194px",
    height: "196px"
  }), __jsx(Text, {
    mt: 2,
    fontSize: "large",
    fontWeight: "bold"
  }, message));
}