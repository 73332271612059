export const playContentMimeType = 'application/play-content';
export const playComponentMimeType = 'application/play-component';
export const playResourceMetaDataMimeType = 'application/play-resource-meta';
export const playCustomKitMimeType = 'application/play-custom-kit';
export class AddContentEvent {
    content;
    onDone;
    constructor(content, onDone) {
        this.content = content;
        this.onDone = onDone;
    }
}
export class AddPromptContentEvent {
    renderer;
    componentType;
    onDone;
    multiple;
    promptTitle;
    existingContent;
    constructor(renderer, componentType, onDone, multiple, promptTitle, existingContent) {
        this.renderer = renderer;
        this.componentType = componentType;
        this.onDone = onDone;
        this.multiple = multiple;
        this.promptTitle = promptTitle;
        this.existingContent = existingContent;
    }
}
export class DblClickComponentEvent {
    component;
    constructor(component) {
        this.component = component;
    }
}
export function isRootViewContainer(component) {
    return component?.name === 'rootViewContainer';
}
export const EmbedModalRenderToken = '__embed_modal__';
export const UploadModalRenderToken = '__upload_modal__';
export const AddMediaChildrenToken = '__add_media_children__';
export const HTMLAIModelRenderToken = '__aicomponent_modal__';
