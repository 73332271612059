import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { joinPath } from '@playful/utils';
export function buildProjectIdEditRoute(id) {
  return joinPath(['/edit', id]);
}
export function buildProjectEditRoute(info) {
  if (info.slug && info.published) {
    return buildUserRoute(info.ownerName, info.slug, 'edit');
  }
  return buildProjectIdEditRoute(info.id);
}
export function buildUserRoute(userName, slug) {
  var action = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var base = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var validOnly = ["@".concat(userName), slug, action].filter(Boolean);
  return joinPath(["/".concat(base)].concat(_toConsumableArray(validOnly)));
}
export function buildAnonUserRoute(slug) {
  var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var base = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var validOnly = ["hatchling", slug, action].filter(Boolean);
  return joinPath(["/".concat(base)].concat(_toConsumableArray(validOnly)));
}