import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Fix animations broken by fixing the double mount bug',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (!obj.componentType || !obj.interactions) {
                return;
            }
            // Fix up the broken animations
            for (const interaction of obj.interactions) {
                if (interaction.key === 'bba6fe82-dfc4-4949-99d3-041b261558e8' &&
                    interaction.trigger.event === 'mount' &&
                    interaction.actions) {
                    for (const action of interaction.actions) {
                        if (action.key === 'd0383a12-a88e-4ccd-b38c-2762160c0ef7' &&
                            action.method === 'setProperty') {
                            action.args.value.value = action.args.value.value
                                .replace('-25', '-50')
                                .replace('+25', '+50');
                        }
                    }
                }
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
