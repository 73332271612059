import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var _excluded = ["delay", "interval"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createGenericContext } from '@playful/utils';
import React, { useEffect, useState } from 'react';
import { HatchSpinner } from './components/HatchSpinner';
import { useRandLoadingMsg } from './utils/getRandomLoadingMsg';
// pass in either children, or a message to display.
export function Loading(_ref) {
  var message = _ref.message,
    delay = _ref.delay;
  var _useState = useState(false),
    display = _useState[0],
    setDisplay = _useState[1];
  var _useLoadingContext = useLoadingContext(),
    _useLoadingContext$me = _useLoadingContext.message,
    ctxMsg = _useLoadingContext$me === void 0 ? 'Loading...' : _useLoadingContext$me,
    _useLoadingContext$de = _useLoadingContext.delay,
    ctxDelay = _useLoadingContext$de === void 0 ? 0 : _useLoadingContext$de,
    startCycling = _useLoadingContext.startCycling,
    stopCycling = _useLoadingContext.stopCycling;
  useEffect(function () {
    var timeout = setTimeout(function () {
      return setDisplay(true);
    }, delay !== null && delay !== void 0 ? delay : ctxDelay);
    return function () {
      return clearTimeout(timeout);
    };
  }, [delay, ctxDelay]);

  // start and stop rotation through random loading messages once we're displaying
  useEffect(function () {
    if (!display) return;
    startCycling();
    return stopCycling;
  }, [startCycling, stopCycling, display]);

  // return display ? <HatchSpinner message={message ?? ctxMsg} /> : null;
  return __jsx(HatchSpinner, {
    message: message !== null && message !== void 0 ? message : ctxMsg
  });
}
var _createGenericContext = createGenericContext(),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  hook = _createGenericContext2[0],
  GenericProvider = _createGenericContext2[1];
export var useLoadingContext = hook;

/** A light wrapper over the random message generator */
export function LoadingProvider(_ref2) {
  var _ref2$delay = _ref2.delay,
    delay = _ref2$delay === void 0 ? 400 : _ref2$delay,
    _ref2$interval = _ref2.interval,
    interval = _ref2$interval === void 0 ? 2500 : _ref2$interval,
    props = _objectWithoutProperties(_ref2, _excluded);
  var randLoadingMsgReturn = useRandLoadingMsg({
    interval: interval
  });
  return __jsx(GenericProvider, _extends({
    value: _objectSpread({
      delay: delay
    }, randLoadingMsgReturn)
  }, props));
}