import { customEvent } from './customEvent.js';
const SIGN_IN_SUCCESS_EVENT = 'signin-success';
const SIGN_IN_ERROR_EVENT = 'signin-error';
const SIGN_UP_SUCCESS_EVENT = 'signup-success';
const SIGN_UP_ERROR_EVENT = 'signup-error';
const ANONYMOUS_SIGN_UP_SUCCESS_EVENT = 'anon-signup-success';
const ANONYMOUS_SIGN_UP_ERROR_EVENT = 'anon-signup-error';
const ANONYMOUS_SIGN_IN_SUCCESS_EVENT = 'anon-signin-success';
const ANONYMOUS_SIGN_IN_ERROR_EVENT = 'anon-signin-error';
export function onAuthSuccess(props, isNewUser) {
    customEvent(props.method === 'anonymous'
        ? isNewUser
            ? ANONYMOUS_SIGN_UP_SUCCESS_EVENT
            : ANONYMOUS_SIGN_IN_SUCCESS_EVENT
        : isNewUser
            ? SIGN_UP_SUCCESS_EVENT
            : SIGN_IN_SUCCESS_EVENT, props);
}
export function onAuthError(props, isNewUser) {
    customEvent(props.method === 'anonymous'
        ? isNewUser
            ? ANONYMOUS_SIGN_UP_ERROR_EVENT
            : ANONYMOUS_SIGN_IN_ERROR_EVENT
        : isNewUser
            ? SIGN_UP_ERROR_EVENT
            : SIGN_IN_ERROR_EVENT, props);
}
