import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'html on sandbox transformation',
    migrate(state) {
        const runtimeVersion = state.runtimeVersion || 0;
        // 13 & 14 are skipped because there was overlap during development
        if (runtimeVersion < 15) {
            // html on sandbox transformation
            forEachObject(state, (component) => {
                // this is a rarity, but during development, the new HTML component
                // ran alongside the legacy HTML component. During this period, the new
                // HTML component was called HTMLPrime, so this is to cleanup will
                // migrate any of those that it finds.
                if (component.componentType === 'Play Kit/HTMLPrime') {
                    component.componentType = 'Play Kit/HTML';
                }
            });
            forEachObject(state, (component) => {
                if (component.componentType === 'Play Kit/HTML') {
                    // undo the experiment with a legacy templater living in the sandbox
                    if (component.template === 'legacy') {
                        component.template = 'none';
                    }
                    // pre existing html components are opted into a legacy mode which
                    // interpolates the variables in the play side and refreshes
                    // the component for each prop change.
                    if (/{{.*?}}/.test(component.html) ||
                        /{{.*?}}/.test(component.css) ||
                        /{{.*?}}/.test(component.script)) {
                        component.useLegacyInterpolation = true;
                    }
                }
            });
            // Upgrade style to be a component
            forEachObject(state, (obj) => {
                if (!obj.componentType || !obj.style) {
                    return;
                }
                obj.style.componentType = 'Play Kit/Style';
            });
            // Fix overflow
            state.pages?.forEach((page) => {
                if (page.overflow === 'scroll') {
                    page.overflow = 'show';
                }
            });
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
