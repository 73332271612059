import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
export const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
/**
 * "Borrowed" from chakra-ui:
 *
 * React hook to persist any value between renders,
 * but keeps it up-to-date if it changes.
 *
 * @param value the value or function to persist
 */
export function useCallbackRef(fn, deps = []) {
    const ref = useRef(fn);
    useIsomorphicLayoutEffect(() => {
        ref.current = fn;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(((...args) => ref.current?.(...args)), deps);
}
