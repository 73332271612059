import { getConfig } from '@playful/config';
import { logRocketIdentify } from '../services/logrocket.js';
import { gtagIdentify, heapIdentify, rudderIdentify } from './identify.js';
const services = [heapIdentify, gtagIdentify, rudderIdentify, logRocketIdentify];
export function setUserProperties(id, userProperties) {
    if (getConfig().isDevLoggingCustomEvents) {
        console.log('telemetry.setUserProperties:', id, userProperties);
    }
    services.forEach((setUserProperties) => setUserProperties(id, userProperties));
}
