const events = [
    'auxclick',
    'click',
    'dblclick',
    'focusin',
    'focusout',
    'input',
    'textinput',
    'keydown',
    'keypress',
    'keyup',
    'pointerdown',
    'pointermove',
    'pointerout',
    'pointerover',
    'pointerup',
    'touchend',
    'touchmove',
    'touchstart',
    'wheel',
];
let dispose;
export function attachSandboxEventListeners(target, play) {
    dispose?.();
    const listeners = [];
    for (const type of events) {
        const handler = (event) => {
            play.dispatchEvent(serializeEvent(event));
        };
        target.addEventListener(type, handler);
        listeners.push([target, type, handler]);
    }
    dispose = () => {
        for (const [target, type, listener] of listeners) {
            target.removeEventListener(type, listener);
        }
    };
}
export function isPlaySerializedEvent(event) {
    return event && event.eventClass && event.eventType;
}
export function serializeEvent(event) {
    const core = {
        eventType: event.type,
        bubbles: event.bubbles,
        cancelable: event.cancelable,
    };
    if (event instanceof FocusEvent) {
        return {
            eventClass: getEventClass(event),
            ...core,
            detail: event.detail,
        };
    }
    if (event instanceof InputEvent) {
        return {
            eventClass: getEventClass(event),
            ...core,
            data: event.data,
            inputType: event.inputType,
            isComposing: event.isComposing,
        };
    }
    // How is InputEvent different from event.type === 'input'? Learn something new every day:
    // https://stackoverflow.com/questions/63273548/why-input-event-has-event-type-of-event-instead-of-inputevent
    if (event.type === 'input') {
        return {
            eventClass: getEventClass(event),
            ...core,
        };
    }
    if (event instanceof KeyboardEvent) {
        return {
            eventClass: getEventClass(event),
            ...core,
            altKey: event.altKey,
            code: event.code,
            ctrlKey: event.ctrlKey,
            isComposing: event.isComposing,
            key: event.key,
            location: event.location,
            metaKey: event.metaKey,
            repeat: event.repeat,
            shiftKey: event.shiftKey,
        };
    }
    if (event instanceof MouseEvent) {
        return {
            eventClass: getEventClass(event),
            ...core,
            altKey: event.altKey,
            button: event.button,
            buttons: event.buttons,
            clientX: event.clientX,
            clientY: event.clientY,
            ctrlKey: event.ctrlKey,
            metaKey: event.metaKey,
            movementX: event.movementX,
            movementY: event.movementY,
            offsetX: event.offsetX,
            offsetY: event.offsetY,
            pageX: event.pageX,
            pageY: event.pageY,
            screenX: event.screenX,
            screenY: event.screenY,
            shiftKey: event.shiftKey,
            x: event.x,
            y: event.y,
        };
    }
    if (event instanceof PointerEvent) {
        return {
            eventClass: getEventClass(event),
            ...core,
            altKey: event.altKey,
            button: event.button,
            buttons: event.buttons,
            clientX: event.clientX,
            clientY: event.clientY,
            ctrlKey: event.ctrlKey,
            height: event.height,
            isPrimary: event.isPrimary,
            metaKey: event.metaKey,
            movementX: event.movementX,
            movementY: event.movementY,
            offsetX: event.offsetX,
            offsetY: event.offsetY,
            pageX: event.pageX,
            pageY: event.pageY,
            pointerId: event.pointerId,
            pointerType: event.pointerType,
            pressure: event.pressure,
            screenX: event.screenX,
            screenY: event.screenY,
            shiftKey: event.shiftKey,
            tangentialPressure: event.tangentialPressure,
            tiltX: event.tiltX,
            tiltY: event.tiltY,
            twist: event.twist,
            width: event.width,
            x: event.x,
            y: event.y,
        };
    }
    if (
    // thanks safari :-(
    typeof TouchEvent !== 'undefined' &&
        event instanceof TouchEvent) {
        const touchEvent = event;
        return {
            eventClass: getEventClass(touchEvent),
            ...core,
            altKey: touchEvent.altKey,
            changedTouches: serializeTouchList(touchEvent.changedTouches),
            ctrlKey: touchEvent.ctrlKey,
            metaKey: touchEvent.metaKey,
            shiftKey: touchEvent.shiftKey,
            targetTouches: serializeTouchList(touchEvent.targetTouches),
            touches: serializeTouchList(touchEvent.touches),
        };
    }
    if (event instanceof WheelEvent) {
        return {
            eventClass: getEventClass(event),
            ...core,
            deltaMode: event.deltaMode,
            deltaX: event.deltaX,
            deltaY: event.deltaY,
            deltaZ: event.deltaZ,
        };
    }
    throw new Error(`unsupported event type ${event.type}`);
}
export function deserializeEvent(sandbox, serializedEvent) {
    switch (serializedEvent.eventClass) {
        case 'focus': {
            const { eventClass: _, eventType: type, ...initDict } = serializedEvent;
            return new FocusEvent(type, initDict);
        }
        case 'textinput': {
            const { eventClass: _, eventType: type, ...initDict } = serializedEvent;
            return new InputEvent(type, initDict);
        }
        case 'input': {
            const { eventClass: _, eventType: type, ...initDict } = serializedEvent;
            return new Event(type, initDict);
        }
        case 'keyboard': {
            const { eventClass: _, eventType: type, ...initDict } = serializedEvent;
            return new KeyboardEvent(type, initDict);
        }
        case 'pointer': {
            const { eventClass: _, eventType: type, ...initDict } = serializedEvent;
            return new PointerEvent(type, initDict);
        }
        case 'touch': {
            const { eventClass: _, eventType: type, ...initDict } = serializedEvent;
            function setTarget(serializedTouch) {
                return new Touch({
                    ...serializedTouch,
                    target: sandbox,
                });
            }
            const dict = {
                ...initDict,
                changedTouches: initDict.changedTouches.map(setTarget),
                targetTouches: initDict.targetTouches.map(setTarget),
                touches: initDict.touches.map(setTarget),
            };
            if (typeof TouchEvent === 'undefined') {
                // thanks safari :-(
                return new CustomEvent(type, dict);
            }
            return new TouchEvent(type, dict);
        }
        case 'wheel': {
            const { eventClass: _, eventType: type, ...initDict } = serializedEvent;
            return new WheelEvent(type, initDict);
        }
    }
    throw new Error(`unsupported event type ${serializedEvent.eventClass}`);
}
function getEventClass(event) {
    if (event instanceof FocusEvent) {
        return 'focus';
    }
    if (event instanceof InputEvent) {
        return 'textinput';
    }
    if (event.type === 'input') {
        return 'input';
    }
    if (event instanceof KeyboardEvent) {
        return 'keyboard';
    }
    if (event instanceof PointerEvent || event instanceof MouseEvent) {
        return 'pointer';
    }
    if (
    // thanks safari :-(
    typeof TouchEvent !== 'undefined' &&
        event instanceof TouchEvent) {
        return 'touch';
    }
    if (event instanceof WheelEvent) {
        return 'wheel';
    }
    throw new Error(`unsupported event type ${event.type}`);
}
export function serializeTouchList(touchList) {
    const serialized = [];
    for (let i = 0; i < touchList.length; i++) {
        const touch = touchList.item(i);
        if (!touch) {
            continue;
        }
        serialized.push({
            clientX: touch.clientX,
            clientY: touch.clientY,
            identifier: touch.identifier,
            pageX: touch.pageX,
            pageY: touch.pageY,
            screenX: touch.screenX,
            screenY: touch.screenY,
        });
    }
    return serialized;
}
