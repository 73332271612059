export const knownMigrations = new Set();
export const migrations = {};
const DateStampRegex = /([0-9]+)-.*$/;
export function registerMigration(migration, url) {
    const path = new URL(url).pathname;
    const test = DateStampRegex.exec(path);
    if (!test) {
        throw new Error(`unable to load migration ${path}. It does not match the expected format.`);
        return;
    }
    const [, idStr] = test;
    const id = idStr;
    migrations[id] = migration;
    knownMigrations.add(id);
    if (migration.retires) {
        for (const retire of migration.retires) {
            knownMigrations.add(retire);
        }
    }
}
