import { useCallbackRef } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

/**
 * This allows an updating of an initial SSR value with a client-only value.
 *
 * Normally, you could use swr for this with a default value, and update on load,
 * but there are times when you want to use server-side data and switch to
 * client side, sometimes after a synchronous callback or setState.
 *
 * once useClientValue is toggled to true, and the hook detects we're in the client,
 * the value will switch over, and not cause errors with nextjs hydration.
 *
 * NOTE: setting useClientValue back to false will not cause it to switch back to the SSR value.
 */
export function useTransferSSRState(_ref) {
  var SSRVal = _ref.SSRVal,
    clientVal = _ref.clientVal,
    useClientVal = _ref.useClientVal,
    onBrowser = _ref.onBrowser;
  var _useState = useState(false),
    inBrowser = _useState[0],
    setInBrowser = _useState[1];
  var _useState2 = useState(false),
    shouldSwitch = _useState2[0],
    setSwitch = _useState2[1];
  var safeCb = useCallbackRef(onBrowser);
  useEffect(function () {
    if (useClientVal && !shouldSwitch) setSwitch(true);
  }, [useClientVal, shouldSwitch]);

  // runs cb when in client
  useEffect(function () {
    setInBrowser(true);
    safeCb === null || safeCb === void 0 || safeCb();
  }, [safeCb]);
  return shouldSwitch && inBrowser ? clientVal : SSRVal;
}