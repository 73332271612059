import { forEachObject, newSortKey } from '@playful/utils';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Give sections a sortKey if they are missing one',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Page') {
                // First for loop to iterate in reverse order
                // Note until this PR Pages had Sections stored in reverse order, so we must preserve that order
                for (let index = obj.children.length - 1; index >= 0; index--) {
                    const section = obj.children[index];
                    if (section.componentType === 'Play Kit/Section' && section.sortKey === undefined) {
                        let before = null;
                        let after = null;
                        // Second for loop to find sortKey of the sections before and after
                        for (let i = obj.children.length - 1; i >= 0; i--) {
                            const s = obj.children[i];
                            if (i > index && s.sortKey) {
                                before = s.sortKey;
                            }
                            if (i < index && s.sortKey) {
                                after = s.sortKey;
                            }
                        }
                        section.sortKey = newSortKey(before, after);
                    }
                }
            }
        });
    },
};
registerMigration(migration, import.meta.url);
