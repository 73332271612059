import { log, warn } from './debug.js';
export const getProjectMigrator = ({ availableMigrations, knownMigrations, migrations, }) => ({
    async migrateProjectState(state) {
        if (!state.appliedMigrations) {
            state.appliedMigrations = [];
        }
        const newAppliedMigrations = [];
        const appliedMigrations = new Set(state.appliedMigrations || []);
        for (const migrationId of availableMigrations) {
            if (!appliedMigrations.has(migrationId)) {
                const migration = migrations[migrationId];
                log('migrateProject', migrationId);
                try {
                    await migration.migrate(state);
                }
                catch (e) {
                    console.error('Migrator: Error: ', e);
                    throw new Error(`Migration ${migrationId} failed: ${e.message}`);
                }
                if (!migration.draft) {
                    appliedMigrations.add(migrationId);
                }
                newAppliedMigrations.push(migrationId);
                log('applied', migrationId);
            }
        }
        for (const migrationId of Object.keys(appliedMigrations)) {
            if (!migrations[migrationId] && knownMigrations.has(migrationId)) {
                warn(`removing retired migration ${migrationId}`);
                appliedMigrations.delete(migrationId);
            }
        }
        state.appliedMigrations = [...appliedMigrations];
        return newAppliedMigrations;
    },
    isSafeToRunProject(state, errors) {
        if (!state.appliedMigrations) {
            return false;
        }
        for (const migrationId of state.appliedMigrations) {
            if (!knownMigrations.has(migrationId)) {
                errors?.push({ message: `migration ${migrationId} not found` });
                // migration from the future
                return false;
            }
        }
        return true;
    },
});
