import { getConfig } from '@playful/config';
let _lr;
export function logRocketIdentify(id, userProperties) {
    if (!_lr || !id || !userProperties || !userProperties.email)
        return;
    const { username, ...properties } = userProperties;
    const traits = {
        ...properties,
    };
    if (username) {
        traits.name = username;
    }
    _lr.identify(id, traits);
}
export function logRocketTrack(event, properties) {
    _lr?.track(event, properties ?? {});
}
// Prefer this method over logRocketCurrentSessionURL
export function logRocketGetSessionURL(func) {
    _lr?.getSessionURL(func);
}
// May return null if logrocket isn't initialized, you probably want logRocketGetSessionURL
export function logRocketCurrentSessionURL() {
    return _lr?.sessionURL;
}
export function initializeLogRocket() {
    const id = getConfig().logRocketId;
    if (id && !navigator.webdriver) {
        import('logrocket' /* webpackChunkName: "logrocket" */).then((LogRocket) => {
            _lr = LogRocket.default;
            _lr.init(id, {
                mergeIframes: true,
                childDomains: ['*'],
                parentDomain: 'https://hatch.one',
                console: {
                    // LogRocket needs this setting to record 'issues' for errors
                    shouldAggregateConsoleErrors: true,
                },
            });
        });
    }
}
