import { axiosRequest } from './axiosRequest.js';
export async function getCheckoutSession(priceId, returnUrl, returnTo = [], successModal = 'subscribe-success') {
    const { data } = await axiosRequest('create_checkout_session', {
        method: 'POST',
        data: {
            priceId,
            redirectUrlSuccess: `${returnUrl}?subscribeSuccess=true&returnTo=${[
                successModal,
                ...returnTo,
            ].join(',')}`,
            redirectUrlCancel: `${returnUrl}?returnTo=${returnTo.join(',')}`,
        },
    });
    return data.url;
}
export async function getCustomerPortalSession(userId, stripeCustomerId, returnUrl) {
    const { data } = await axiosRequest(`users/${userId}/create_customer_portal_session`, {
        method: 'POST',
        data: {
            stripeCustomerId,
            redirectUrl: returnUrl,
        },
    });
    return data.url;
}
export async function getPrices() {
    const { data } = await axiosRequest(`prices`);
    return data;
}
