import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'move the string condition to a typed object',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.condition && obj.blocks) {
                obj.condition = { type: 'expression', expression: obj.condition };
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
