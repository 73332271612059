import { getConfig } from '@playful/config';
import { sendPageView } from '../services/hubspot.js';
import { logRocketIdentify } from '../services/logrocket.js';
export function rudderIdentify(id, userProperties) {
    const { rudderanalytics } = window;
    if (!rudderanalytics) {
        return;
    }
    if (!id || !userProperties) {
        rudderanalytics.reset();
    }
    else {
        rudderanalytics.identify(id, userProperties);
    }
}
export function heapIdentify(id, userProperties) {
    const { heap } = window;
    if (!heap) {
        return;
    }
    if (!id || !userProperties) {
        heap?.resetIdentity();
        return;
    }
    heap?.identify(id);
    heap?.addUserProperties(userProperties);
}
export function gtagIdentify(id, userProperties) {
    const { gtag } = window;
    if (!gtag) {
        return;
    }
    if (!id || !userProperties) {
        gtag?.('user_id', null);
        gtag?.('user_properties', null);
        return;
    }
    const gaUserTraits = { userid: id, ...userProperties };
    gtag?.('set', 'user_id', id);
    gtag?.('set', 'user_properties', gaUserTraits);
}
export function hubSpotIdentify(id, userProperties) {
    window._hsq?.push([
        'identify',
        {
            id: id,
            ...userProperties,
        },
    ]);
    //  HubSpot needs a pageview to send the identify data
    sendPageView();
}
const services = [heapIdentify, logRocketIdentify, gtagIdentify, rudderIdentify, hubSpotIdentify];
export function identify(id, userProperties) {
    if (getConfig().isDevLoggingCustomEvents) {
        console.log('telemetry.identify:', id, userProperties);
    }
    services.forEach((identify) => {
        identify(id, userProperties);
    });
}
