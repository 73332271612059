import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Set Gradient Effect type to "background" so we can change default to "foreground".',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.effects) {
                for (const effect of obj.effects) {
                    if (effect.componentType === 'Play Kit/BackgroundGradient' && effect.type === undefined) {
                        effect.type = 'background';
                    }
                }
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
