import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Add event args to existing component.javascript events that dont have arguments',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.javascript) {
                const javascriptWithEvent = obj.javascript.replace(/(this\.on\('([a-zA-Z]+)', \(\))/g, "this.on('$2', (event)");
                obj.javascript = javascriptWithEvent;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
