import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Upgrade P5.JS component uses to a version which explicitly uses the classic javascript environment',
    async migrate(state) {
        const p5Import = state?.Components?.['P5js'];
        if (p5Import) {
            p5Import.scriptType = 'application/javascript';
            p5Import.useSandboxDomain = false;
            p5Import.html = p5Import.html
                .replace(/p5\.js\/1\.4\..\/p5\.min\.js/, 'p5.js/1.3.1/p5.min.js')
                .replace(/p5\.js\/1\.4\..\/addons\/p5\.sound\.js/, 'p5.js/0.10.2/addons/p5.sound.min.js')
                .replaceAll(/\ integrity=".*?"/g, '')
                .replaceAll(/\ crossorigin=".*?"/g, '')
                .replaceAll(/\ referrerpolicy=".*?"/g, '');
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
