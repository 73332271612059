/**
 * Migrator
 *
 * Each migration is contained in a .ts file in the migrations folder.
 * See the README for more information.
 *
 */
import './migrations/index.js';
import { getProjectMigrator } from './projectMigration.js';
import { log } from './debug.js';
import { knownMigrations, migrations } from './registry.js';
const availableMigrations = Object.keys(migrations).sort();
const { isSafeToRunProject, migrateProjectState } = getProjectMigrator({
    availableMigrations,
    knownMigrations,
    migrations,
});
log({ migrations, knownMigrations });
export { availableMigrations, isSafeToRunProject, migrateProjectState, };
