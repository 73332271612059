import { getConfig } from '@playful/config';
import { logRocketTrack } from '../services/logrocket.js';
import { rudderstackEvent } from './rudderstackEvent.js';
// Sends a custom event to various services
export function customEvent(name, props) {
    if (getConfig().isDevLoggingCustomEvents || window.location.search.includes('debug_events')) {
        console.debug('telemetry.customEvent:', name, props);
    }
    try {
        const { heap, gtag } = window;
        heap?.track(name, props ?? {});
        gtag?.('event', name, props);
        rudderstackEvent(name, props);
        logRocketTrack(name, props ?? {});
    }
    catch (e) {
        console.warn(`Error sending custom event: ${name}`, props);
    }
}
export function triggerCustomEvent(eventName, eventProps) {
    return () => customEvent(eventName, eventProps);
}
export function formatEventString(value) {
    return value.toLowerCase().replace(' ', '');
}
