import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
function forEachBlock(block, callback) {
    callback(block);
    if (Array.isArray(block.blocks)) {
        for (const child of block.blocks) {
            forEachBlock(child, callback);
        }
    }
}
function forEachBlocks(blocks, callback) {
    for (const child of blocks) {
        forEachBlock(child, callback);
    }
}
const migration = {
    description: 'migrates set properties blocks into presets',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.blocks) {
                forEachBlocks(obj.blocks, (block) => {
                    if (block.type === 'setProperty') {
                        const p = {
                            uid: nanoid(8),
                            // actions is now deprecated, but we still need to migrate it
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            actions: [...(block?.actions ?? [])],
                            ordinal: 0,
                        };
                        block.presets = [p];
                    }
                });
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
// https://github.com/ai/nanoid/blob/main/non-secure/index.js
// This alphabet uses `A-Za-z0-9_-` symbols.
// The order of characters is optimized for better gzip and brotli compression.
// References to the same file (works both for gzip and brotli):
// `'use`, `andom`, and `rict'`
// References to the brotli default dictionary:
// `-26T`, `1983`, `40px`, `75px`, `bush`, `jack`, `mind`, `very`, and `wolf`
const urlAlphabet = 'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict';
const nanoid = (size = 21) => {
    let id = '';
    // A compact alternative for `for (var i = 0; i < step; i++)`.
    let i = size;
    while (i--) {
        // `| 0` is more compact and faster than `Math.floor()`.
        id += urlAlphabet[(Math.random() * 64) | 0];
    }
    return id;
};
registerMigration(migration, import.meta.url);
