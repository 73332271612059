import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
import { forEachObject } from '@playful/utils';
const migration = {
    description: `a previous migration set backgroundImageOpacity to a number between 0 - 1.
    The code in the app set it 0-100.
    I updated the code to be 0 - 1 so this migration is to fix projects with values > 1.`,
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (!!obj.backgroundImageOpacity && obj.backgroundImageOpacity > 1) {
                obj.backgroundImageOpacity = obj.backgroundImageOpacity / 100;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
