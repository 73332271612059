import { boolean } from 'boolean';
export function getConfig() {
    const config = {
        environment: process.env.PLAY_ENV || 'dev',
        gitBranch: process.env.GIT_BRANCH || '',
        apiRoot: '/_/api',
        googleProjectId: process.env.GOOGLE_CLOUD_PROJECT || '',
        firebaseApiKey: process.env.FIREBASE_API_KEY || '',
        firebaseAuthDomain: process.env.FIREBASE_AUTH_DOMAIN || '',
        firebaseDatabaseUrl: process.env.FIREBASE_DATABASE_URL || '',
        firebaseAppId: process.env.FIREBASE_APP_ID || '',
        googleOAuthClientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID || '',
        stripeMonthlyPlan: process.env.NEXT_PUBLIC_STRIPE_MONTHLY || '',
        stripeYearlyPlan: process.env.NEXT_PUBLIC_STRIPE_YEARLY || '',
        hoverBaseUrl: process.env.HOVER_BASE_URL || '',
        // Telemetry
        googleAnalyticsId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '',
        heapAnalyticsId: process.env.NEXT_PUBLIC_HEAP_ID || '',
        logRocketId: process.env.NEXT_PUBLIC_LOGROCKET_ID || '',
        hubSpotId: process.env.NEXT_PUBLIC_HUBSPOT_ID || '',
        rudderStackWriteKey: process.env.RUDDERSTACK_FRONTEND_WRITE || '',
        rudderStackCDNURL: process.env.RUDDERSTACK_FRONTEND_CDN || '',
        rudderStackAPIURL: process.env.RUDDERSTACK_FRONTEND_API || '',
        rudderStackDataPlaneURL: process.env.RUDDERSTACK_FRONTEND_DP || '',
        // Dev logging
        isDevLoggingXstate: boolean(process.env.NEXT_PUBLIC_DEV_LOGGING_XSTATE) || false,
        isDevLoggingCustomEvents: boolean(process.env.NEXT_PUBLIC_DEV_LOGGING_CUSTOM_EVENTS) || false,
        isDevLoggingHoverDesigner: boolean(process.env.NEXT_PUBLIC_DEV_LOGGING_HOVER_DESIGNER) || false,
        // Sanity CMS
        sanityProjectId: process.env.SANITY_STUDIO_PROJECT_ID || '2023-12-06',
        sanityDataset: process.env.SANITY_STUDIO_DATASET || '',
        sanityApiVersion: process.env.SANITY_STUDIO_API_VERSION || '',
        sanityApiReadToken: process.env.SANITY_API_READ_TOKEN || '',
    };
    // Allow the player to set the api root.
    // TODO: replace this with dynamic env vars now that nextjs supports it
    if (typeof window !== 'undefined') {
        config.apiRoot = window?.playful?.apiRoot ?? config.apiRoot;
    }
    return config;
}
let sandboxConfig;
export function getSandboxConfig() {
    if (!sandboxConfig) {
        throw new Error('Please call setSandboxConfig first');
    }
    return sandboxConfig;
}
export function setSandboxConfig(config) {
    sandboxConfig = config;
}
