import { getConfig } from '@playful/config';
import { getFirebaseAuthToken } from './firebase.js';
/**
 * Upload an ArrayBuffer to a URL
 * @param uploadUrl
 * @param buf
 * @param mimeType
 * @param onProgress
 * @param options
 */
export async function uploadFile(uploadUrl, buf, mimeType, onProgress, options) {
    const opts = options ?? {};
    const xhr = new XMLHttpRequest();
    // relative endpoints are relative to the apiRoot
    if (!uploadUrl.startsWith('/')) {
        uploadUrl = `${getConfig().apiRoot}/${uploadUrl}`;
    }
    const urlParts = uploadUrl.split('?');
    const params = new URLSearchParams(urlParts[1]);
    if (opts.noDuplicates) {
        params.append('noDuplicates', 'true');
    }
    const uri = `${urlParts[0]}?${params.toString()}`;
    xhr.open('PUT', uri, true);
    xhr.setRequestHeader('Content-Type', mimeType ?? 'application/octet-stream');
    if (opts.etag) {
        xhr.setRequestHeader('If-Match', opts.etag);
    }
    // Add auth token
    const token = await getFirebaseAuthToken();
    if (token) {
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }
    const promise = new Promise((resolve, reject) => {
        xhr.onerror = function () {
            reject({ status: this.status, statusText: xhr.statusText });
        };
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                const json = JSON.parse(xhr.responseText);
                const uploadedHash = xhr.getResponseHeader('X-Uploaded-Resource-Hash');
                resolve({ uploadedResourceHash: uploadedHash, projectResourceData: json });
            }
            else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText,
                });
            }
        };
    });
    if (onProgress) {
        xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
                onProgress((e.loaded / e.total) * 100);
            }
        };
    }
    xhr.send(buf);
    return promise;
}
