import * as yup from 'yup';
export var authSchema = yup.object({
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required').min(6, 'Password must have at least 6 characters')
}).required();
export var usernameSchema = yup.string().required('Username is required').min(4, 'Username must have at least 4 characters').max(31, 'Username must be less than 32 characters long.').matches(/^\S*$/, 'Username cannot include spaces.').matches(/^[^0-9].*$/, 'Username cannot start with a number').matches(/^.*[^-]$/, 'Username cannot end with a dash').matches(/^[^-].*$/, 'Username cannot start with a dash').matches(/^[a-zA-Z0-9-]*$/, 'Must only have a-z, A-Z, 0-9, and dash characters');
export function getErrorMsg(err) {
  if (!err) {
    return null;
  }
  var code = err.code,
    _err$message = err.message,
    message = _err$message === void 0 ? 'Unknown error' : _err$message;
  var errs = {
    'auth/wrong-password': {
      field: 'password',
      type: 'auth/wrong-password',
      message: 'Wrong password.'
    },
    'auth/weak-password': {
      field: 'password',
      type: 'auth/weak-password',
      message: 'Password is too weak.'
    },
    'auth/user-not-found': {
      field: 'email',
      type: 'auth/user-not-found',
      message: 'User not found.'
    },
    'auth/email-already-in-use': {
      field: 'email',
      type: 'auth/email-already-in-use',
      message: 'Email is already in use.'
    },
    'auth/username-exists': {
      field: 'username',
      type: 'auth/username-exists',
      message: 'User already exists.'
    },
    'auth/user-error': {
      field: 'username',
      type: 'auth/username-exists',
      message: 'Username already exists.'
    },
    'auth/too-many-requests': {
      type: 'other',
      message: 'Account temporarily disabled. Try again later.'
    },
    'auth/claim-error': {
      type: 'other',
      message: message
    },
    'auth/cancelled-popup-request': null,
    // ignore
    'auth/popup-closed-by-user': null,
    // ignore
    'auth/popup-blocked': {
      type: 'other',
      message: 'Please allow popups and try again.'
    },
    other: {
      type: 'other',
      message: message
    }
  };
  var errKey = code;
  if (errs.hasOwnProperty(errKey)) {
    return errs[errKey];
  }
  return errs.other;
}