import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const selfObjectId = -1;
function forEachBlock(block, callback) {
    callback(block);
    if (Array.isArray(block.blocks)) {
        for (const child of block.blocks) {
            forEachBlock(child, callback);
        }
    }
}
function forEachBlocks(blocks, callback) {
    for (const child of blocks) {
        forEachBlock(child, callback);
    }
}
const migration = {
    description: 'Make block references to self use the special self object id',
    async migrate(state) {
        // todo migrate the state!
        forEachObject(state, (obj) => {
            if (obj.blocks) {
                const componentId = obj[ID];
                forEachBlocks(obj.blocks, (block) => {
                    if (block.type === 'trigger') {
                        block.targetId = makeSelfish(block.targetId, componentId);
                    }
                    else if (block.type === 'cond' && block.condition.type === 'conditions') {
                        block.condition.conditions = block.condition.conditions.map((condition) => {
                            return {
                                ...condition,
                                targetId: makeSelfish(condition.targetId, componentId),
                            };
                        });
                        // actions is now deprecated, but we still need to migrate it
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                    }
                    else if (block.type === 'setProperty' && block.actions?.length > 0) {
                        // actions is now deprecated, but we still need to migrate it
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        block.actions = block.actions.map((action) => {
                            return {
                                ...action,
                                targetId: makeSelfish(action.targetId, componentId),
                            };
                        });
                        block.targetId = makeSelfish(block.targetId, componentId);
                    }
                    else if (block.type === 'call') {
                        block.action.targetId = makeSelfish(block.action.targetId, componentId);
                    }
                });
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
function makeSelfish(targetId, triggerTargetId) {
    if (Number(targetId) === Number(triggerTargetId)) {
        return selfObjectId;
    }
    return targetId;
}
