import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'This migration sets the default fit property to "fill" for existing Image/Video components',
    draft: false,
    async migrate(state) {
        /**
         * We are migrating to setting object-fit to 'cover' by default on Image/Video in app,
         * If the 'fit' property is undefined, this migration will set it to 'fill' like the old behavior.
         */
        forEachObject(state, (obj) => {
            if (!['Play Kit/Image', 'Play Kit/Video'].includes(obj.componentType)) {
                return;
            }
            if (!obj.fit) {
                obj.fit = 'fill';
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
