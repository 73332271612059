import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Fix projects whose pages and components have been moved',
    migrate(state) {
        const runtimeVersion = state.runtimeVersion || 0;
        if (runtimeVersion < 12) {
            // It has been possible for Pages and Components to be moved away from
            // the undefined x,y position the Designer expects, leading to shifted positioning
            // of Designer elements (e.g. selection rectangle) and other broken behavior.
            // Hopefully all those bugs have been fixed.
            // This migration repairs projects whose Pages and Components have been moved.
            state.pages?.forEach((page) => {
                delete page.x;
                delete page.y;
            });
            if (state.Components) {
                Object.values(state.Components).forEach((component) => {
                    delete component.x;
                    delete component.y;
                });
            }
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
