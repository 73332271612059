import { isClientCtx } from '@playful/utils';
import { getFirebaseAuthToken, isFirebaseInitialized } from './firebase.js';
export async function getHeaderAuth() {
    const token = await getFirebaseAuthToken();
    return token ? { Authorization: `Bearer ${token}` } : undefined;
}
export async function mergeTokenConfig(config) {
    // don't try to get a token when we're not running on the client (e.g. SSR)
    if (!isClientCtx())
        return config;
    if (!isFirebaseInitialized()) {
        console.warn('Firebase is not initialized yet, skipping mergeTokenConfig');
        return config;
    }
    try {
        const token = await getFirebaseAuthToken();
        const headerAuth = token ? { Authorization: `Bearer ${token}` } : undefined;
        return {
            ...config,
            headers: {
                ...config.headers,
                ...headerAuth,
            },
        };
    }
    catch (e) {
        console.error('Error merging token config', e);
    }
    return config;
}
