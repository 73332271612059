export const alertTheme = {
    variants: {
        toast: (props) => {
            const { status } = props;
            let statusColor = 'blue';
            if (status === 'success') {
                statusColor = 'green';
            }
            else if (status === 'warning') {
                statusColor = 'orange';
            }
            else if (status === 'error') {
                statusColor = 'red';
            }
            const color = `${statusColor}.500`;
            return {
                container: {
                    bgColor: 'gray.800',
                    color: `gray.50`,
                    fontSize: 'sm',
                    fontWeight: 'medium',
                    borderRadius: 'full',
                    mb: 4,
                    textAlign: 'center',
                    boxShadow: 'md',
                    minWidth: 48,
                },
                icon: {
                    color,
                },
            };
        },
    },
};
