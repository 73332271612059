import { ID } from './reactor.js';
import { createReactorArray } from './reactorArray.js';
import { createReactorObject } from './reactorObject.js';
export function createReactorFactory(nextReactorId = 1) {
    return new ReactorFactory(nextReactorId);
}
export class ReactorFactory {
    /** @internal */
    _nextReactorId;
    /** @internal */
    _reactors = {
        0: { [ID]: -1, name: 'invalid Reactor' },
    };
    /** @internal */
    rootReactor;
    constructor(nextReactorId = 1) {
        this._nextReactorId = nextReactorId;
    }
    // TODO: dispose()?
    reactorCount() {
        // account for the 0th/-1th reactor
        return Object.keys(this._reactors).length - 1;
    }
    getNextReactorId() {
        return this._nextReactorId;
    }
    getReactorById(id) {
        const reactor = this._reactors[id];
        if (!reactor) {
            throw new Error(`reactor id ${id} isn't in reactor index`);
        }
        return reactor;
    }
    hasReactor(id) {
        return this._reactors[id] !== undefined;
    }
    // Reactor paths are dot-separated property names, e.g. Components.View.5.whatever
    getReactorByPath(path) {
        const segments = path.split('.');
        let reactor = this.rootReactor;
        if (path === '') {
            return reactor;
        }
        for (const segment of segments) {
            const r = reactor[segment];
            if (r === undefined) {
                return undefined; // Path not found
            }
            reactor = r;
        }
        return reactor;
    }
    forEachReactor(callback) {
        for (const id in this._reactors) {
            const reactor = this._reactors[id];
            if (reactor && reactor[ID] !== -1) {
                if (!callback(reactor)) {
                    // Abort enumeration when callback returns false.
                    return;
                }
            }
        }
    }
    //
    //
    //
    createReactor(objectOrArray, options) {
        if (Array.isArray(objectOrArray)) {
            return createReactorArray(this, objectOrArray, options);
        }
        else {
            return createReactorObject(this, objectOrArray, options);
        }
    }
    // Used by ReactorObject and ReactorArray to add a new reactor with an optional id
    addReactor(reactor, id) {
        if (id) {
            // Be sure generated ids don't overlap existing ids.
            if (id >= this._nextReactorId) {
                this._nextReactorId = id + 1;
            }
        }
        else {
            id = this._nextReactorId++;
        }
        console.assert(this._reactors[id] === undefined, `Reactor id ${id} already in use!\n`, this._reactors[id]);
        this._reactors[id] = reactor;
        return id;
    }
    removeReactor(id) {
        if (id <= 0) {
            console.warn(`Invalid reactor id: ${id}`);
            return;
        }
        delete this._reactors[id];
    }
}
