import { withF, withT } from '@playful/utils';
import { useEffect, useState } from 'react';
var verbs = ['hoisting', 'tokenizing', 'refurbishing', 'tidying', 'reticulating', 'filtering', 'relocating', 'confabulating', 'computing', 'entangling', 'traversing', 'disrupting', 'converging', 'recalculating', 'distinguishing', 'obfuscating', 'integrating', 'minifying', 'stabilizing'];
var subjects = ['the frontend', 'the backend', 'satellites', 'pixels', 'buttons', 'bits', 'tachyons', 'the gravitron', 'crystal assembly', 'the cloud', 'web3', 'the ai', 'splines', 'time and space', 'a PC', 'Windows 95', 'ants', 'subspace', 'polarity'];
function getRandomIdx(arr) {
  return Math.floor(Math.random() * arr.length);
}
export function getRandLoadingMsg() {
  return "".concat(verbs[getRandomIdx(verbs)], " ").concat(subjects[getRandomIdx(subjects)], "...");
}
export function useRandLoadingMsg() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _props$interval = props.interval,
    interval = _props$interval === void 0 ? 2500 : _props$interval;
  var _useState = useState(getRandLoadingMsg()),
    message = _useState[0],
    setMessage = _useState[1];
  var _useState2 = useState(false),
    isCycling = _useState2[0],
    setCycling = _useState2[1];
  var refreshMsg = function refreshMsg() {
    return setMessage(getRandLoadingMsg());
  };
  useEffect(function () {
    if (!isCycling) return;
    var intervalId = window.setInterval(refreshMsg, interval);
    return function () {
      return window.clearInterval(intervalId);
    };
  }, [interval, isCycling]);
  return {
    message: message,
    refreshMsg: refreshMsg,
    startCycling: withT(setCycling),
    stopCycling: withF(setCycling)
  };
}