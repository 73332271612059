export const appToastTheme = {
    baseStyle: {
        bgColor: 'gray.800',
        color: 'gray.50',
        fontSize: 'sm',
        fontWeight: 'medium',
        borderRadius: 'md',
        px: 3,
        py: 2,
        marginBottom: 8,
        textAlign: 'center',
        boxShadow: 'md',
    },
};
