import { toPrecision } from '@playful/utils';
import { Vec2d } from './Vec2d.js';
/** @public */
export class Box2d {
    constructor(x = 0, y = 0, w = 0, h = 0) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }
    x = 0;
    y = 0;
    w = 0;
    h = 0;
    get point() {
        return new Vec2d(this.x, this.y);
    }
    set point(val) {
        this.x = val.x;
        this.y = val.y;
    }
    get minX() {
        return this.x;
    }
    set minX(n) {
        this.x = n;
    }
    get midX() {
        return this.x + this.w / 2;
    }
    get maxX() {
        return this.x + this.w;
    }
    get minY() {
        return this.y;
    }
    set minY(n) {
        this.y = n;
    }
    get midY() {
        return this.y + this.h / 2;
    }
    get maxY() {
        return this.y + this.h;
    }
    get width() {
        return this.w;
    }
    set width(n) {
        this.w = n;
    }
    get height() {
        return this.h;
    }
    set height(n) {
        this.h = n;
    }
    get aspectRatio() {
        return this.width / this.height;
    }
    get center() {
        return new Vec2d(this.midX, this.midY);
    }
    set center(v) {
        this.minX = v.x - this.width / 2;
        this.minY = v.y - this.height / 2;
    }
    get corners() {
        return [
            new Vec2d(this.minX, this.minY),
            new Vec2d(this.maxX, this.minY),
            new Vec2d(this.maxX, this.maxY),
            new Vec2d(this.minX, this.maxY),
        ];
    }
    get snapPoints() {
        return [
            new Vec2d(this.minX, this.minY),
            new Vec2d(this.maxX, this.minY),
            new Vec2d(this.maxX, this.maxY),
            new Vec2d(this.minX, this.maxY),
            this.center,
        ];
    }
    get sides() {
        const { corners } = this;
        return [
            [corners[0], corners[1]],
            [corners[1], corners[2]],
            [corners[2], corners[3]],
            [corners[3], corners[0]],
        ];
    }
    get size() {
        return new Vec2d(this.w, this.h);
    }
    toFixed() {
        this.x = toPrecision(this.x);
        this.y = toPrecision(this.y);
        this.w = toPrecision(this.w);
        this.h = toPrecision(this.h);
        return this;
    }
    setTo(B) {
        this.x = B.x;
        this.y = B.y;
        this.w = B.w;
        this.h = B.h;
        return this;
    }
    set(x = 0, y = 0, w = 0, h = 0) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        return this;
    }
    expand(A) {
        const minX = Math.min(this.minX, A.minX);
        const minY = Math.min(this.minY, A.minY);
        const maxX = Math.max(this.maxX, A.maxX);
        const maxY = Math.max(this.maxY, A.maxY);
        this.x = minX;
        this.y = minY;
        this.w = maxX - minX;
        this.h = maxY - minY;
        return this;
    }
    expandBy(n) {
        this.x -= n;
        this.y -= n;
        this.w += n * 2;
        this.h += n * 2;
        return this;
    }
    scale(n) {
        this.x /= n;
        this.y /= n;
        this.w /= n;
        this.h /= n;
        return this;
    }
    clone() {
        const { x, y, w, h } = this;
        return new Box2d(x, y, w, h);
    }
    translate(delta) {
        this.x += delta.x;
        this.y += delta.y;
        return this;
    }
    snapToGrid(size) {
        const minX = Math.round(this.minX / size) * size;
        const minY = Math.round(this.minY / size) * size;
        const maxX = Math.round(this.maxX / size) * size;
        const maxY = Math.round(this.maxY / size) * size;
        this.minX = minX;
        this.minY = minY;
        this.width = Math.max(1, maxX - minX);
        this.height = Math.max(1, maxY - minY);
    }
    collides(B) {
        return Box2d.Collides(this, B);
    }
    contains(B) {
        return Box2d.Contains(this, B);
    }
    includes(B) {
        return Box2d.Includes(this, B);
    }
    containsPoint(V, y) {
        return Box2d.ContainsPoint(this, V, y);
    }
    toJson() {
        return { x: this.minX, y: this.minY, w: this.w, h: this.h };
    }
    union(box) {
        const minX = Math.min(this.minX, box.x);
        const minY = Math.min(this.minY, box.y);
        const maxX = Math.max(this.maxX, box.x + box.w);
        const maxY = Math.max(this.maxY, box.y + box.h);
        this.x = minX;
        this.y = minY;
        this.width = maxX - minX;
        this.height = maxY - minY;
        return this;
    }
    relativePosition(boxB, orientation) {
        const centerA = this.center;
        const centerB = boxB.center;
        const dx = centerB.x - centerA.x;
        const dy = centerB.y - centerA.y;
        // Calculate the distances to each edge of this box
        const distToTop = Math.abs(boxB.maxY - this.minY);
        const distToRight = Math.abs(boxB.minX - this.maxX);
        const distToBottom = Math.abs(boxB.minY - this.maxY);
        const distToLeft = Math.abs(boxB.maxX - this.minX);
        if (orientation === 'vertical') {
            // Only consider top and bottom
            return dy < 0 ? 'top' : 'bottom';
        }
        else if (orientation === 'horizontal') {
            // Only consider left and right
            return dx > 0 ? 'right' : 'left';
        }
        // If no orientation is specified, proceed with the full logic
        const minDist = Math.min(distToTop, distToRight, distToBottom, distToLeft);
        // Determine the relative position based on the minimum distance and the direction
        if (minDist === distToTop && dy < 0) {
            return 'top';
        }
        else if (minDist === distToRight && dx > 0) {
            return 'right';
        }
        else if (minDist === distToBottom && dy > 0) {
            return 'bottom';
        }
        else if (minDist === distToLeft && dx < 0) {
            return 'left';
        }
        // If boxes are overlapping or exactly aligned, determine based on center positions
        if (Math.abs(dx) > Math.abs(dy)) {
            return dx > 0 ? 'right' : 'left';
        }
        else {
            return dy > 0 ? 'bottom' : 'top';
        }
    }
    static From(box) {
        return new Box2d(box.x, box.y, box.w, box.h);
    }
    static FromPoints(points) {
        if (points.length === 0)
            return new Box2d();
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        let point;
        for (let i = 0, n = points.length; i < n; i++) {
            point = points[i];
            minX = Math.min(point.x, minX);
            minY = Math.min(point.y, minY);
            maxX = Math.max(point.x, maxX);
            maxY = Math.max(point.y, maxY);
        }
        return new Box2d(minX, minY, maxX - minX, maxY - minY);
    }
    static Expand(A, B) {
        const minX = Math.min(B.minX, A.minX);
        const minY = Math.min(B.minY, A.minY);
        const maxX = Math.max(B.maxX, A.maxX);
        const maxY = Math.max(B.maxY, A.maxY);
        return new Box2d(minX, minY, maxX - minX, maxY - minY);
    }
    static ExpandBy(A, n) {
        return new Box2d(A.minX - n, A.minY - n, A.width + n * 2, A.height + n * 2);
    }
    static Collides = (A, B) => {
        return !(A.maxX < B.minX || A.minX > B.maxX || A.maxY < B.minY || A.minY > B.maxY);
    };
    static Contains = (A, B) => {
        return A.minX < B.minX && A.minY < B.minY && A.maxY > B.maxY && A.maxX > B.maxX;
    };
    static Includes = (A, B) => {
        return Box2d.Collides(A, B) || Box2d.Contains(A, B);
    };
    static ContainsPoint = (A, B, y) => {
        if (typeof B === 'number') {
            return !(B < A.minX || y < A.minY || B > A.maxX || y > A.maxY);
        }
        return !(B.x < A.minX || B.y < A.minY || B.x > A.maxX || B.y > A.maxY);
    };
    static Common = (boxes) => {
        let minX = Infinity;
        let minY = Infinity;
        let maxX = -Infinity;
        let maxY = -Infinity;
        for (let i = 0; i < boxes.length; i++) {
            const B = boxes[i];
            minX = Math.min(minX, B.minX);
            minY = Math.min(minY, B.minY);
            maxX = Math.max(maxX, B.maxX);
            maxY = Math.max(maxY, B.maxY);
        }
        return new Box2d(minX, minY, maxX - minX, maxY - minY);
    };
    static Sides = (A, inset = 0) => {
        const { corners } = A;
        if (inset) {
            // TODO: Inset the corners by the inset amount.
        }
        return [
            [corners[0], corners[1]],
            [corners[1], corners[2]],
            [corners[2], corners[3]],
            [corners[3], corners[0]],
        ];
    };
    equals(other) {
        return Box2d.Equals(this, other);
    }
    static Equals(a, b) {
        return b.x === a.x && b.y === a.y && b.w === a.w && b.h === a.h;
    }
}
