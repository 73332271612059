import { getSandboxConfig } from '@playful/config';
import { expose, windowEndpoint, wrap } from 'comlink';
import { ID } from '../reactor.js';
import { getHostApi } from './api.js';
export function connectSandboxToHost(host, bridgeId, label) {
    const { facade } = createMultiplexingEndpointFacade(windowEndpoint(host.parent), bridgeId, label);
    return wrap(facade);
}
export function exposeHostToSandbox(component, sandbox, bridgeId, { onDispatchEvent, }) {
    if (!sandbox.contentWindow) {
        throw new Error("Couldn't connect to sandbox");
    }
    const endpoint = windowEndpoint(sandbox.contentWindow);
    const { facade, listeners } = createMultiplexingEndpointFacade(endpoint, bridgeId, component.name + '-S');
    const { client, host } = getHostApi(component, facade, {
        comlinkListeners: listeners,
        onDispatchEvent: onDispatchEvent,
    });
    expose(client, facade);
    //console.log('[Hatch] Hosting:', component.name);
    return host;
}
/**
 * To provide the neccessary support infrastructure on the client, we parse,
 * augment, and return the html with some additons. This will allow us to
 * achieve most anything we need. If we need a more stateful experience, we
 * might consider replacing direct use of frameSrc, so that it has a persisent
 * javascript process and the HTML is is replaced via a PJAX style call over the
 * wire instead of replacing srcDoc. In the past this has been able to be a
 * pretty clean drop in replacement for srcDoc.
 *
 * @param html
 * @param bridgeId
 * @returns
 */
export function injectClientLibrary(html, bridgeId, { debug, resourceRoot }) {
    if (!html) {
        return undefined;
    }
    const { sandboxRuntime } = getSandboxConfig();
    const parser = new DOMParser();
    const parsed = parser.parseFromString(html, 'text/html');
    const head = parsed.head;
    const base = parsed.createElement('base');
    base.setAttribute('href', resourceRoot);
    head.appendChild(base);
    const client = parsed.createElement('script');
    client.setAttribute('type', 'text/javascript');
    client.setAttribute('src', sandboxRuntime);
    head.prepend(client);
    const config = parsed.createElement('script');
    config.setAttribute('type', 'text/play-config');
    const configText = parsed.createTextNode(JSON.stringify({
        bridgeId,
        debug,
    }));
    config.append(configText);
    head.prepend(config);
    return `
    <!DOCTYPE html>
    ${parsed.documentElement.innerHTML}
  `;
}
/**
 * TODO describe comlink typically being used to expose apis FROM a iframe, so
 * comlink doesn't expect multiple comlink 'message' listeners to all be
 * listening on the host window. The assumption of directionality also means
 * that comlink doesn't attempt to clean up listeners. It assumes that iframes
 * and workers will terminate at some point and listeners will be cleaned up
 * automatically. This assumption breaks when the listeners are on th persistent
 * window. There is also an issue in that complink makes no attempt to protect
 * against receiving messages sent by a different instance of comlink.
 *
 * This facade provides two main purposes. First it accumulates a list of
 * listeners which have been added by comlink (we don't swizzle the functions)
 * but rather pass this facade just to comlink. Keeps the rest of the system
 * safe. The second purpose is that these facades are filtered by bridgeId
 * by passing facades with the same bridgeId to both host and client of a
 * sandbox, we establish filtered messaging paths such that any number of
 * sandbox components can all share the IPC.
 */
export function createMultiplexingEndpointFacade(endpoint, bridgeId, label) {
    const listeners = [];
    const facade = {
        addEventListener(type, listener, options) {
            if (type === 'message') {
                listeners.push(listener);
            }
            endpoint.addEventListener(type, (event) => {
                const { data } = event;
                if (data.bridgeId && data.bridgeId !== bridgeId) {
                    return;
                }
                if (listener.handleEvent instanceof Function) {
                    listener.handleEvent(event);
                }
                else if (listener instanceof Function) {
                    listener(event);
                }
            }, options);
        },
        postMessage: (message, transfer) => {
            if (typeof message === 'object' && message.id) {
                message.bridgeId = bridgeId;
            }
            endpoint.postMessage(message, transfer);
        },
        removeEventListener: (...args) => endpoint.removeEventListener(...args),
        start: (...args) => endpoint.start?.(...args),
    };
    return {
        facade,
        listeners,
        dispose() {
            listeners.forEach((it) => endpoint.removeEventListener('message', it));
        },
    };
}
/**
 * Takes a component and a bridgeId and generates the fully formed URL and targetDomain which are
 *
 * @param component the play Sandbox/HTML component which is being displayed
 * @param bridgeId the established bridge ID of the sandbox, like the play api the bridgeId is used to filter messages
 *  and prevent crosstalk.
 * @returns {
 *  url: the fully formed url for the live source loader that can be passed to the iframe element.
 *  targetOrigin: the origin which is the expected recipient of dispatched messages and can be passed as the
 *    targetOrigin property of a postMessage call.
 * }
 */
export function getComponentIFrameUrlAndDomain(component, bridgeId) {
    const { componentUrl } = getSandboxConfig();
    const componentId = component[ID];
    const projectId = component.project.info.id;
    const identifier = `c${componentId}p${projectId}`;
    const sandboxUrl = new URL(componentUrl.replace('ID', identifier));
    sandboxUrl.searchParams.set('c', '' + componentId);
    sandboxUrl.searchParams.set('p', projectId);
    sandboxUrl.hash = bridgeId;
    return {
        url: sandboxUrl.toString(),
        targetOrigin: `${sandboxUrl.protocol}//${sandboxUrl.host}`,
    };
}
