import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    draft: false,
    description: 'This preserves the borderRadius behavior of effectively being 0 pre-migration, as we set the default to undefined in <component>.tsx.',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if ([
                'Play Kit/Canvas',
                'Play Kit/Image',
                'Play Kit/Button',
                'Play Kit/Input',
                'Play Kit/Slideshow',
                'Play Kit/Video',
            ].includes(obj.borderRadius)) {
                if (!obj.borderRadius)
                    obj.borderRadius = 0;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
