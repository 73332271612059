import { useEffect, useState } from 'react';

// This hook is used to determine if the user prefers dark mode for swapping
// the favicon (without getting into the complexities of actually
// implementing dark/light color modes)
// TODO: Actually impelment light/dark mode, which Chakra has built-in
// functionality for, but will require additional work, including better defaults
// for dark color schemes https://v2.chakra-ui.com/docs/styled-system/color-mode
export default function usePrefersDarkMode() {
  var _useState = useState(false),
    prefersDarkMode = _useState[0],
    setPrefersDarkMode = _useState[1];
  useEffect(function () {
    var darkModePreference = window.matchMedia('(prefers-color-scheme: dark)');
    var handler = function handler() {
      setPrefersDarkMode(darkModePreference.matches);
    };
    // Set the initial value
    handler();
    darkModePreference.addEventListener('change', handler);
    return function () {
      return darkModePreference.removeEventListener('change', handler);
    };
  }, []);
  return {
    prefersDarkMode: prefersDarkMode
  };
}