import Bowser from 'bowser';
// fake for node
let userAgent = 'User-Agent: Mozilla/5.0';
try {
    userAgent = window.navigator.userAgent;
}
catch (e) { }
// eslint-disable-next-line import/no-named-as-default-member
const browser = Bowser.getParser(userAgent);
export function isMobile() {
    return browser.getPlatformType() === 'mobile';
}
export function isSafari() {
    return browser.getBrowserName() === 'Safari';
}
export function isFirefox() {
    return browser.getBrowserName() === 'Firefox';
}
export function isMac() {
    return browser.getOSName() === 'macOS';
}
export function isAndroid() {
    return browser.getOSName() === 'Android';
}
export function isIOS() {
    return browser.getOSName() === 'iOS';
}
export const isEventSupported = (function () {
    const TAGNAMES = {
        select: 'input',
        change: 'input',
        submit: 'form',
        reset: 'form',
        error: 'img',
        load: 'img',
        abort: 'img',
    };
    function isEventSupported(eventName) {
        const el = document.createElement(TAGNAMES[eventName] || 'div');
        eventName = 'on' + eventName;
        let isSupported = eventName in el;
        if (!isSupported) {
            el.setAttribute(eventName, 'return;');
            isSupported = typeof el[eventName] == 'function';
        }
        el?.remove?.();
        return isSupported;
    }
    return isEventSupported;
})();
