import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
import { forEachObject } from '@playful/utils';
const migration = {
    description: 'Migrate old projects to use the new centerPageVertically property on pages',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Page') {
                if ('centerPageVertically' in obj)
                    return;
                obj.centerPageVertically = true;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
