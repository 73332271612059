import { getConfig } from '@playful/config';
import { pathJoin } from './util.js';
// Accepts project resource ids, possibly prefixed with "resource:" and returns
// a URL the resource can be fetched from.
// TODO: consider breaking this into `getResourceUrl` and `getResourceDataUrl`
export function getResourceUrl(resId, key = '', secret = '', options) {
    if (resId.startsWith('resource:')) {
        resId = resId.split(':', 2)[1];
    }
    const apiRoot = getConfig().apiRoot;
    if (secret !== '') {
        resId += '.' + secret;
    }
    if (key === null) {
        return `${apiRoot}/resources/${resId}`;
    }
    else {
        let url = `${apiRoot}/resources/${resId}/data/${key}`;
        // set options in the URL
        if (options) {
            url = url + '?' + new URLSearchParams(options).toString();
        }
        return url;
    }
}
// TODO: Eliminate this function
export function urlFromSource(source, project) {
    const [a, b] = source.split(':', 2);
    const resourceRoot = project.resourceRoot;
    if (a === 'data' || a === 'blob' || a === 'https' || a === 'http' || source.startsWith('/_/')) {
        return source;
    }
    else if (a === 'sha256') {
        return pathJoin([resourceRoot, source]);
    }
    else if (a === 'resource') {
        return pathJoin([resourceRoot, b]);
    }
    return pathJoin([resourceRoot, source]);
}
