import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
import { forEachObject } from '@playful/utils';
const TEXT_TYPES = [
    'Play Kit/Text',
    'Play Kit/Subheading',
    'Play Kit/BodyText',
    'Play Kit/Heading',
];
function fixTextDecoration(childNodes) {
    childNodes.forEach((node) => {
        node.querySelectorAll('a').forEach((link) => {
            // resetting textDecoration to nothing and then back to none fixes the multiple entries
            link.style.textDecoration = '';
            link.style.textDecoration = 'none';
        });
    });
}
const migration = {
    description: `sometimes text elements are ending up with multiple text-decoration styles,
  like "text-decoration: none; text-decoration: none;", fix that and clean up variants`,
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (TEXT_TYPES.includes(obj.componentType) && obj.html?.length) {
                // migrate main one
                const doc = new DOMParser().parseFromString(obj.html, 'text/html');
                fixTextDecoration(doc.body.childNodes);
                obj.html = doc.body.innerHTML;
                // migrate variants, delete if after migration it is the same as the main one
                const variants = Object.keys(obj.variants ?? {});
                variants.forEach((variant) => {
                    if (obj.variants[variant].html?.length) {
                        const doc = new DOMParser().parseFromString(obj.variants[variant].html, 'text/html');
                        fixTextDecoration(doc.body.childNodes);
                        obj.variants[variant].html = doc.body.innerHTML;
                        if (obj.variants[variant].html === obj.html) {
                            delete obj.variants[variant].html;
                        }
                        if (obj.variants[variant].text === obj.text) {
                            delete obj.variants[variant].text;
                        }
                    }
                });
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
