import { isClientCtx } from '@playful/utils';
import { useEffect, useState } from 'react';
export function useLocalStorageState(defaultValue, key) {
  var _useState = useState(defaultValue),
    value = _useState[0],
    setValue = _useState[1];
  useEffect(function () {
    if (!isClientCtx()) return;
    var value = localStorage.getItem(key);
    try {
      if (value === null) return;
      if (value === 'undefined') setValue(undefined);
      setValue(JSON.parse(value));
    } catch (e) {
      console.error(e);
    }
  }, [key]);
  useEffect(function () {
    if (!isClientCtx()) return;
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  }, [key, value]);
  return [value, setValue];
}