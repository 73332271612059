import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Migrate old dark theme projects to use the new backgroundColor/color systems instead of relying on projectView.default.css.',
    async migrate(state) {
        // If it has css, check to see if it has the dark theme background color.
        const isDarkTheme = state.css && `${state.css}`?.includes('background-color: #282c34');
        // If there is no css it's an old dark-theme project
        const isOldDarkTheme = !state.css;
        if ((isDarkTheme || isOldDarkTheme) && // Light theme projects had css applied to the project. Dark theme did not.
            state.pages && // avoids running on some test projects
            state.componentType === 'Play Kit/Project') {
            if (!state.backgroundColor) {
                state.backgroundColor = '#282c34';
                state?.pages?.forEach((p) => {
                    if (!p.backgroundColor) {
                        p.backgroundColor = '#282c34';
                    }
                });
            }
            forEachObject(state, (obj) => {
                if (obj.componentType === 'Play Kit/Text' || obj.componentType === 'Play Kit/Button') {
                    if (!obj.color) {
                        obj.color = '#ffffff';
                    }
                }
            });
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
