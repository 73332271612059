import { getConfig } from '@playful/config';
import { mergeTokenConfig } from './mergeTokenConfig.js';
export async function apiRequest(endpoint, config = {}) {
    const mergedConfig = await mergeTokenConfig(config);
    // relative endpoints are relative to the apiRoot
    if (!endpoint.startsWith('/')) {
        endpoint = `${getConfig().apiRoot}/${endpoint}`;
    }
    const res = await fetch(endpoint, mergedConfig);
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    return res;
}
