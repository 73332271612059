// Tokens can be imported to a designRuntime environment, like PlayKit
// When used in an Inspector Description, they will be replaced with a concrete implementation
// when the Description is parsed by the Frontend & Workbench
export const PositionIconToken = '__position_icon__';
export const StyleIconToken = '__style_icon__';
export const AdvancedIconToken = '__advanced_icon__';
export const PhysicsIconToken = '__physics_icon__';
export const LeftTextAlignIconToken = '__left_text_align__';
export const CenterTextAlignIconToken = '__center_text_align__';
export const RightTextAlignIconToken = '__right_text_align__';
export const BottomTextAlignIconToken = '__bottom_text_align__';
export const VerticalCenterTextAlignIconToken = '__vertical_center_text_align__';
export const TopTextAlignIconToken = '__top_text_align__';
export const LeftAlignIconToken = '__left_object_align__';
export const CenterAlignIconToken = '__center_object_align__';
export const RightAlignIconToken = '__right_object_align__';
export const BottomAlignIconToken = '__bottom_object_align__';
export const MiddleAlignIconToken = '__middle_object_align__';
export const TopAlignIconToken = '__top_object_align__';
export const ImageIconToken = '__image_icon__';
export const HTMLIconToken = '__html_icon__';
export const GearIconToken = '__gear_icon__';
export const PropertiesChangeIconToken = '__properties_change_icon__';
export const InViewAnyIconToken = '__inview_any_icon__';
export const InViewHalfIconToken = '__inview_half_icon__';
export const InViewAllIconToken = '__inview_all_icon__';
export const ArrowHorizontalIconToken = '__arrow_horizontal_icon__';
export const ArrowVerticalIconToken = '__arrow_vertical_icon__';
export const ArrowLeftIconToken = '__arrow_left_icon__';
export const ArrowRightIconToken = '__arrow_right_icon__';
export const ArrowUpIconToken = '__arrow_up_icon__';
export const ArrowDownIconToken = '__arrow_down_icon__';
export const ArrowMiddleHorizontalIconToken = '__arrow_middle_horizontal_icon__';
export const ArrowMiddleVerticalIconToken = '__arrow_middle_vertical_icon__';
export const VDistributionTopIconToken = '__v_distribution_top_icon__';
export const VDistributionCenterIconToken = '__v_distribution_center_icon__';
export const VDistributionBottomIconToken = '__v_distribution_bottom_icon__';
export const HDistributionLeftIconToken = '__h_distribution_left_icon__';
export const HDistributionCenterIconToken = '__h_distribution_center_icon__';
export const HDistributionRightIconToken = '__h_distribution_right_icon__';
export const VDistributionSpaceBetweenIconToken = '__v_distribution_space_between_icon__';
export const HDistributionSpaceBetweenIconToken = '__h_distribution_space_between_icon__';
export const VDistributionSpaceAroundIconToken = '__v_distribution_space_around_icon__';
export const HDistributionSpaceAroundIconToken = '__h_distribution_space_around_icon__';
export const VDistributionSpaceEvenlyIconToken = '__v_distribution_space_evenly_icon__';
export const HDistributionSpaceEvenlyIconToken = '__h_distribution_space_evenly_icon__';
export const FixedWidthIconToken = '__fixed_width_icon__';
export const FixedHeightIconToken = '__fixed_height_icon__';
export const PageIconToken = '__page_icon__';
export const FixedSizeTextIconToken = '__fixed_size_text_icon__';
export const FixedWidthTextIconToken = '__fixed_width_text_icon__';
export const DynamicSizeTextIconToken = '__dynamic_size_text_icon__';
export const InViewIconToken = '__inview_icon__';
export const ScrollingIconToken = '__scrolling_icon__';
export const PageLoadIconToken = '__page_load_icon__';
export const HoverIconToken = '__hover_icon__';
export const ClickIconToken = '__click_icon__';
export const OverlapIconToken = '__overlap_icon__';
export const PressReleaseIconToken = '__press_release_icon__';
export const PointerDistanceIconToken = '__pointer_distance_icon__';
