import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle, theme, } from '@chakra-ui/react';
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);
const baseStyle = definePartsStyle({
    button: { transitionProperty: 'common', transitionDuration: 'normal' },
    list: {
        bg: 'white',
        boxShadow: 'lg',
        color: 'inherit',
        minW: '3xs',
        py: 2,
        zIndex: theme.zIndices.dropdown,
        borderRadius: 'md',
        border: '0.0625rem solid',
        borderColor: 'gray.100',
        overflow: 'hidden',
    },
    item: {
        py: 2,
        px: 3,
        transitionProperty: 'background',
        transitionDuration: 'ultra-fast',
        transitionTimingFunction: 'ease-in',
        _focus: { bg: 'gray.50' },
        _active: { bg: 'gray.100' },
        _expanded: { bg: 'gray.50' },
        _disabled: { opacity: 0.4, cursor: 'not-allowed' },
    },
    groupTitle: { mx: 4, my: 2, fontWeight: 700, fontSize: 'sm' },
    command: { opacity: 0.6 },
    divider: {
        border: 0,
        borderBottom: '0.0625rem solid',
        borderColor: 'gray.100',
        my: 2,
        opacity: 0.6,
    },
});
const auto = {
    list: defineStyle({
        minW: '0px',
    }),
};
const sm = defineStyle((props) => {
    const w = props.variant === 'unified' ? 'auto' : '12.5rem';
    return {
        list: {
            w,
        },
    };
});
const lg = {
    list: defineStyle({
        w: '18.5rem',
    }),
};
const sizes = {
    auto: definePartsStyle({ ...auto }),
    sm: definePartsStyle({ ...sm }),
    lg: definePartsStyle({ ...lg }),
};
const variants = {
    selectBox: {
        item: {
            fontSize: 'xs',
            p: 1,
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        list: {
            maxHeight: '200px',
            overflowY: 'auto',
        },
    },
    // Maybe not the best name. Comes from the unified inputs designs.
    unified: defineStyle((props) => {
        const { colorScheme, size } = props;
        let hoverBg = 'gray.100';
        let focusBg = 'gray.100';
        let activeBg = 'gray.100';
        // TODO: Maybe icon bg?
        if (colorScheme === 'purple') {
            hoverBg = 'purple.50';
            focusBg = 'purple.50';
            activeBg = 'purple.100';
        }
        if (colorScheme === 'blue') {
            hoverBg = 'blue.50';
            focusBg = 'blue.50';
            activeBg = 'blue.100';
        }
        return {
            list: {
                py: '6px',
                bg: 'white',
                border: '1px solid',
                borderColor: 'gray.200',
                boxShadow: 'lg',
                maxHeight: '300px',
                overflowY: 'auto',
                minW: '0px',
                zIndex: 'popover',
            },
            item: {
                padding: 1,
                color: 'gray.800',
                borderRadius: 'sm',
                fontSize: 'sm',
                fontWeight: 'regular',
                height: size === 'sm' ? '32px' : '40px',
                mx: '6px',
                width: 'calc(100% - 12px)',
                _hover: {
                    bg: hoverBg,
                },
                _focus: {
                    bg: focusBg,
                },
                _active: {
                    bg: activeBg,
                },
                '> span': {
                    overflow: 'hidden',
                },
            },
            divider: {
                borderColor: 'gray.100',
                my: '6px',
                opacity: 1,
            },
            groupTitle: {
                color: 'gray.500',
                fontSize: 'xxs',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                mx: '10px',
            },
        };
    }),
};
export const menuTheme = defineMultiStyleConfig({ baseStyle, sizes, variants });
