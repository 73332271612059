import { buildPath } from '@playful/utils';
import { axiosRequest } from './axiosRequest.js';
export const getOEmbedPath = (projectId) => buildPath('/_/oembed', {
    format: 'json',
    projectId,
});
export async function getOEmbed({ projectId }) {
    const { data } = await axiosRequest(getOEmbedPath(projectId));
    // what type should this return?
    return data;
}
