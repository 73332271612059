import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(popoverAnatomy.keys);
const baseStyle = definePartsStyle({
    popper: { zIndex: 1800 },
    content: {
        '--popper-bg': 'colors.white',
        bg: 'var(--popper-bg)',
        '--popper-arrow-bg': 'var(--popper-bg)',
        '--popper-arrow-shadow-color': 'colors.gray.100',
        width: 'xs',
        border: '0.0625rem solid',
        borderColor: 'gray.100',
        borderRadius: 'md',
        boxShadow: 'md',
        zIndex: 'inherit',
    },
    header: { px: 6, py: 4, borderBottom: '0.0625rem solid', borderBottomColor: 'gray.100' },
    body: { px: 6, py: 4 },
    footer: { px: 6, py: 4, borderTop: '0.0625rem solid', borderTopColor: 'gray.100' },
    arrow: {},
    closeButton: {
        position: 'absolute',
        borderRadius: 'full',
        top: 1,
        insetEnd: 2,
        padding: 2,
        _focus: { boxShadow: 'none' },
        _focusVisible: {
            outline: '0.125rem solid',
            outlineColor: 'gray.800',
            outlineOffset: '0.125rem',
        },
    },
});
const variants = {
    tooltip: baseStyle, // for legacy purposes. we have `tooltip` as a variant around the codebase, but it never worked.
    dock: {
        popper: { zIndex: 'docked' },
    },
};
export const popoverTheme = defineMultiStyleConfig({ baseStyle, sizes: {}, variants });
