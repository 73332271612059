import { registerMigration } from '../registry.js';
import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
const migration = {
    description: 'Migrate old projects with a project BG and page BG that are different colors',
    draft: false,
    async migrate(state) {
        const projectBG = state.backgroundColor;
        forEachObject(state, (obj) => {
            if (projectBG) {
                if (obj.componentType === 'Play Kit/Page') {
                    if (obj.backgroundColor !== projectBG) {
                        if (obj.children?.length === 1 &&
                            obj.children[0].componentType === 'Play Kit/Section') {
                            obj.children[0].backgroundColor = obj.backgroundColor;
                            obj.backgroundColor = projectBG;
                        }
                    }
                }
                delete state.backgroundColor;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
