// Sends a custom event ONLY to rudderStack (customEvent sends to multiple services)
export function rudderstackEvent(name, props) {
    try {
        const { rudderanalytics } = window;
        rudderanalytics?.track(name, props);
    }
    catch (e) {
        console.warn(`Error sending to rudderstack event: ${name}`, props);
    }
}
