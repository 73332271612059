import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
import { forEachObject } from '@playful/utils';
const TEXT_TYPES = [
    'Play Kit/Text',
    'Play Kit/Subheading',
    'Play Kit/BodyText',
    'Play Kit/Heading',
];
const MIXED_STYLES = '__mixed_styles__';
const MIXED_SIZES = -1;
const migration = {
    description: 'build mixedProperties for text components, remove old mixed values',
    draft: true, // TODO: Flip this false after QAing
    async migrate(state) {
        log(`Migrated ${migration.description}`);
        forEachObject(state, (obj) => {
            if (TEXT_TYPES.includes(obj.componentType)) {
                const mixedProperties = obj.mixedProperties || {};
                if (obj.fontWeight === MIXED_STYLES) {
                    mixedProperties.fontWeight = true;
                    delete obj.fontWeight;
                }
                if (obj.color === MIXED_STYLES) {
                    mixedProperties.color = true;
                    delete obj.color;
                }
                if (obj.fontSize === MIXED_SIZES) {
                    mixedProperties.fontSize = true;
                    delete obj.fontSize;
                }
                if (obj.fontFamily === MIXED_STYLES) {
                    mixedProperties.fontFamily = true;
                    delete obj.fontFamily;
                }
                if (obj.lineHeight === MIXED_SIZES) {
                    mixedProperties.lineHeight = true;
                    delete obj.lineHeight;
                }
                if (obj.letterSpacing === MIXED_SIZES) {
                    mixedProperties.letterSpacing = true;
                    delete obj.letterSpacing;
                }
                obj.mixedProperties = mixedProperties;
            }
        });
    },
};
registerMigration(migration, import.meta.url);
