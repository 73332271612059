import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';
// From https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4
function sendToGoogleAnalytics({ name, delta, value, id }) {
    window.gtag?.('event', name, {
        // Built-in params:
        value: delta, // Use `delta` so the value can be summed.
        // Custom params:
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.
    });
}
function sendToRudderstack({ name, delta, value, navigationType, rating, id }) {
    window.rudderanalytics?.ready(() => {
        window.rudderanalytics?.track(name, {
            delta,
            value,
            navigationType,
            rating,
            metric_id: id,
        });
    });
}
export function enableWebVitals() {
    const sendMetric = (metric) => {
        sendToGoogleAnalytics(metric);
        sendToRudderstack(metric);
    };
    const funcs = [onCLS, onFID, onLCP, onFCP, onTTFB, onINP];
    for (const func of funcs) {
        func(sendMetric);
    }
}
