import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
function forEachBlock(block, callback) {
    callback(block);
    if (Array.isArray(block.blocks)) {
        for (const child of block.blocks) {
            forEachBlock(child, callback);
        }
    }
}
function forEachBlocks(blocks, callback) {
    for (const child of blocks) {
        forEachBlock(child, callback);
    }
}
const migration = {
    description: 'Moves Physics properties from effect into container component - attempt #2',
    // we reverted the first attempt because it was not working as expected.
    // It did not delete the physics component (intentionally), so we are able try again safely.
    //
    async migrate(state) {
        const physicsObjectIds = {};
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Page' || obj.componentType === 'Play Kit/Container') {
                // look for Physics children components (aka gravity canvas elements)
                const physics = obj.children?.find((c) => c.componentType === 'Play Kit/Physics');
                if (physics) {
                    physicsObjectIds[physics[ID]] = obj[ID];
                    const props = [
                        'verticalGravity',
                        'horizontalGravity',
                        'collisionVelocityThreshold',
                        'defaultType',
                        'defaultShape',
                        'defaultDensity',
                        'defaultBounciness',
                        'defaultFriction',
                    ];
                    props.forEach((prop) => {
                        if (physics[prop] !== undefined) {
                            obj[prop] = physics[prop];
                        }
                    });
                    // move the javascript code to the container
                    if (physics['javascript']) {
                        obj['javascript'] = (obj['javascript'] ?? '') + physics['javascript'];
                        physics['javascript'] = '';
                    }
                    if (physics.defaultType === undefined) {
                        obj.defaultType = 'static';
                    }
                    // renaming the enabled property
                    // physics.enabled defaulted to true
                    obj.physicsEnabled = physics.enabled ?? true;
                }
            }
        });
        function remap(targetId, physicsObjectIds) {
            if (Object.keys(physicsObjectIds).includes(String(targetId))) {
                return physicsObjectIds[targetId];
            }
            return targetId;
        }
        function migrateActions(actions) {
            if (!actions)
                return;
            for (const action of actions) {
                if (action === null)
                    continue;
                if (physicsObjectIds[action.targetId]) {
                    action.targetId = physicsObjectIds[action.targetId];
                    if (action?.args?.value?.type === 'expression' &&
                        typeof action.args.value.value === 'string') {
                        // remove parent. prefix from expressions as we're moving the parent from being the physics object to its parent, the container
                        action.args.value.value = action.args.value.value.replaceAll(/\bparent\./g, '');
                    }
                }
                if (action?.args?.property?.value === 'enabled') {
                    action.args.property.value = 'physicsEnabled';
                }
            }
        }
        forEachObject(state, (obj) => {
            if (obj.key && obj.actions) {
                migrateActions(obj.actions);
            }
            if (obj.condition &&
                obj.condition.type === 'expression' &&
                typeof obj.condition.value === 'string') {
                obj.condition.value = obj.condition.value.replaceAll(/\benabled\b/g, 'physicsEnabled');
            }
            if (obj.trueActions && obj.trueActions.type === 'actions') {
                migrateActions(obj.trueActions.value);
            }
            if (obj.falseActions && obj.falseActions.type === 'actions') {
                migrateActions(obj.falseActions.value);
            }
            if (obj.javascript && typeof obj.javascript === 'string') {
                Object.entries(physicsObjectIds).forEach(([oldId, newId]) => {
                    const oldIdString = String(oldId);
                    const newIdString = String(newId);
                    obj.javascript = obj.javascript.replaceAll(new RegExp(`get\\(\\b${oldIdString}.*?\\).enabled`, 'g'), `get(${newIdString}).physicsEnabled`);
                    obj.javascript = obj.javascript.replaceAll(new RegExp(`get\\(\\b${oldIdString}\\b`, 'g'), `get(${newIdString}`);
                });
            }
            if (obj.blocks) {
                forEachBlocks(obj.blocks, (block) => {
                    getTargetIdsFromBlock(block)
                        .filter((id) => Object.keys(physicsObjectIds).includes(String(id)))
                        .forEach((id) => {
                        if (block.type === 'setProperty') {
                            block.targetId = remap(block.targetId, physicsObjectIds);
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore - ya ya, we're deprecating it :)
                            block.actions.forEach((action) => {
                                if (physicsObjectIds[action.targetId]) {
                                    action.targetId = physicsObjectIds[action.targetId];
                                    if (action.args.enabled) {
                                        action.args.physicsEnabled = action.args.enabled;
                                        delete action.args.enabled;
                                    }
                                    // remap expressions containing physics objects
                                    Object.entries(action.args).forEach(([k, v]) => {
                                        if (v.isExpression) {
                                            Object.entries(physicsObjectIds).forEach(([oldId, newId]) => {
                                                const oldIdString = String(oldId);
                                                const newIdString = String(newId);
                                                v.value = v.value.replaceAll(new RegExp(`get\\(\\b${oldIdString}\\b`, 'g'), `get(${newIdString}`);
                                            });
                                            action.args[k] = v;
                                        }
                                    });
                                }
                            });
                        }
                        else if (block.type === 'call') {
                            block.action.targetId = remap(block.action.targetId, physicsObjectIds);
                        }
                        else if (block.type === 'trigger') {
                            block.targetId = remap(block.targetId, physicsObjectIds);
                        }
                        else if (block.type === 'cond') {
                            if (block.condition.type === 'conditions') {
                                for (const condition of block.condition.conditions) {
                                    condition.targetId = remap(condition.targetId, physicsObjectIds);
                                    if (condition.rtype === 'target' && condition.rtargetId) {
                                        condition.rtargetId = remap(condition.rtargetId, physicsObjectIds);
                                        if (condition.rproperty === 'enabled') {
                                            condition.rproperty = 'physicsEnabled';
                                        }
                                    }
                                }
                            }
                        }
                    });
                });
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
function getTargetIdsFromBlock(block) {
    const targetIds = [];
    if (block.type === 'setProperty') {
        // actions is now deprecated, but we still need to migrate it
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (block.actions?.length > 0) {
            // actions is now deprecated, but we still need to migrate it
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            targetIds.push(...block.actions.map((i) => i.targetId));
        }
    }
    else if (block.type === 'call') {
        targetIds.push(block.action.targetId);
    }
    else if (block.type === 'trigger') {
        targetIds.push(block.targetId);
    }
    else if (block.type === 'cond') {
        if (block.condition.type === 'conditions') {
            for (const condition of block.condition.conditions) {
                targetIds.push(condition.targetId);
                if (condition.rtype === 'target' && condition.rtargetId) {
                    targetIds.push(condition.rtargetId);
                }
            }
        }
        else if (block.condition.type === 'expression') {
            // TODO - can we extract the references from the expression?
        }
    }
    return targetIds;
}
registerMigration(migration, import.meta.url);
