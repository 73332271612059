import { jsx as _jsx } from "react/jsx-runtime";
import { Button as ChButton, IconButton as ChIconButton } from '@chakra-ui/button';
import { forwardRef } from '@chakra-ui/system';
/**
 * Primary UI component for user interaction. See [Button](https://v1.chakra-ui.com/docs/components/form/button) for the complete documentation.
 */
export const Button = forwardRef(({ iconSpacing = 1.5, ...restProps }, ref) => {
    // TODO: Fix color usage so Chakra doesn't `throw new ColorError$1(color);`
    //return <ChButton ref={ref} iconSpacing={iconSpacing} {...restProps} colorScheme='red' />;
    return _jsx(ChButton, { ref: ref, iconSpacing: iconSpacing, ...restProps });
});
export const IconButton = forwardRef((props, ref) => {
    // TODO: Fix color usage so Chakra doesn't `throw new ColorError$1(color);`
    //return <ChIconButton ref={ref} {...props} colorScheme='red' />;
    return _jsx(ChIconButton, { ref: ref, ...props });
});
