import { axiosRequest } from './axiosRequest.js';
export async function getCustomDomains(userId) {
    const { data } = await axiosRequest(`users/${userId}/custom_domains`, {});
    return data;
}
export async function deleteCustomDomain(userId, domainName) {
    await axiosRequest(`users/${userId}/custom_domains/${domainName}`, {
        method: 'DELETE',
    });
}
export async function addCustomDomain(userId, domain, redirect) {
    const { data } = await axiosRequest(`users/${userId}/custom_domains`, {
        method: 'POST',
        data: { name: domain, redirect },
    });
    return data;
}
