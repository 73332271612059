export const textTheme = {
    baseStyle: {
        lineHeight: 'base',
        fontStretch: 'normal',
    },
    sizes: {
        xs: {
            fontSize: '0.75rem',
        },
    },
    variants: {
        body: { fontSize: 'md' },
        caption: {
            fontSize: 'xs',
            fontWeight: 'bold',
        },
        header: {
            fontWeight: '700',
        },
    },
    defaultProps: {},
};
