import { log } from '../debug.js';
import { forEachObject } from '@playful/utils';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Move fixedPosition to the new positionType property',
    draft: false,
    async migrate(state) {
        log(`Migrated ${migration.description}`);
        forEachObject(state, (object) => {
            if (object.fixedPosition && object.componentType) {
                object.positionType = 'fixed';
            }
            // look for blocks that have a method of 'setProperty' and a fixedPosition property
            // update it to use the new positionType property
            if (object.method && object.method === 'setProperty') {
                if (!!object?.args?.fixedPosition) {
                    if (!object?.args?.fixedPosition?.isExpression) {
                        object.args.positionType = {
                            type: 'string',
                            value: object.args.fixedPosition.value ? 'fixed' : 'absolute',
                            isExpression: false,
                        };
                        delete object.args.fixedPosition;
                    }
                }
            }
            if (object.type === 'condition' &&
                object.property === 'fixedPosition' &&
                object.rtype === 'target') {
                object.property = 'positionType';
            }
        });
    },
};
registerMigration(migration, import.meta.url);
