import { forEachObject } from '@playful/utils';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Set the responsive behavior for existing projects to the old behavior.',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType &&
                obj.componentType === 'Play Kit/Section' &&
                !obj.responsiveBehavior) {
                obj.responsiveBehavior = 'containToPage';
            }
        });
    },
};
registerMigration(migration, import.meta.url);
