import { defineStyleConfig } from '@chakra-ui/react';
export const inputTheme = defineStyleConfig({
    defaultProps: { size: 'md', variant: 'rebrand' },
    baseStyle: {
        field: {
            width: '100%',
            minWidth: 0,
            outline: 0,
            position: 'relative',
            appearance: 'none',
            transitionProperty: 'common',
            transitionDuration: 'normal',
        },
    },
    variants: {
        flat: {
            field: { backgroundColor: 'gray.50', border: 'none', _placeholder: { color: 'gray.800' } },
        },
        outline: {
            field: {
                border: '0.0625rem solid',
                borderColor: 'inherit',
                bg: 'inherit',
                _hover: { borderColor: 'gray.300' },
                _readOnly: { boxShadow: 'none !important', userSelect: 'all' },
                _disabled: { opacity: 0.4, cursor: 'not-allowed' },
                _invalid: { borderColor: 'red.500' },
                _focus: {
                    zIndex: 1,
                    boxShadow: '0 0 0 1px var(--play-colors-blue-500)',
                    bg: 'white',
                },
            },
            addon: { border: '0.0625rem solid', borderColor: 'inherit', bg: 'gray.100' },
        },
        unstyled: {
            field: { bg: 'transparent', px: 0, height: 'auto' },
            addon: { bg: 'transparent', px: 0, height: 'auto' },
        },
        underlined: {
            field: {
                bg: 'transparent',
                height: 'auto',
                borderBottom: '0.0625rem solid',
                borderColor: 'inherit',
                borderRadius: 0,
                px: 0,
            },
            addon: {
                bg: 'transparent',
                height: 'auto',
                borderBottom: '0.0625rem solid',
                borderColor: 'inherit',
                borderRadius: 0,
                px: 0,
            },
        },
        inspector: {
            field: {
                bg: 'gray.50',
                borderColor: 'gray.50',
                color: 'gray.800',
                _focus: {
                    outline: '0.125rem solid',
                    outlineColor: 'blue.500',
                    outlineOffset: '-0.125rem',
                    boxShadow: 'none',
                    bg: 'white',
                },
            },
        },
        // TODO: Is this the baseStyle now?
        rebrand: {
            field: {
                bg: 'gray.50',
                color: 'gray.800',
                border: '2px solid',
                borderColor: 'gray.50',
                _placeholder: { color: 'gray.500' },
                _hover: {
                    border: '2px solid',
                    borderColor: 'gray.400',
                },
                _focus: {
                    border: '2px solid',
                    borderColor: 'blue.500',
                    bg: 'white',
                },
            },
        },
    },
    sizes: {
        lg: {
            field: { fontSize: 'lg', px: 4, h: 12, borderRadius: 'md' },
            addon: { fontSize: 'lg', px: 4, h: 12, borderRadius: 'md' },
        },
        md: {
            field: { fontSize: 'md', px: 4, h: 10, borderRadius: 'md' },
            addon: { fontSize: 'md', px: 4, h: 10, borderRadius: 'md' },
        },
        sm: {
            field: { fontSize: 'sm', px: 3, h: 8, borderRadius: 'sm' },
            addon: { fontSize: 'sm', px: 3, h: 8, borderRadius: 'sm' },
        },
        xs: {
            field: { fontSize: 'xs', px: 2, h: 6, borderRadius: 'sm' },
            addon: { fontSize: 'xs', px: 2, h: 6, borderRadius: 'sm' },
        },
    },
});
