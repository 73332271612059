// Return true if the two objects are shallowly equal.
export function shallowEqual(a, b) {
    if (a === b)
        return true;
    if (!a || !b)
        return false;
    for (const key in a) {
        if (a[key] !== b[key]) {
            return false;
        }
    }
    return true;
}
