import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'change setProperty instruction to instructions array',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.type === 'setProperty' && obj.instruction) {
                obj.targetId = obj.instruction.targetId; // the block's target
                obj.instructions = [obj.instruction];
                delete obj.instruction;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
