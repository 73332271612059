import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Remove unsupported effects (noop)',
    draft: false,
    async migrate(state) {
        log(`Migrated ${migration.description} (NOOP)`);
    },
};
registerMigration(migration, import.meta.url);
