import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    draft: false,
    description: 'This preserves the vertical alignment of text components when migrating to RTE',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (['Play Kit/Text', 'Play Kit/BodyText', 'Play Kit/Heading', 'Play Kit/Subheading'].includes(obj.componentType)) {
                if (!obj.verticalAlignment && obj.text && obj.sizeMode === 'fixed')
                    obj.verticalAlignment = 'center';
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
