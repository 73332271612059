import { useCallback, useEffect, useState } from 'react';
import queryStringActionsState from './queryStringActions.js';
export function useQueryStringActions({ startAction, endAction, actionName, }) {
    const [currentDialog, setCurrentDialog] = useState();
    useEffect(() => {
        queryStringActionsState.subscribe(setCurrentDialog);
        return () => {
            queryStringActionsState.unsubscribe(setCurrentDialog);
        };
    }, []);
    useEffect(() => {
        if (currentDialog === actionName) {
            startAction();
        }
    }, [currentDialog, actionName, startAction]);
    const handleEndAction = useCallback(() => {
        endAction();
        queryStringActionsState.removeAction(actionName);
    }, [endAction, actionName]);
    return { handleEndAction };
}
