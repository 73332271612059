import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
// IMPURE FUNCTION
// this will *only* set the value on the provided obj if it doesn't already exist.
function migratePropertyDefault(obj, prop, oldDefaultVal) {
    if (Object.hasOwn(obj, prop))
        return;
    obj[prop] = oldDefaultVal;
}
const migration = {
    draft: false,
    description: 'This preserves existing defaults for shape components existing before #3180',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (['Play Kit/Ellipse', 'Play Kit/Polygon', 'Play Kit/Rectangle'].includes(obj.componentType)) {
                migratePropertyDefault(obj, 'strokeColor', '#ffffff');
                migratePropertyDefault(obj, 'strokeWidth', 0);
                migratePropertyDefault(obj, 'color', '#808080');
                return;
            }
            if (obj.componentType === 'Play Kit/Arrow') {
                migratePropertyDefault(obj, 'arrowHeight', 10);
                migratePropertyDefault(obj, 'arrowWidth', 7);
                migratePropertyDefault(obj, 'color', '#808080');
                return;
            }
            if (obj.componentType === 'Play Kit/Line') {
                migratePropertyDefault(obj, 'lineCap', 'round');
                migratePropertyDefault(obj, 'strokeWidth', 5);
                migratePropertyDefault(obj, 'color', '#808080');
                return;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
