import { useMediaQuery } from '@chakra-ui/react';
import { extraSmallScreenThreshold, mediumScreenThreshold, smallScreenThreshold, } from '../theme/foundations/breakpoints';
const maxWidthQuery = (threshold) => `(max-width: ${threshold}px)`;
export function useIsSmallScreen(ssr = false) {
    const [isSm] = useMediaQuery([maxWidthQuery(smallScreenThreshold)], { ssr });
    return isSm;
}
export function isSmallScreen() {
    try {
        return window && window.matchMedia(`(max-width: ${smallScreenThreshold}px)`).matches;
    }
    catch (e) {
        return false;
    }
}
export function useIsExtraSmallScreen() {
    const [isXs] = useMediaQuery([maxWidthQuery(extraSmallScreenThreshold)]);
    return isXs;
}
export function useHatchBreakpoints(opts) {
    const [isSmallScreen, isMediumScreen, isLargeScreen] = useMediaQuery([
        `(max-width: ${smallScreenThreshold}px)`,
        `(max-width: ${mediumScreenThreshold}px) and (min-width: ${smallScreenThreshold}px)`,
        `(min-width: ${mediumScreenThreshold}px)`,
    ], opts);
    return {
        isSmallScreen,
        isMediumScreen,
        isLargeScreen,
    };
}
