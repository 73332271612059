import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
import { forEachObject } from '@playful/utils';
export const makeUrl = (url) => {
    try {
        return new URL(decodeURIComponent(url)).toString();
    }
    catch (e) {
        // will succeed 99% of the time, if it's not totally butchered (like spaces, extra
        // colons, etc)
        try {
            return new URL(decodeURIComponent('http://' + url)).toString();
        }
        catch (e) {
            return;
        }
    }
};
const migration = {
    description: 'Convert Link and TextLink components into Text components',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Link' || obj.componentType === 'Play Kit/TextLink') {
                obj.componentType = 'Play Kit/Text';
                let paragraphStyle = '';
                let spanStyle = '';
                if (obj.fontSize) {
                    paragraphStyle += `font-size: ${obj.fontSize}px;`;
                }
                if (obj.fontFamily) {
                    spanStyle += `font-family: ${obj.fontFamily};`;
                }
                if (obj.fontSize) {
                    spanStyle += `font-size: ${obj.fontSize}px;`;
                }
                if (obj.color) {
                    spanStyle += `color: ${obj.color};`;
                }
                let html = `${obj.text || ''}`;
                if (obj.underline) {
                    obj.textDecoration = 'underline';
                    html = `<u>${html}</u>`;
                }
                if (obj.fontWeight) {
                    html = `<strong data-bold=${obj.fontWeight} style="font-weight: ${obj.fontWeight}">${html}</strong>`;
                }
                let linkAttributes = '';
                // component defaults to _self
                if (obj.target) {
                    linkAttributes += ` target="${obj.target}"`;
                }
                else {
                    linkAttributes += ` target="_self"`;
                }
                if (obj.rel) {
                    linkAttributes += ` rel="${obj.rel}"`;
                }
                if (obj.title) {
                    linkAttributes += ` title="${obj.title}"`;
                }
                // component defaults to no-referrer-when-downgrade
                if (obj.referrerPolicy) {
                    linkAttributes += ` referrerpolicy="${obj.referrerPolicy}"`;
                }
                else {
                    linkAttributes += ` referrerpolicy="no-referrer-when-downgrade"`;
                }
                if (obj.url) {
                    const formattedUrl = makeUrl(obj.url);
                    if (formattedUrl) {
                        linkAttributes += ` href="${formattedUrl}" data-linkaddress="${formattedUrl}"`;
                    }
                    else {
                        linkAttributes += ` data-linkaddress=""`;
                    }
                }
                else {
                    linkAttributes += ` data-linkaddress=""`;
                }
                html = `<a style='text-decoration: none' ${linkAttributes} data-linkto="website">${html}</a>`;
                if (spanStyle) {
                    html = `<span style="${spanStyle}">${html}</span>`;
                }
                html = `<p style="${paragraphStyle}">${html}</p>`;
                obj.html = html;
                // defaults from the component
                if (!obj.sizeMode) {
                    obj.sizeMode = 'fixed';
                }
                if (!obj.width) {
                    obj.width = 100;
                }
                if (!obj.height) {
                    obj.height = 24;
                }
                delete obj.rel;
                delete obj.title;
                delete obj.target;
                delete obj.underline;
                delete obj.url;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
