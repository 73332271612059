export default {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    // TODO: the following values have each been reduced by 100 for increased
    // compatibility with mui z-indices. we should increment them by 100 after
    // we finish removing mui to get back to chakra defaults.
    dropdown: 900,
    sticky: 1000,
    banner: 1100,
    overlay: 1200,
    modal: 1300,
    popover: 1400,
    skipLink: 1500,
    toast: 1600,
    tooltip: 1700,
};
