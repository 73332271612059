class QueryStringActions {
    queryValues = [];
    handlers = [];
    constructor() {
        if (typeof window === 'undefined')
            return;
        const url = new URL(window.location.href);
        const actionNames = url.searchParams.get('returnTo');
        this.queryValues = actionNames?.split(',') || [];
    }
    removeAction(actionName) {
        this.queryValues = this.queryValues.filter((value) => value !== actionName);
        this.notifyHandlers();
    }
    subscribe(callback) {
        this.handlers.push(callback);
        if (this.queryValues.length) {
            callback(this.queryValues[0]);
        }
    }
    unsubscribe(callback) {
        this.handlers = this.handlers.filter((handler) => handler !== callback);
    }
    notifyHandlers() {
        this.handlers.forEach((handler) => handler(this.queryValues[0]));
    }
}
const instance = new QueryStringActions();
export default instance;
