import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Migrate Physics properties',
    migrate(state) {
        const runtimeVersion = state.runtimeVersion || 0;
        // Migrate Physics properties
        if (runtimeVersion < 18) {
            forEachObject(state, (obj) => {
                if (obj.componentType !== 'Play Kit/Physics') {
                    return;
                }
                if (obj.verticalGravity) {
                    obj.verticalGravity = (obj.verticalGravity * 9.8) / 100;
                }
                if (obj.horizontalGravity) {
                    obj.horizontalGravity = (obj.horizontalGravity * 9.8) / 100;
                }
            });
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
