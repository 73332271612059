import { jsx as _jsx } from "react/jsx-runtime";
import { Link as ChLink } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/system';
import NLink from 'next/link';
import { Button } from '../Button';
/**
 * Link component that renders an 'a' tag. See [Link](https://v1.chakra-ui.com/docs/components/navigation/link) for the complete documentation.
 */
export const Link = forwardRef((props, ref) => {
    return _jsx(ChLink, { ref: ref, ...props });
});
// Hoops we must jump through bc `next` has an `as` and `href` prop that conflict with
// chakras. the next `Link` renders an href so we need to use chakra's `as` in order
// to style it
const NextRouteLink = forwardRef(({ asPath, href: _, nHref, ...props }, ref) => {
    return _jsx(NLink, { ref: ref, as: asPath, href: nHref, ...props });
});
export const RouteLink = forwardRef(({ href, ...props }, ref) => {
    return _jsx(Link, { ref: ref, as: NextRouteLink, nHref: href, ...props });
});
export const ButtonLink = forwardRef((props, ref) => {
    return _jsx(Button, { as: Link, role: 'button', ref: ref, ...props });
});
