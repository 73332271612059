import { isReactor } from '../reactor.js';
import { META } from '../reactorObject.js';
import { isContainer } from './container.js';
import { DESCRIPTION, MASTER } from '../constants.js';
export function isComponent(obj) {
    return typeof obj === 'object' && DESCRIPTION in obj;
}
export function isEffect(component) {
    return component[DESCRIPTION]?._meta?.isEffect === true;
}
export function isProject(component) {
    // TODO: This is technially checking if component is the MAIN project,
    // but that doesn't seem to be how it's used.
    // Should it just check componentType?
    return component?.mainProject && component?.mainProject === component;
}
export function isPage(component) {
    return component.componentType === 'Play Kit/Page';
}
export function isCustomComponentInstance(component) {
    if (!component.componentType) {
        return false;
    }
    return isReactor(component[MASTER]);
}
export function isRelativelyPositioned(component) {
    return component.positionType === 'relative';
}
export function isSection(component) {
    return !!component?.componentType && component.componentType === 'Play Kit/Section';
}
export function isProjectView(component) {
    return !!component?.componentType && component.componentType === 'Play Kit/Project';
}
export function isViewContainer(component) {
    return !!component?.componentType && !!component.children;
}
export function isFlexContainer(component) {
    // Some containers, e.g. Emitter, don't have isFlex method.
    return !!component && isContainer(component) && component.isFlexContainer?.();
}
export function isOrderedItem(component) {
    return (!!component && isSection(component)) || isFlexItem(component);
}
export function isFlexItem(component) {
    return !!component && isFlexContainer(component?.parent);
}
// Return true if this project has content that should be hidden from LogRocket recordings
export function hasPrivateContent(info) {
    const allowedUsers = ['play', 'gallery', 'hatch', 'remixables', 'links'];
    return !allowedUsers.includes(info.ownerName) && !info.permissions.showInGallery;
}
export function isAncestor(view, ancestor) {
    let c = view.parent;
    while (c) {
        if (ancestor === c) {
            return true;
        }
        c = c.parent;
    }
    return false;
}
export function supportsFlexResizing(component) {
    return (!!component &&
        (isFlexContainer(component) || isTextComponent(component) || canFlexGrow(component)));
}
export function isTextComponent(component) {
    return component[DESCRIPTION]?._meta?.isTextComponent === true;
}
export function canFlexGrow(component) {
    return !!component && !isLayoutComponent(component) && !!component[META]?.supportFlexGrow;
}
export function isFlexGrowHorizontally(component) {
    return (!!component &&
        !isSection(component) &&
        isFlexItem(component) &&
        component.horizontalResizing === 'fill');
}
export function isFlexGrowVertically(component) {
    return (!!component &&
        !isSection(component) &&
        isFlexItem(component) &&
        component.verticalResizing === 'fill');
}
export function canFlexHugHorizontally(component) {
    return !!component && isFlexContainer(component) && component.horizontalResizing === 'hug';
}
export function canFlexHugVertically(component) {
    return !!component && isFlexContainer(component) && component.verticalResizing === 'hug';
}
export function isSectionOnlyChild(component) {
    return (isSection(component) && !!component?.parent?.children && component.parent.children.length === 1);
}
export function isLayoutComponent(component) {
    return (isSection(component) ||
        isPage(component) ||
        isProjectView(component) ||
        component.name === 'rootViewContainer');
}
/////////////////////////////
// Move these to workbench //
/////////////////////////////
export function isOnlyPage(reactor) {
    const pages = reactor.project?.pages;
    if (!pages) {
        return false;
    }
    return pages.length === 1 && pages[0] === reactor;
}
export function isOnlySection(reactor) {
    const sections = reactor?.project?.rootView?.children;
    if (!sections) {
        return false;
    }
    return sections.length === 1 && sections[0] === reactor;
}
export function rootViewOnlyContainsOneSection(reactor) {
    const sections = reactor?.project?.rootView?.children;
    if (!sections) {
        return false;
    }
    return sections.length === 1 && isSection(sections[0]);
}
export function selectionContainsAllPagesInProject(selection) {
    const pagesInSelection = selection.filter((component) => isPage(component));
    if (!pagesInSelection[0])
        return false;
    const pagesInProject = pagesInSelection[0].project.pages;
    const pagesNotInSelection = pagesInProject.filter((page) => !pagesInSelection.includes(page));
    return pagesNotInSelection.length < 1;
}
export function selectionContainsAllSectionsOnPage(selection) {
    const sectionsInSelection = selection.filter((component) => isSection(component));
    if (!sectionsInSelection[0])
        return false;
    const sectionsOnPage = sectionsInSelection[0].project.rootView.children;
    const sectionsNotInSelection = sectionsOnPage.filter((section) => !sectionsInSelection.includes(section) && !section.deleted);
    return sectionsNotInSelection.length < 1;
}
export function componentIsSizable(component) {
    if (component.parent) {
        return component.parent[META].childrenAreSizable ?? true;
    }
    return true;
}
