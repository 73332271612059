import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Remove unsupported effects #2',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Page') {
                obj.effects = obj.effects?.filter((effect) => {
                    if (effect.componentType === 'Play Kit/Draggable') {
                        return false;
                    }
                    else {
                        return true;
                    }
                });
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
