import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import { createGenericContext } from '@playful/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from './useRouter';
var _createGenericContext = createGenericContext(),
  _createGenericContext2 = _slicedToArray(_createGenericContext, 2),
  useRoute = _createGenericContext2[0],
  RouteProvider = _createGenericContext2[1];
export var useRouteCtx = useRoute;
export function RouteContext(_ref) {
  var children = _ref.children;
  var _useState = useState(''),
    prevPath = _useState[0],
    setPrevPath = _useState[1];
  var currPath = useRef('');
  var _useRouter = useRouter(),
    asPath = _useRouter.asPath;
  useEffect(function () {
    setPrevPath(currPath.current);
    currPath.current = asPath;
  }, [asPath]);
  return __jsx(RouteProvider, {
    value: {
      prevPath: prevPath
    }
  }, children);
}