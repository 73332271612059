// the bare required properties for our default users
export var anonymousUser = {
  id: '',
  name: '',
  email: '',
  userType: 'user',
  uploadsResourceId: '',
  isAnonymous: true // isAnonymous is true for Firebase Anonymous Users
};

export var signedOutUser = {
  id: '',
  name: '',
  email: '',
  userType: 'signedout',
  uploadsResourceId: '',
  isAnonymous: false // isAnonymous is true for Firebase Anonymous Users
};