import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _excluded = ["authType"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { forwardRef } from '@chakra-ui/react';
import { RouteLink } from '@playful/design_system';
import { ROUTE_FORGOT_PASSWORD, ROUTE_REGISTER, ROUTE_SIGNIN } from '../../routes';
import { useRouter } from '../../hooks/useRouter';
import React from 'react';
export var SIGN_IN = 'signIn';
export var SIGN_UP = 'signUp';
export var FORGOT_PW = 'forgotPw';
var authQueries = [SIGN_IN, SIGN_UP, FORGOT_PW];
export var authRouteLookup = {
  signIn: ROUTE_SIGNIN,
  signUp: ROUTE_REGISTER,
  forgotPw: ROUTE_FORGOT_PASSWORD
};
export function useAuthNavigation(type, options) {
  var _useRouter = useRouter(),
    query = _useRouter.query,
    push = _useRouter.push;
  return function () {
    return push({
      query: _objectSpread(_objectSpread({}, query), {}, {
        authType: type
      })
    }, authRouteLookup[type], options);
  };
}
export var AuthLink = forwardRef(function (_ref, ref) {
  var authType = _ref.authType,
    props = _objectWithoutProperties(_ref, _excluded);
  // preserve the existing query during auth for things like ?next
  var _useRouter2 = useRouter(),
    query = _useRouter2.query,
    pathname = _useRouter2.pathname;
  return __jsx(RouteLink, _extends({
    ref: ref
    // preserve the pathname as the original page the modal was opened over. on modal close, we'll need
    // to know where we started. for instance, a user going from /templates -> /signin -> /signup will
    // expect to land back on /templates if they close the modal.
    ,
    href: {
      pathname: pathname,
      query: _objectSpread(_objectSpread({}, query), {}, {
        authType: authType
      })
    },
    shallow: true,
    asPath: authRouteLookup[authType]
  }, props));
});