// Iterate and recurse down an (acyclic) object hierarchy, calling back for each one.
export function forEachObject(obj, callback) {
    if (Array.isArray(obj)) {
        for (const element of obj) {
            if (isObjectOrArray(element)) {
                forEachObject(element, callback);
            }
        }
    }
    else {
        for (const property in obj) {
            const value = obj[property];
            if (isObjectOrArray(value)) {
                forEachObject(value, callback);
            }
        }
    }
    callback(obj);
}
export function isObjectNotArray(value) {
    return value && typeof value === 'object' && !Array.isArray(value);
}
export function isObjectOrArray(value) {
    return value && typeof value === 'object';
}
export function mapObject(object, cb) {
    return Object.keys(object).reduce((result, key) => {
        const value = object[key];
        result[key] = cb(value, key);
        return result;
    }, {});
}
/**
 * Deep extend two or more objects.
 * @param target
 * @param ...sources
 */
export function extendDeep(target, ...sources) {
    for (const source of sources) {
        for (const key in source) {
            if (key in target) {
                if (isObjectNotArray(target[key]) && isObjectNotArray(source[key])) {
                    extendDeep(target[key], source[key]);
                }
            }
            else {
                if (isObjectNotArray(source[key])) {
                    target[key] = {};
                    extendDeep(target[key], source[key]);
                }
                else {
                    target[key] = source[key];
                }
            }
        }
    }
    return target;
}
