import { defineStyleConfig } from '@chakra-ui/react';
export const checkboxTheme = defineStyleConfig({
    defaultProps: { size: 'md', colorScheme: 'blue' },
    baseStyle: ({ colorScheme }) => ({
        icon: {
            transitionProperty: 'transform',
            transitionDuration: 'normal',
        },
        container: {
            _disabled: { cursor: 'not-allowed' },
        },
        control: {
            alignSelf: 'flex-start',
            margin: 0,
            w: '100%',
            transitionProperty: 'box-shadow',
            transitionDuration: 'normal',
            border: '2px solid',
            borderRadius: 'sm',
            borderColor: 'inherit',
            color: 'white',
            _checked: {
                bg: `${colorScheme}.500`,
                borderColor: `${colorScheme}.500`,
                color: 'white',
                _hover: { bg: `${colorScheme}.600`, borderColor: `${colorScheme}.600` },
                _disabled: { borderColor: 'gray.200', bg: 'gray.200', color: 'gray.500' },
            },
            _indeterminate: {
                bg: `${colorScheme}.500`,
                borderColor: `${colorScheme}.500`,
                color: 'white',
            },
            _disabled: { bg: 'gray.100', borderColor: 'gray.100' },
            _focus: {
                outline: '0.125rem solid',
                outlineColor: 'gray.800',
                outlineOffset: '0.125rem',
            },
            _invalid: { borderColor: 'red.500' },
        },
        label: { userSelect: 'none', _disabled: { opacity: 0.4 } },
    }),
    variants: {
        radio: {
            icon: {
                bgColor: 'currentColor',
                strokeWidth: 0,
                h: '8px',
                w: '8px',
                borderRadius: '100%',
            },
            container: {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            },
            control: {
                borderRadius: '100%',
                bgColor: 'transparent',
                border: '2px solid',
                borderColor: 'gray.200',
                color: 'white',
                margin: 0,
                alignSelf: 'center',
                _focus: {
                    outline: '0px',
                    borderColor: 'gray.300',
                    _hover: {
                        borderColor: 'gray.300',
                    },
                },
                _checked: {
                    bgColor: 'transparent',
                    borderColor: 'gray.200',
                    color: 'blue.500',
                    _hover: {
                        bgColor: 'transparent',
                        borderColor: 'gray.200',
                    },
                },
                _active: {
                    borderColor: 'gray.200',
                },
                _hover: {},
            },
            label: {},
        },
    },
    sizes: {
        xs: { control: { h: 4, w: 4 }, label: { fontSize: 'xs' }, icon: { fontSize: '0.45rem' } },
        sm: { control: { h: 4, w: 4 }, label: { fontSize: 'sm' }, icon: { fontSize: '0.45rem' } },
        md: { control: { w: 5, h: 5 }, label: { fontSize: 'md' }, icon: { fontSize: '0.625rem' } },
        lg: { control: { w: 5, h: 5 }, label: { fontSize: 'lg' }, icon: { fontSize: '0.625rem' } },
    },
});
