import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const selfObjectId = -1;
// Initialize a Variant from a value and an optional type.
function makeVariant(value, type) {
    if (type) {
        return { type, value };
    }
    else if (value === undefined) {
        return { type: 'undefined', value };
    }
    else if (typeof value === 'number') {
        return { type: 'number', value };
    }
    else if (typeof value === 'string') {
        return { type: 'string', value };
    }
    else if (typeof value === 'boolean') {
        return { type: 'boolean', value };
    }
    else if (typeof value === 'object') {
        return { type: 'object', value };
    }
    else {
        console.assert(false, 'Unsupported value type:', value);
        return { type: 'unknown', value };
    }
}
function isContainer(component) {
    return !!component.children;
}
const migration = {
    description: 'project migrations. slides to pages, delete width and height, migrate deck css convert .components to .children etc...',
    migrate(state) {
        const runtimeVersion = state.runtimeVersion || 0;
        if (runtimeVersion < 10) {
            // Convert Project.slides to Project.pages.
            if (state.slides) {
                state.pages = state.slides;
                delete state.slides;
            }
            // Delete Project.width and .height.
            delete state.width;
            delete state.height;
            // Delete Project.components (and .children from a couple test projects).
            delete state.components;
            delete state.children;
            // Migrate Deck CSS.
            if (state.css) {
                state.css = state.css.replace('.slide', '.page');
                state.css = state.css.replace('.deck', '.project');
            }
            forEachObject(state, (obj) => {
                if (!obj.componentType)
                    return;
                const component = obj;
                // No event handlers anymore.
                delete component.eventHandlers;
                // Convert Slides to Pages.
                if (component.componentType === 'Play Kit/Slide') {
                    component.componentType = 'Play Kit/Page';
                }
                // Convert .components to .children.
                if (component.components) {
                    component.children = component.components;
                    delete component.components;
                }
                // Convert Container.clipContents to Container.overflow.
                if (isContainer(component) && component.clipContents !== undefined) {
                    component.overflow = component.clipContents ? 'hide' : 'show';
                    delete component.clipContents;
                }
            });
            // Convert Interaction Action arguments to be Variants.
            // Convert Trigger and Action targets to use symbolic references when appropriate.
            let key = 1;
            function migrateActions(actions, interactionComponentId) {
                for (const action of actions) {
                    // Reference self symbolically.
                    if (action.targetId === interactionComponentId) {
                        action.targetId = selfObjectId;
                    }
                    // Give each action a unique key for rendering purposes.
                    if (!action.key) {
                        action.key = (key++).toString();
                    }
                    if (action.args) {
                        const args = action.args;
                        for (const argName in args) {
                            const value = args[argName];
                            if (value.type !== undefined) {
                                // Already a Variant.
                                if (value.type === 'actions') {
                                    migrateActions(value.value, interactionComponentId);
                                }
                            }
                            else {
                                args[argName] = makeVariant(value);
                            }
                        }
                    }
                    else {
                        // The args object isn't optional as it once was.
                        action.args = {};
                    }
                }
            }
            forEachObject(state, (obj) => {
                if (!obj.componentType || !obj.interactions) {
                    return;
                }
                for (const interaction of obj.interactions) {
                    if (!interaction.key) {
                        interaction.key = (key++).toString();
                    }
                    // Reference self symbolically.
                    if (interaction.trigger.targetId === obj[ID]) {
                        interaction.trigger.targetId = selfObjectId;
                    }
                    if (interaction.actions) {
                        migrateActions(interaction.actions, obj[ID]);
                    }
                }
            });
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
