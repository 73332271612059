/**
 * Joins an array into a sentence:
 * - `semanticJoin(['one'], 'and')` -> `'one'`
 * - `semanticJoin(['one', 'two'], 'and')` -> `'one and two'`
 * - `semanticJoin(['one', 'two', 'three'], 'or')` -> `'one, two, or three'`
 */
export function semanticJoin(arr, conjunction) {
  var arrCopy = arr.slice();
  var last = arrCopy.pop();
  var oxfordComma = arrCopy.length > 1 ? ',' : ''; // i'm not a monster...

  return arrCopy.length > 0 ? "".concat(arrCopy.join(', ')).concat(oxfordComma, " ").concat(conjunction, " ").concat(last) : last;
}