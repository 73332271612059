import { useEffect, useState } from 'react';
import { arraysEqual } from '@playful/utils';
// This effect prevents unnecessary re-renders by verifying the contents of the dependency array have changed
// rather than just the referential equality of the dependency array itself.
// i.e. ['x'] and ['x'] should not cause re-renders, even if the array object itself is difference.
export const useImmutableDependencies = (dependencies) => {
    const [immutableDependencies, setImmutableDependencies] = useState(dependencies || []);
    useEffect(() => {
        if (dependencies &&
            dependencies.length > 0 &&
            !arraysEqual(immutableDependencies, dependencies)) {
            setImmutableDependencies(dependencies);
        }
    }, [dependencies, immutableDependencies]);
    return immutableDependencies;
};
