import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
export const informationalUpgradeCTATheme = defineStyleConfig({
    defaultProps: {
        variant: 'yellow',
    },
    baseStyle: {
        box: {
            borderRadius: 'md',
            padding: 3,
            display: 'flex',
            w: '100%',
            // position: 'relative',
            zIndex: '1',
            overflow: 'hidden',
            justifyContent: 'space-between',
            textAlign: 'left',
        },
        logoWrap: {},
        logo: {},
    },
    variants: {
        yellow: defineStyle(({ colorScheme }) => {
            return {
                box: {
                    backgroundColor: 'yellow.500',
                },
                logo: {
                    fill: 'yellow.200',
                },
            };
        }),
    },
    sizes: {},
});
