import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
export const badgeTheme = defineStyleConfig({
    defaultProps: {
        variant: 'normal',
    },
    variants: {
        normal: defineStyle(({ colorScheme }) => ({
            color: `${colorScheme}.500`,
            bgColor: `${colorScheme}.50`,
        })),
    },
    sizes: {},
    baseStyle: {
        textTransform: 'none',
        fontWeight: 'medium',
        lineHeight: '18px',
        fontSize: 'xs',
        px: '6px',
        py: '2px',
    },
});
