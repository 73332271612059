import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Set Text Outline Effect placement to "outside" so we can change the default.',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.effects) {
                for (const effect of obj.effects) {
                    if (effect.componentType === 'Play Kit/TextOutline' && effect.placement === undefined) {
                        effect.placement = 'center';
                    }
                }
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
