import { axiosRequest } from './axiosRequest.js';
// Login/create a user
export async function authenticateUser({ userId, marketingOptIn, mobileProjectLink, }) {
    const { data } = await axiosRequest('authenticate', {
        method: 'POST',
        withCredentials: true,
        data: { userId, marketingOptIn, mobileProjectLink },
    });
    return data;
}
