import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const TEXT_TYPES = [
    'Play Kit/Text',
    'Play Kit/Subheading',
    'Play Kit/BodyText',
    'Play Kit/Heading',
];
const migration = {
    description: 'Find links that were underlined previously and move the underline to a <u> tag',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (TEXT_TYPES.includes(obj.componentType) && obj.html?.length) {
                const doc = new DOMParser().parseFromString(obj.html, 'text/html');
                doc.body.childNodes.forEach((node) => {
                    node.querySelectorAll('a').forEach((link) => {
                        if (!link.style.textDecoration ||
                            (link.style.textDecoration.length && !link.style.textDecoration.includes('none'))) {
                            // need to underline text
                            link.childNodes.forEach((child) => {
                                wrapTextNodesInUnderline(child);
                            });
                        }
                        link.style.textDecoration = 'none';
                    });
                });
                obj.html = doc.body.innerHTML;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
function wrapTextNodesInUnderline(currentNode) {
    if (currentNode.nodeType === Node.TEXT_NODE) {
        const parent = currentNode.parentNode;
        if (!parent || parent.nodeName === 'U') {
            return;
        }
        const underline = document.createElement('u');
        parent.replaceChild(underline, currentNode);
        underline.appendChild(currentNode.cloneNode(true));
    }
    else {
        currentNode.childNodes.forEach((child) => {
            wrapTextNodesInUnderline(child);
        });
    }
}
