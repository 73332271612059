import values from 'lodash/values.js';
import max from 'lodash/max.js';
import sortBy from 'lodash/sortBy.js';
const kChars = '!0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~'.split('');
// Build an index lookup map
const kCharMap = {};
for (let i = 0; i < kChars.length; i++) {
    kCharMap[kChars[i]] = i;
}
const kLowest = kChars[0];
const kHighest = kChars[kChars.length - 1];
// based on a numeric index, generate a sortKey that corresponds to it
// for safety, we won't allow the first character, since our key cannot end with that
// so this is effectively a base 63 encoding
export function toSortKeyFragment(s) {
    let n = parseInt(s, 10);
    if (n < 0)
        throw new Error(n + ' is not a valid sortKey index');
    let key = '';
    do {
        const trailing = n % 63;
        // index should be from 0-62, add 1 and our keyspace will exclude the first character
        key = kChars[trailing + 1] + key;
        n = (n - trailing) / 63;
    } while (n > 0);
    return key;
}
export function newSortKey(before, after, sessionId) {
    before = before || kLowest;
    after = after || kHighest;
    // the '-' is the separator for our suffix. It shouldn't influence the actual sort order
    // if we include it in the sort it creates an infinite loop
    // (e.g. we cannot insert between 'xz-0' and 'y-0')
    before = before.replace('-', '');
    after = after.replace('-', '');
    if (after[after.length - 1] === kLowest) {
        throw new Error(`Sort key error: after cannot end in '!': ${before} ${after}`);
    }
    if (before >= after) {
        throw new Error(`Sort key error: invalid before/after values: ${before} ${after}`);
    }
    let str = '';
    let i = 0;
    while (true) {
        const leftIndex = kCharMap[before[i]] !== undefined ? kCharMap[before[i]] : 0;
        const rightIndex = kCharMap[after[i]] !== undefined ? kCharMap[after[i]] : kChars.length - 1;
        i++;
        let charIndex;
        if (leftIndex + 1 < rightIndex) {
            charIndex = Math.floor((leftIndex + rightIndex) / 2);
        }
        else {
            charIndex = leftIndex;
        }
        const char = kChars[charIndex];
        str += kChars[charIndex];
        // It's important that we don't generate keys that end in the lowest
        // character, otherwise you can end up in situations where it's impossible
        // to insert a new key. For instance, you cannot insert a key between '!' and '!!'
        // given the charset defined above.
        if (before < str && str < after && char !== kLowest)
            break;
    }
    if (sessionId) {
        const suffix = toSortKeyFragment(sessionId);
        str += '-' + suffix;
    }
    return str;
}
export function first(container) {
    const ary = containerSort(container);
    return ary.length ? ary[0] : null;
}
export function last(container) {
    const ary = containerSort(container);
    return ary.length ? ary[ary.length - 1] : null;
}
export function appendKey(container, entry) {
    const maxSortKey = max(values(container).map((e) => e.sortKey)) || null;
    return newSortKey(maxSortKey, null);
}
// Returns an array of entry sorted by their sortKeys
export function containerSort(container) {
    // container
    //   .filter((it) => !it.sortKey)
    //   .map((it) => console.log('Missing sortKey', (it as any)?.name));
    return sortBy(container, 'sortKey');
}
export function sortKeyOrdinal(entry, container) {
    return sortBy(container, 'sortKey').findIndex((c) => c.sortKey === entry.sortKey);
}
