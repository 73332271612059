import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'no op',
    draft: false,
    async migrate(state) {
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
