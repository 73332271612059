import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
function forEachBlock(block, callback) {
    callback(block);
    if (Array.isArray(block.blocks)) {
        for (const child of block.blocks) {
            forEachBlock(child, callback);
        }
    }
}
function forEachBlocks(blocks, callback) {
    for (const child of blocks) {
        forEachBlock(child, callback);
    }
}
const migration = {
    description: 'Migrate block instructions to block actions',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.blocks) {
                forEachBlocks(obj.blocks, (block) => {
                    if (block.type === 'setProperty' && block.instructions) {
                        block.actions = block.instructions.map((instruction) => {
                            return {
                                targetId: instruction.targetId,
                                method: instruction.type, // renamed field
                                args: instruction.args,
                            };
                        });
                        delete block.instructions;
                    }
                    if (block.type === 'call' && block.instruction) {
                        block.action = {
                            targetId: (block.action || block.instruction).targetId,
                            method: (block.action || block.instruction).type, // renamed field
                            args: (block.action || block.instruction).args,
                        };
                        delete block.instruction;
                    }
                });
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
