import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Moves Physics properties from effect into container component',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Page' || obj.componentType === 'Play Kit/Container') {
                // look for Physics children components (aka gravity canvas elements)
                const physics = obj.children?.find((c) => c.componentType === 'Play Kit/Physics');
                if (physics) {
                    const props = [
                        'verticalGravity',
                        'horizontalGravity',
                        'collisionVelocityThreshold',
                        'defaultType',
                        'defaultShape',
                        'defaultDensity',
                        'defaultBounciness',
                        'defaultFriction',
                    ];
                    props.forEach((prop) => {
                        if (physics[prop]) {
                            obj[prop] = physics[prop];
                        }
                    });
                    // renaming the enabled property
                    // physics.enabled defaulted to true
                    obj.physicsEnabled = physics.enabled ?? true;
                }
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
