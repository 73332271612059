import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { proxy, snapshot } from 'valtio';
export var projectDesignState = proxy({
  dirtyCount: 0,
  draggingContent: {}
});
export var incrementDirtyCount = function incrementDirtyCount() {
  var _snapshot = snapshot(projectDesignState),
    dirtyCount = _snapshot.dirtyCount;
  projectDesignState.dirtyCount = dirtyCount + 1;
};
export var resetDirtyCount = function resetDirtyCount() {
  projectDesignState.dirtyCount = 0;
};
var add = function add(stateKey, content) {
  var _snapshot2 = snapshot(projectDesignState),
    state = _snapshot2[stateKey];
  var contentId = content.id;
  var existing = state === null || state === void 0 ? void 0 : state[contentId];
  if (existing) {
    var _content;
    content = _objectSpread(_objectSpread(_objectSpread({}, existing), content), {}, {
      options: _objectSpread(_objectSpread({}, existing === null || existing === void 0 ? void 0 : existing.options), (_content = content) === null || _content === void 0 ? void 0 : _content.options)
    });
  }
  projectDesignState[stateKey] = _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, contentId, content));
};
var remove = function remove(stateKey, content) {
  if (!content) {
    projectDesignState[stateKey] = {};
  } else {
    var contentId = content.id;
    var _snapshot3 = snapshot(projectDesignState),
      state = _snapshot3[stateKey];
    var omit = state[contentId],
      rest = _objectWithoutProperties(state, [contentId].map(_toPropertyKey));
    projectDesignState[stateKey] = rest;
  }
};
export var addDraggingContent = function addDraggingContent(content) {
  add('draggingContent', content);
};
export var removeDraggingContent = function removeDraggingContent(content) {
  remove('draggingContent', content);
};