import { getConfig } from '@playful/config';
import { isClientCtx } from '@playful/utils';
import axios from 'axios';
import { mergeTokenConfig } from './mergeTokenConfig.js';
// right now we only augment with a token
axios.interceptors.request.use(mergeTokenConfig);
// This function is used to get the base path for the API.
// Note that the base path is different depending on whether the code is running on the client or server.
export function getBasePath() {
    const { apiRoot } = getConfig();
    return isClientCtx() ? apiRoot : `${process.env.BACKEND_URL}${apiRoot}`;
}
// we would probably be better served using an axios instance in the future with a base config,
// but because of the asynchronous nature of `getConfig`, this should be fine for now.
// `apiRequest` is still around for backwards compatibility, as functions using it may be
// expecting the error to be res.statusText.
export const axiosRequest = (endpoint, config = {}) => axios({
    // this should be `baseUrl` of an axios instance, but `getConfig` isn't available until
    url: !endpoint.startsWith('/') ? `${getBasePath()}/${endpoint}` : endpoint,
    ...config,
}).catch((e) => {
    throw e.response;
});
