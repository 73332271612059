import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const DEFAULT_COLOR_OVERLAY_OPACITY = 0.25;
const DEFAULT_BACKGROUND_IMAGE_TYPE = 'image';
const migration = {
    description: 'migrating page backgrounds to allow for both color and media at same time, plus moving color overlay to background color / image opacity',
    draft: false,
    async migrate(state) {
        const projectBG = state.backgroundColor;
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Page') {
                // Had some old code lying around from when backgroundColor was on the project, lets just migrate it.
                if (projectBG && !obj.backgroundColor) {
                    obj.backgroundColor = projectBG;
                }
                if (obj.backgroundType === 'media') {
                    if (obj.backgroundImageColorOverlayEnabled) {
                        if (obj.backgroundImageColorOverlay) {
                            obj.backgroundColor = obj.backgroundImageColorOverlay;
                        }
                        if (obj.backgroundImageColorOverlayOpacity) {
                            obj.backgroundImageOpacity = 1 - obj.backgroundImageColorOverlayOpacity;
                        }
                        else {
                            obj.backgroundImageOpacity = 1 - DEFAULT_COLOR_OVERLAY_OPACITY;
                        }
                    }
                    obj.backgroundImageMimeType = obj.backgroundImageType || DEFAULT_BACKGROUND_IMAGE_TYPE;
                }
                delete obj.backgroundType;
                delete obj.backgroundImageType;
                delete obj.backgroundImageColorOverlayEnabled;
                delete obj.backgroundImageColorOverlay;
                delete obj.backgroundImageColorOverlayOpacity;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
