import { apiRequest } from './apiRequest.js';
import { fetchRetry } from './fetchRetry.js';
export async function apiRequestWithRetry(endpoint, config = {}, numberOfTries = 5) {
    async function request() {
        return apiRequest(endpoint, config);
    }
    const res = await fetchRetry(request, numberOfTries);
    if (!res.ok) {
        throw new Error(res.statusText);
    }
    return res;
}
