export const FEATURE_UNDER_CONSTRUCTION = 'underConstruction';
export const FEATURE_INTERNAL_TOOLS = 'internalTools';
export const FEATURE_ALL_ELEMENTS_PANEL = 'allElementsPanel';
export const FEATURE_NEW_CONTENT_PANEL = 'newContentPanel';
export const FEATURE_PROJECT_TAGS = 'projectTags';
export const FEATURE_BREAKPOINTS = 'breakpoints';
export const FEATURE_TEXT_PREVIEW_IN_LISTS = 'textPreviewInLists';
export const TUTORIAL_VISUAL_SCRIPTING = 'tutorialVisualScripting';
export const TUTORIAL_BREAKPOINTS = 'tutorialBreakpoints';
export const TUTORIAL_BREAKPOINT_OVERRIDES = 'tutorialBreakpointOverrides';
// All users can see these flags in their user menu. The value is the default value if the flag is not set.
export const UserFeatureFlags = {
    [FEATURE_UNDER_CONSTRUCTION]: false,
};
// Only shown for employees. The value is the default value if the flag is not set.
export const EmployeeFeatureFlags = {
    [FEATURE_INTERNAL_TOOLS]: false,
    [FEATURE_ALL_ELEMENTS_PANEL]: false,
    [FEATURE_NEW_CONTENT_PANEL]: false,
    [FEATURE_PROJECT_TAGS]: true,
    [FEATURE_TEXT_PREVIEW_IN_LISTS]: false,
    [TUTORIAL_BREAKPOINTS]: undefined,
    [TUTORIAL_BREAKPOINT_OVERRIDES]: undefined,
};
// Flags that are not shown in the user menu. Only accessible via the CLI
const PrivateFeatureFlags = {};
// Tutorial flags have 3 states: true, false, and undefined.
// Undefined is used for "never seen it before"
const Tutorials = {
    [TUTORIAL_VISUAL_SCRIPTING]: undefined,
    [TUTORIAL_BREAKPOINTS]: undefined,
    [TUTORIAL_BREAKPOINT_OVERRIDES]: undefined,
};
export const userFlagDefaults = {
    ...UserFeatureFlags,
    ...EmployeeFeatureFlags,
    ...PrivateFeatureFlags,
    ...Tutorials,
};
