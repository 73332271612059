import { isReactor } from '../reactor.js';
import { ACTIVE_BREAKPOINT, PRIMARY, VARIANT_ID } from '../constants.js';
import { isComponent } from './utils.js';
export const PRIMARY_BREAKPOINT_ID = 'desktop';
export const DEFAULT_BREAKPOINTS = {
    // Note: desktop is the PRIMARY breakpoint so you won't encounter this ID.
    // The PRIMARY breakpoint is used when the breakpointId is undefined.
    [PRIMARY_BREAKPOINT_ID]: {
        variantId: PRIMARY_BREAKPOINT_ID,
        name: 'Desktop',
        width: 1200,
    },
    tablet: {
        variantId: 'tablet',
        name: 'Tablet',
        width: 768,
    },
    mobile: {
        variantId: 'mobile',
        name: 'Mobile',
        width: 375,
    },
};
export function isVariantReactor(reactor) {
    return isReactor(reactor) && reactor[VARIANT_ID];
}
export function isPropertyOverridden(component, property) {
    const activeBreakpointReactor = component[ACTIVE_BREAKPOINT];
    if (!activeBreakpointReactor) {
        return false;
    }
    return property in activeBreakpointReactor;
}
// given a component run a callback on all the variants of the component, including [PRIMARY]
export function forEachVariantReactor(component, callback) {
    // error checking because this can only be run on a full formed, initialized, and promoted Component
    if (!isComponent(component)) {
        throw new Error('forEachVariantReactor: expected a component');
    }
    if (!component[PRIMARY]) {
        throw new Error('forEachVariantReactor: expected a component with a primary variant');
    }
    // start with the primary variant
    callback(component[PRIMARY], PRIMARY_BREAKPOINT_ID);
    for (const variantId in component.variants) {
        const variant = component.variants[variantId];
        if (isVariantReactor(variant)) {
            callback(variant, variantId);
        }
    }
}
/*
 * Determine the active breakpoint key for a component
 * return undefined if primary breakpoint should be used
 * [PRIMARY] is the primary breakpoint
 *
 * pageState is expected to be the storedState of the page
 * pageState.width should be the primary breakpoint width
 *
 * If you have a Page component, pass page[PRIMARY] as pageState
 */
export function determineBreakpointKey(viewportWidth, pageState) {
    const primaryWidth = pageState.width;
    if (!primaryWidth) {
        return undefined;
    }
    const breakpoints = Object.values((pageState.breakpoints || {}));
    if (breakpoints.length === 0) {
        return undefined;
    }
    breakpoints.push({
        name: PRIMARY_BREAKPOINT_ID,
        variantId: PRIMARY_BREAKPOINT_ID,
        width: primaryWidth,
    });
    breakpoints.sort((a, b) => a.width - b.width);
    let breakpoint = breakpoints[0];
    for (const bp of breakpoints) {
        if (bp.width > viewportWidth) {
            break;
        }
        breakpoint = bp;
    }
    return breakpoint.variantId === PRIMARY_BREAKPOINT_ID ? undefined : breakpoint.variantId;
}
