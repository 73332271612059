export const headingTheme = {
    baseStyle: {
        fontWeight: 'medium',
        lineHeight: 'shorter',
    },
    sizes: {
        // We want Inter font for all headings except for the largest ones, which should use Nikkei font
        // We also want to make the smaller Inter headings bold, but not the larger Nikkei ones
        '2xs': { fontWeight: 700 },
        xs: { fontWeight: 700 },
        sm: { fontWeight: 700 },
        md: { fontWeight: 700 },
        lg: { fontFamily: 'nikkei' },
        xl: { fontFamily: 'nikkei' },
        '2xl': { fontFamily: 'nikkei' },
        '3xl': { fontFamily: 'nikkei' },
        '4xl': { fontFamily: 'nikkei' },
    },
    // fontSizes: sizesOverrides,
    variants: {
        AppTitle: { fontSize: 'md' },
    },
    defaultProps: {},
};
