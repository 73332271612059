import { useEffect, useState } from 'react';
import { useImmutableDependencies } from './useImmutableDependencies.js';
export function useOnPropertyChange(component, dependencies) {
    const [change, setChange] = useState();
    const immutableDependencies = useImmutableDependencies(dependencies);
    useEffect(() => {
        if (!component) {
            // Shouldn't be required, but is a nice sanity check.
            return;
        }
        function handleUpdate(reactor, property, newValue, oldValue, type) {
            // If none of the changes properties are in the dependency array, don't update
            if (immutableDependencies.length > 0 && !immutableDependencies.includes(property)) {
                return;
            }
            setChange({ property, newValue, oldValue, type });
        }
        const listener = component?.onPropertyChange?.(handleUpdate);
        return () => {
            listener.dispose();
        };
    }, [component, immutableDependencies]);
    if (component && change) {
        return change;
    }
    return { property: undefined, newValue: undefined, oldValue: undefined, type: undefined };
}
