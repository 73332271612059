import { axiosRequest } from './axiosRequest.js';
// Fetch a user
export async function getUser(userId) {
    const { data } = await axiosRequest(`users/${userId}`);
    return data;
}
export async function updateUser(userId, updates) {
    const { data } = await axiosRequest(`users/${userId}`, {
        method: 'POST',
        withCredentials: true,
        data: updates,
    });
    return data;
}
// Fetch a user's flags
export async function getUserFlags(userId) {
    const { data } = await axiosRequest(`users/${userId}/flags`);
    return data;
}
// Update a user's flags
export async function updateUserFlags(userId, flags) {
    const { data } = await axiosRequest(`users/${userId}/flags`, {
        method: 'POST',
        withCredentials: true,
        data: flags,
    });
    return data;
}
// Check a username
export async function checkUsername(username, config = {}) {
    const { data } = await axiosRequest(`check_username/${username}`, config);
    return data;
}
// Set a user's username
export async function setUsername(userId, username) {
    const { data } = await axiosRequest(`users/${userId}/set_username`, {
        method: 'POST',
        withCredentials: true,
        data: { name: username },
    });
    return data;
}
// Copy From Anonymous user's data into existing User
export async function copyFromUser(userId, sourceToken) {
    const { data } = await axiosRequest(`users/${userId}/copy_from`, {
        method: 'POST',
        withCredentials: true,
        data: { sourceToken },
    });
    return data;
}
export async function discourseSSO(userId, sso, sig) {
    const { data } = await axiosRequest(`users/${userId}/discourse_sso`, {
        method: 'POST',
        withCredentials: true,
        data: { sso, sig },
    });
    return `${data.return_sso_url}?sso=${data.sso}&sig=${data.sig}`;
}
export async function getSubscriptionInfo(useriId, subscriptionId) {
    const { data } = await axiosRequest(`users/${useriId}/subscriptions/${subscriptionId}`);
    return data;
}
