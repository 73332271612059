import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Convert existing interactions eventtype-based javascript into single javascript property',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType && obj.interactions) {
                let javascript = obj.javascript || '';
                obj.interactions.forEach((interaction) => {
                    if (interaction.showJavascript) {
                        const type = interaction.trigger.event;
                        const script = interaction.javascript || '';
                        // Check for most common uses of await and make event handler async
                        const isAsync = /[\s|\n|\r|\(|\{]?(await)[\s\(\{]/g.test(script);
                        if (javascript?.length) {
                            javascript += '\n\n';
                        }
                        if (isAsync) {
                            javascript += `this.on('${type}', async () => {`;
                        }
                        else {
                            javascript += `this.on('${type}', () => {`;
                        }
                        javascript += '\n';
                        javascript += `  ${script}`;
                        javascript += '\n';
                        javascript += '});';
                    }
                });
                obj.interactions = obj.interactions.filter((interaction) => {
                    return !interaction.showJavascript;
                });
                obj.javascript = javascript;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
