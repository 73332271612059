import { defineStyleConfig } from '@chakra-ui/react';
import { keyframes } from '@chakra-ui/system';
import colors from '../theme/foundations/colors';
const placeholderAnimation = keyframes({
    from: {
        opacity: 1,
    },
    to: {
        opacity: 0,
    },
});
export const skeletonTheme = defineStyleConfig({
    baseStyle: {
        boxShadow: 'none',
        backgroundClip: 'padding-box',
        bgColor: colors.gray['100'],
        cursor: 'default',
        color: 'transparent',
        pointerEvents: 'none',
        userSelect: 'none',
        '&::before, *': {
            visibility: 'hidden',
        },
        ':after': {
            content: "''",
            animation: `0.8s linear infinite alternate ${placeholderAnimation}`,
            bgColor: colors.gray['300'],
            opacity: 0,
            display: 'block',
            width: 'full',
            height: 'full',
        },
    },
});
