export function moveArrayElement(array, from, to) {
    if (to === from)
        return array;
    const target = array[from];
    const increment = to < from ? -1 : 1;
    for (let k = from; k != to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
}
// Simple equality: must hold the same values in the same order.
export function arraysEqual(a, b) {
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}
// split an array into 2 arrays based on a predicate
export function partition(array, predicate) {
    const truthy = [];
    const falsy = [];
    for (const item of array) {
        if (predicate(item)) {
            truthy.push(item);
        }
        else {
            falsy.push(item);
        }
    }
    return [truthy, falsy];
}
