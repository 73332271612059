import { DESCRIPTION_PRIMITIVES } from '../descriptions.js';
import { ACTIVE_BREAKPOINT_ID } from '../constants.js';
export function getDefaultMetaData() {
    return {
        exported: false,
        commands: {},
        events: {},
        properties: {
            [ACTIVE_BREAKPOINT_ID]: {
                type: DESCRIPTION_PRIMITIVES.STRING,
                hidden: true,
            },
            variants: {
                type: DESCRIPTION_PRIMITIVES.OBJECT,
                hidden: true,
                isReactor: false,
            },
            deleted: {
                type: DESCRIPTION_PRIMITIVES.BOOLEAN,
                hidden: true,
                default: false,
                description: 'Is the effect deleted from its breakpoint',
            },
        },
    };
}
