import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const parentObjectId = -2;
const migration = {
    description: 'Convert Action targets to use symbolic references to parent',
    migrate(state) {
        const runtimeVersion = state.runtimeVersion || 0;
        // Convert Action targets to use symbolic references to parent.
        if (runtimeVersion < 11) {
            function migrateActions(actions, parentComponentId) {
                for (const action of actions) {
                    // Reference parent symbolically.
                    if (parentComponentId !== undefined && action.targetId === parentComponentId) {
                        action.targetId = parentObjectId;
                        //console.log('migrated action target to parent');
                    }
                    if (action.args) {
                        const args = action.args;
                        for (const argName in args) {
                            const value = args[argName];
                            if (value.type !== undefined) {
                                if (value.type === 'actions') {
                                    migrateActions(value.value, parentComponentId);
                                }
                            }
                        }
                    }
                }
            }
            forEachObject(state, (obj) => {
                if (!obj.componentType || !obj.interactions) {
                    return;
                }
                for (const interaction of obj.interactions) {
                    if (interaction.actions) {
                        migrateActions(interaction.actions, obj?.parent?.[ID]);
                    }
                }
            });
            // re-run the removal of these imports
            state.Imports = state.Imports?.filter((imp) => imp &&
                imp.name !== 'Shapes' &&
                imp.name !== 'Location Component' &&
                imp.name !== 'Mouse' &&
                imp.name !== 'Squircle');
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
