/**
 * This class gathers and logs performance stats for the project.
 * It logs the avg, min, max, and p90 for each measure.
 *
 * You can enable it by passing the ?logPerformance=true query param.
 * or by setting localstorage to true: localStorage.setItem('logPerformance', 'true')
 *
 * The query param method doesn't work in the Workbench
 */
export class PerformanceTracker {
    _tickId = 0;
    _rafId = 0;
    _collecting = false;
    _avgStats = {};
    _frameStats = { sum: 0, count: 0 };
    constructor() {
        if (shouldLogPerformance()) {
            this.startLogging();
        }
    }
    // Start logging performance stats to the console
    startLogging(interval = 1000) {
        this.stopLogging();
        this._tickId = window.setInterval(() => {
            console.log('FPS:', this.getFPS().toFixed(1));
            console.table(this.getDetailedStats());
            console.table(this.getStats());
        }, interval);
        this.start();
    }
    // Stop logging performance stats to the console
    stopLogging() {
        if (this._tickId) {
            window.clearInterval(this._tickId);
        }
        this.stop();
    }
    // Collect performance stats for a given time period
    collect(time = 5000) {
        if (this._collecting)
            return Promise.resolve({});
        this.start();
        return new Promise((resolve) => {
            window.setTimeout(() => {
                resolve(this.getDetailedStats());
            }, time);
        });
    }
    // Start collecting stats
    start() {
        if (this._collecting)
            return;
        this._collecting = true;
        // Collect frame-to-frame timing info
        this.startMark('frame');
        this._avgStats = {
            frame: { sum: 0, count: 0 },
        };
        const frame = () => {
            this._frameStats.sum += this.endMark('frame');
            this._frameStats.count++;
            this.startMark('frame');
            this._rafId = window.requestAnimationFrame(frame);
        };
        this._rafId = window.requestAnimationFrame(frame);
    }
    // Stop collecting stats
    stop() {
        this._collecting = false;
        if (this._rafId) {
            window.cancelAnimationFrame(this._rafId);
        }
    }
    // Mark the start of a performance measure
    startMark(name) {
        if (!this._collecting)
            return;
        performance.mark(`hatch:${name}:start`);
    }
    // Mark the end of a performance measure
    endMark(name) {
        if (!this._collecting)
            return 0;
        if (this._avgStats[name] === undefined) {
            this._avgStats[name] = { sum: 0, count: 0 };
        }
        try {
            const duration = performance.measure(`hatch:${name}`, `hatch:${name}:start`);
            this._avgStats[name].sum += duration.duration;
            this._avgStats[name].count++;
            return duration.duration;
        }
        catch (e) {
            return 0;
        }
    }
    // Return the average frames per second since the last call
    getFPS() {
        if (!this._collecting)
            return 0;
        const frame = this._frameStats;
        if (frame.sum === 0) {
            frame.count = 0; // just in case
            return 0;
        }
        const fps = frame.count / (frame.sum / 1000);
        frame.sum = 0;
        frame.count = 0;
        return fps;
    }
    // Get simple performance stats
    getStats() {
        if (!this._collecting)
            return {};
        const stats = Object.keys(this._avgStats)
            .sort()
            .reduce((acc, key) => {
            const entry = this._avgStats[key];
            if (entry.count > 0) {
                acc[key] = entry.sum / entry.count;
                entry.sum = 0;
                entry.count = 0;
            }
            return acc;
        }, {});
        return stats;
    }
    // Get detaild performance stats
    getDetailedStats() {
        // Extract all the performance measures
        const durations = performance.getEntriesByType('measure').reduce((acc, entry) => {
            if (!entry.name.startsWith('hatch:'))
                return acc;
            acc[entry.name] = acc[entry.name] || [];
            acc[entry.name].push(entry.duration);
            return acc;
        }, {});
        // Clear our accumulated data
        Object.keys(durations).forEach((key) => {
            performance.clearMeasures(key);
            performance.clearMarks(`${key}:start`);
            performance.clearMarks(`${key}:end`);
        });
        const emptyStats = {
            sum: 0,
            count: 0,
            min: Number.MAX_VALUE,
            max: 0,
            p90: 0,
            avg: 0,
        };
        const entryStats = Object.keys(durations)
            .sort()
            .reduce((acc, key) => {
            const stats = durations[key].reduce((acc, duration) => {
                acc.sum += duration;
                acc.count++;
                acc.min = Math.min(acc.min, duration);
                acc.max = Math.max(acc.max, duration);
                return acc;
            }, { ...emptyStats });
            stats.avg = stats.sum / stats.count;
            stats.p90 = getPercentile(durations[key], 90);
            key = key.replace('hatch:', '');
            acc[key] = stats;
            return acc;
        }, {});
        return entryStats;
    }
}
const shouldLogPerformance = () => {
    if (typeof window === 'undefined')
        return false;
    const params = new URLSearchParams(window.location.search);
    const perf = params.get('perf')?.toLowerCase() ?? '';
    if (perf.includes('log') || perf === 'true')
        return true;
    return false;
};
function getPercentile(arr, percentile) {
    const sortedArr = arr.slice().sort((a, b) => a - b);
    const index = Math.ceil((percentile / 100) * sortedArr.length) - 1;
    return sortedArr[index];
}
export function flattenStats(stats) {
    return Object.keys(stats).reduce((acc, key) => {
        const entry = stats[key];
        if (entry.count === 1) {
            acc[key] = entry.avg;
        }
        else {
            acc[`${key}_count`] = entry.count;
            acc[`${key}_avg`] = entry.avg;
            acc[`${key}_min`] = entry.min;
            acc[`${key}_max`] = entry.max;
            acc[`${key}_p90`] = entry.p90;
        }
        return acc;
    }, {});
}
export const perfTracker = new PerformanceTracker();
