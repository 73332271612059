import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["header", "footer", "onSubmit", "children", "onClose", "isOpen"];
var __jsx = React.createElement;
import { Center } from '@chakra-ui/react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@playful/design_system';
import React from 'react';
import HatchSvg from '../../assets/hatch.svg';
export function InlineAuthModalLayout(_ref) {
  var Header = _ref.header,
    Footer = _ref.footer,
    onSubmit = _ref.onSubmit,
    children = _ref.children,
    onClose = _ref.onClose,
    isOpen = _ref.isOpen,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Modal, _extends({
    size: "sm",
    isCentered: true,
    isOpen: isOpen,
    onClose: onClose,
    "aria-labelledby": "form-dialog-title"
  }, props), __jsx(ModalOverlay, null), __jsx(ModalContent, {
    textAlign: "center"
  }, !Header && __jsx(Center, {
    w: "100%",
    bg: "yellow.500",
    h: "140px",
    borderTopRadius: "md"
  }, __jsx(HatchSvg, null)), Header && __jsx(ModalHeader, {
    px: 12,
    pt: 10,
    fontWeight: 'medium'
  }, Header), __jsx(ModalBody, {
    as: "form",
    px: 8,
    pb: 8,
    pt: 5,
    noValidate: true,
    onSubmit: onSubmit,
    "data-cy": "auth-form"
  }, children), Footer && __jsx(ModalFooter, null, Footer)));
}