import { customAlphabet } from 'nanoid';
import { durationDefault, easeDefault, keyframeOptionsDefault, } from './types.js';
// JS var safe alphabet
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';
const uid = customAlphabet(alphabet, 16);
//
// Factory functions
//
export function callBlockFactory(action) {
    return {
        type: 'call',
        uid: uid(),
        action,
    };
}
export function dispatchEventBlockFactory(targetId, customEvent) {
    return {
        type: 'dispatchEvent',
        uid: uid(),
        customEvent,
        targetId,
    };
}
export function setPropertyBlockFactory(targetId, args = {}) {
    return {
        type: 'setProperty',
        uid: uid(),
        targetId,
        presets: [
            {
                actions: [
                    {
                        targetId,
                        method: 'setProperty',
                        args,
                    },
                ],
                uid: uid(),
                ordinal: 0,
            },
        ],
        animated: false,
        easing: easeDefault,
        duration: durationDefault,
    };
}
export function setMulitplePropertyBlockFactory(targetId, actions) {
    return {
        type: 'setProperty',
        uid: uid(),
        targetId,
        presets: [{ actions, uid: uid(), ordinal: 0 }],
        animated: false,
        easing: easeDefault,
        duration: 0.5,
    };
}
export function animatePropertyBlockFactory(targetId, actions, options = keyframeOptionsDefault) {
    return {
        type: 'setProperty',
        uid: uid(),
        targetId,
        presets: [presetFactory(actions)],
        animated: true,
        ...options,
    };
}
export function presetFactory(actions, ordinal = 0) {
    return {
        actions,
        uid: uid(),
        ordinal,
    };
}
export function toggleBlockFactory(targetId, _presets, animated = false, options = keyframeOptionsDefault) {
    const presets = _presets || [
        {
            actions: [
                {
                    targetId,
                    method: 'setProperty',
                    args: {},
                },
            ],
            uid: uid(),
            ordinal: 0,
        },
        {
            actions: [
                {
                    targetId,
                    method: 'setProperty',
                    args: {},
                },
            ],
            uid: uid(),
            ordinal: 0,
        },
    ];
    return {
        type: 'toggle',
        uid: uid(),
        targetId,
        presets,
        animated,
        ...options,
    };
}
export function conditionalBlockFactory(condition, blocks) {
    return {
        type: 'cond',
        uid: uid(),
        blocks,
        condition,
    };
}
export function ifElseFactory(blocks) {
    return {
        type: 'ifElse',
        uid: uid(),
        blocks,
    };
}
export function repeatFactory(iterations, blocks) {
    return {
        type: 'repeat',
        repeatType: 'times',
        uid: uid(),
        blocks,
        iterations,
    };
}
export function throttleFactory(millis, repeatBlock) {
    return {
        ...repeatBlock,
        throttled: true,
        throttleDuration: millis,
    };
}
export function repeatUntilFactory(condition, blocks) {
    return {
        type: 'repeat',
        repeatType: 'until',
        uid: uid(),
        blocks,
        condition,
    };
}
export function repeatForeverFactory(blocks) {
    return {
        type: 'repeat',
        repeatType: 'forever',
        uid: uid(),
        blocks,
    };
}
export function waitBlockFactory(s, comment = '') {
    const waitType = typeof s === 'number' ? 'time' : 'promises';
    const seconds = typeof s === 'number' ? s : 1;
    return {
        type: 'wait',
        uid: uid(),
        waitType,
        seconds,
        comment,
    };
}
export function triggerBlockFactory(event, targetId, blocks, isCustomEvent = false) {
    return {
        type: 'trigger',
        uid: uid(),
        targetId,
        event,
        blocks,
        isCustomEvent,
    };
}
export function commentBlockFactory(text) {
    return {
        type: 'comment',
        uid: uid(),
        text,
    };
}
// export function breakBlockFactory(): BreakBlock {
//   return {
//     type: 'break',
//     uid: uid(),
//   };
// }
// export function chanceBlockFactory(chance: number, blocks: AnyBlock[]): ChanceBlock {
//   return {
//     type: 'chance',
//     uid: uid(),
//     chance,
//     blocks,
//   };
// }
// export function moveToBlockFactory(
//   targetId: ReactorId,
//   moveType: MoveToBlock['moveType'] = 'component',
//   moveTo: ReactorId = 0,
//   padding = 0,
//   animated = false,
//   options = keyframeOptionsDefault,
// ): MoveToBlock {
//   return {
//     type: 'moveTo',
//     uid: uid(),
//     moveTo,
//     padding,
//     targetId,
//     moveType,
//     animated,
//     ...options,
//   };
// }
export function translateByBlockFactory(targetId, distance = 25, towards = 'right', animated = true, options = keyframeOptionsDefault) {
    return {
        type: 'translateBy',
        uid: uid(),
        targetId,
        distance,
        towards,
        animated,
        ...options,
    };
}
export function rotateByBlockFactory(targetId, degrees = 45, direction = 'cw', animated = true, options = keyframeOptionsDefault) {
    return {
        type: 'rotateBy',
        uid: uid(),
        targetId,
        degrees,
        direction,
        animated,
        ...options,
    };
}
export function scaleByBlockFactory(targetId, scale = 1.5, animated = true, options = keyframeOptionsDefault) {
    return {
        type: 'scaleBy',
        uid: uid(),
        targetId,
        scale,
        animated,
        ...options,
    };
}
export function visibilityBlockFactory(targetId, visibleOnLoad = false, appearance = 'show', animated = true, options = keyframeOptionsDefault) {
    return {
        type: 'visibility',
        uid: uid(),
        targetId,
        appearance,
        visibleOnLoad: !!visibleOnLoad,
        animated,
        ...options,
    };
}
export function goToLinkBlockFactory(linkTo, target, urlOrPage) {
    return {
        type: 'goToLink',
        uid: uid(),
        linkTo,
        target,
        url: linkTo === 'url' ? urlOrPage : undefined,
        pageName: linkTo === 'page' ? urlOrPage : undefined,
    };
}
function forEachBlock(block, callback) {
    callback(block);
    if (hasBlocksChildren(block)) {
        for (const child of block.blocks) {
            forEachBlock(child, callback);
        }
    }
}
export function forEachBlocks(blocks, callback) {
    for (const child of blocks) {
        forEachBlock(child, callback);
    }
}
// Initialize a Variant from a value and an optional type.
export function makeVariant(value, isExpression, type) {
    if (type) {
        return { type, value, isExpression };
    }
    else if (value === undefined) {
        return { type: 'undefined', value, isExpression };
    }
    else if (typeof value === 'number') {
        return { type: 'number', value, isExpression };
    }
    else if (typeof value === 'string') {
        return { type: 'string', value, isExpression };
    }
    else if (typeof value === 'boolean') {
        return { type: 'boolean', value, isExpression };
    }
    else if (typeof value === 'object') {
        return { type: 'object', value, isExpression };
    }
    else {
        console.assert(false, 'Unsupported value type:', value);
        return { type: 'unknown', value, isExpression };
    }
}
export function propertyTypeToVariantType(type) {
    switch (type) {
        case 'number':
            return 'number';
        case 'string':
            return 'string';
        case 'boolean':
            return 'boolean';
        case 'object':
        case 'array':
        case 'rectangle':
            return 'object';
        case 'date':
            // TODO
            return 'object';
    }
}
export function variantToString(variant) {
    if (!variant) {
        return '';
    }
    switch (variant.type) {
        case 'undefined':
            return '';
        case 'boolean':
            return variant.value ? 'true' : 'false';
        case 'actions':
            console.assert("Can't convert Variant<'actions'> to a string.");
            return '<error>';
        case 'object':
            console.assert("Can't convert object Variant<'object'> to a string.");
            return '<error>';
        case 'number':
        default:
            if (variant.value === undefined || variant.value === null) {
                return '';
            }
            else {
                return variant.value.toString();
            }
    }
}
export function hasBlocksChildren(block) {
    return Array.isArray(block.blocks);
}
export const hasConditionalBlocks = (block) => !!block && block.type === 'ifElse';
export const isContainerBlock = (block) => !!block && (block.type === 'ifElse' || block.type === 'repeat' || block.type === 'trigger');
export const hasBlocks = (block) => !!block &&
    ((block.type === 'ifElse' && block?.blocks.length > 0) ||
        (block.type === 'repeat' && block?.blocks.length > 0) ||
        (block.type === 'trigger' && block?.blocks.length > 0));
export const isEmptyContainer = (block) => !!block &&
    ((block.type === 'ifElse' && block?.blocks.length === 0) ||
        (block.type === 'repeat' && block?.blocks.length === 0) ||
        (block.type === 'trigger' && block?.blocks.length === 0));
export const blocksIn = (block) => {
    if (!block)
        return [];
    if (block.type === 'ifElse' && block?.blocks.length > 0) {
        return block.blocks;
    }
    if (block.type === 'repeat' || (block.type === 'trigger' && block?.blocks.length > 0)) {
        return block.blocks;
    }
    return [];
};
export function duplicateBlock(block) {
    if (hasBlocksChildren(block)) {
        const blocks = block.blocks.map(duplicateBlock);
        return Object.assign({}, block, { uid: uid(), blocks });
    }
    else {
        return Object.assign({}, block, { uid: uid() });
    }
}
