import { defineStyleConfig } from '@chakra-ui/react';
export const dividerTheme = defineStyleConfig({
    defaultProps: { variant: 'solid' },
    baseStyle: { opacity: 0.6, borderColor: 'gray.100', borderWidth: '2px', borderStyle: 'solid' },
    variants: {
        solid: { borderStyle: 'solid' },
        dashed: { borderStyle: 'dashed' },
        thin: { borderWidth: '1px' },
    },
    sizes: {},
});
