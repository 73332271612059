import { forEachObject } from '@playful/utils';
import { registerMigration } from '../registry.js';
const MIXED_STYLES = '__mixed_styles__';
const MIXED_SIZES = -1;
const TEXT_TYPES = [
    'Play Kit/Text',
    'Play Kit/Subheading',
    'Play Kit/BodyText',
    'Play Kit/Heading',
];
function getDefaultFontSizeForType(componentType) {
    switch (componentType) {
        case 'Play Kit/Heading':
            return 56;
        case 'Play Kit/Subheading':
            return 32;
        case 'Play Kit/BodyText':
            return 24;
        default:
            return 16;
    }
}
function getDefaultWeightForType(componentType) {
    switch (componentType) {
        case 'Play Kit/Heading':
            return 'bold';
        default:
            return 'normal';
    }
}
function getDefaultFontWeight(componentType) {
    const defaultWeight = getDefaultWeightForType(componentType);
    if (defaultWeight && defaultWeight !== 'normal') {
        return defaultWeight;
    }
    return undefined;
}
const migration = {
    description: 'migrate text components for moving component styles to inline html, so a global fontSize ends up in a span tag wrapping each text node',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (TEXT_TYPES.includes(obj.componentType) && (obj.text || obj.html)) {
                obj.html = migrateTextComponent(obj);
            }
        });
    },
};
registerMigration(migration, import.meta.url);
function migrateTextComponent(view) {
    const { fontWeight, fontSize, fontFamily, color, textDecoration, fontStyle, componentType } = view;
    const parser = new DOMParser();
    const doc = parser.parseFromString(view.html || `<p>${view.text}</p>`, 'text/html');
    const viewHasColor = color !== MIXED_STYLES;
    const viewHasFontFamily = fontFamily !== MIXED_STYLES;
    const viewHasFontSize = fontSize !== MIXED_SIZES;
    const viewHasFontWeight = (!!fontWeight || getDefaultFontWeight(componentType)) && fontWeight !== MIXED_STYLES;
    const viewHasItalics = !!fontStyle && fontStyle === 'italic';
    const viewHasUnderline = !!textDecoration && textDecoration === 'underline';
    const viewHasLink = view.isLink;
    const viewColor = color || '#000000FF';
    const viewFontFamily = fontFamily || 'Arial';
    const viewFontSize = fontSize || getDefaultFontSizeForType(componentType);
    // add global color, fontFamily, fontSize properties to span tag if span doesn't already
    // have that style property.
    function styleSpan(span) {
        if (viewHasColor && !span.style.color) {
            span.style.color = viewColor;
        }
        if (viewHasFontFamily && !span.style.fontFamily) {
            span.style.fontFamily = viewFontFamily;
        }
        if (viewHasFontSize && !span.style.fontSize) {
            span.style.fontSize = `${viewFontSize}px`;
        }
    }
    // If there's global fontWeight, italics, or underline, wrap text node in correct tag.
    function wrapNodeInGlobals(node) {
        // The order for these is important, it's in backwards order of
        // <a><strong><em><u>text</u></em></strong></a>
        if (!node.textContent)
            return node;
        if (viewHasUnderline) {
            // underline
            node = wrapTextInUnderlines(node);
        }
        // italic
        if (viewHasItalics) {
            node = wrapTextInItalics(node);
        }
        // font weight
        if (viewHasFontWeight) {
            // wrap text nodes in bold
            node = wrapTextInBold(node);
        }
        if (viewHasLink) {
            node = wrapTextInLink(node);
            view.isLink = false;
        }
        return node;
    }
    function wrapTextInLink(node) {
        const { linkAddress, linkPage, linkTo, scrollTo, scrollAnchor, linkTarget, linkTitle, linkRel, linkUnderline, scrollAnimate, } = view;
        const anchor = document.createElement('a');
        anchor.appendChild(node.cloneNode(true));
        anchor.setAttribute('data-linkto', linkTo);
        if (linkTitle) {
            anchor.setAttribute('title', linkTitle);
        }
        if (linkRel) {
            anchor.setAttribute('rel', linkRel);
        }
        if (linkTarget) {
            anchor.setAttribute('target', linkTarget);
        }
        anchor.style.textDecoration = 'none';
        if (linkUnderline) {
            anchor.style.textDecoration = 'underline';
        }
        else {
            anchor.style.textDecoration = 'none';
        }
        if (linkTo === 'page') {
            anchor.setAttribute('href', '#');
            anchor.setAttribute('data-linkpage', linkPage);
            anchor.setAttribute('data-scrollto', scrollTo);
            anchor.setAttribute('data-scrollanchor', scrollAnchor);
            anchor.setAttribute('data-scrollanimate', `${scrollAnimate}`);
        }
        if (linkTo === 'phone') {
            anchor.setAttribute('href', `tel:${linkAddress}`);
            anchor.setAttribute('data-linkaddress', linkAddress);
        }
        if (linkTo === 'email') {
            anchor.setAttribute('href', `mailto:${linkAddress}`);
            anchor.setAttribute('data-linkaddress', linkAddress);
        }
        if (linkTo === 'website') {
            anchor.setAttribute('href', getWebHref(linkAddress));
            anchor.setAttribute('data-linkaddress', linkAddress);
        }
        return anchor;
    }
    function wrapTextInUnderlines(node) {
        const u = document.createElement('u');
        u.appendChild(node.cloneNode(true));
        return u;
    }
    function wrapTextInBold(node) {
        const weight = fontWeight || getDefaultFontWeight(componentType);
        const newWeight = weight === 'bold' ? '700' : weight;
        if (newWeight === 'normal' || newWeight === '400')
            return node;
        const strong = document.createElement('strong');
        strong.appendChild(node.cloneNode(true));
        strong.style.fontWeight = newWeight;
        return strong;
    }
    function wrapTextInItalics(node) {
        const em = document.createElement('em');
        em.appendChild(node.cloneNode(true));
        return em;
    }
    function findSpanToMove(nodeContainer, currentNode, ancestorNodes = []) {
        // Prior to migration, order of tags is like:
        // <p><a><strong><em><u><span>text</span></u></em></strong></a></p>
        // We want to move the span to be a direct child of the <p>, so
        // <p><span><a><strong><em><u>text</u></em></strong></a></span></p>
        // This is because the span contains styles like color which we want to affect
        // the <a> and <u> tags, otherwise the underlines will be different color than
        // the text.
        // Check to see if there's a span tag, doesn't have to be. Either we run into a span or text.
        if (currentNode.nodeName === 'SPAN' || currentNode.nodeType === Node.TEXT_NODE) {
            // Text can contain <br/> tags which splits the text into multiple text nodes and <br/> nodes
            const text = currentNode.nodeName === 'SPAN' ? Array.from(currentNode.childNodes) : [currentNode];
            // Add fontWeight, underline, italic tags
            const wrappedText = text.map((node) => wrapNodeInGlobals(node));
            const span = currentNode.nodeName === 'SPAN' ? currentNode.cloneNode() : document.createElement('span');
            if (ancestorNodes.length) {
                const newStack = ancestorNodes
                    .reverse()
                    .reduce((previous, current, idx) => {
                    if (idx === 0) {
                        wrappedText.map((node) => current.appendChild(node));
                    }
                    else if (current !== previous) {
                        current.appendChild(previous);
                    }
                    return current;
                }, ancestorNodes[0]);
                span.appendChild(newStack);
            }
            else {
                wrappedText.map((node) => span.appendChild(node));
            }
            styleSpan(span);
            nodeContainer.appendChild(span);
        }
        else if (currentNode.nodeName === 'BR') {
            nodeContainer.appendChild(currentNode.cloneNode());
        }
        else {
            if (currentNode.childNodes.length) {
                ancestorNodes.push(currentNode.cloneNode());
                currentNode.childNodes.forEach((node) => {
                    const newAncestorNodes = ancestorNodes.map((node) => node.cloneNode());
                    findSpanToMove(nodeContainer, node, newAncestorNodes);
                });
            }
        }
    }
    function wrapTextInTextStyleSpan(paragraphNode) {
        const newContainer = paragraphNode.cloneNode();
        paragraphNode.childNodes.forEach((topLevelNode) => {
            findSpanToMove(newContainer, topLevelNode.cloneNode(true));
        });
        paragraphNode.replaceWith(newContainer);
    }
    doc.body.childNodes.forEach((node) => {
        wrapTextInTextStyleSpan(node);
    });
    return doc.body.innerHTML;
}
function getWebHref(linkAddress) {
    if (!linkAddress)
        return '#';
    // if it starts with / assume it's a relative url and let it ride
    if (linkAddress.startsWith('/'))
        return linkAddress;
    const constructUrl = (url, fallback) => {
        try {
            const validUrl = new URL(url);
            return validUrl.toString();
        }
        catch (e) {
            return fallback;
        }
    };
    // try to construct a url from the linkAddress, with a fallback
    const firstPass = constructUrl(linkAddress, `http://${linkAddress}`);
    // if the first pass landed on the fallback, revalidate with a second pass
    // and if it's still not valid, just return the original.
    return constructUrl(linkAddress, firstPass);
}
