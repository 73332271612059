import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Set Appear Effect direction to match old trigger slide in name.',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.effects) {
                for (const effect of obj.effects) {
                    if (effect.componentType === 'Play Kit/Appear') {
                        if (!effect.animationPreset?.includes('text-') &&
                            [
                                'Play Kit/Text',
                                'Play Kit/BodyText',
                                'Play Kit/Subheading',
                                'Play Kit/Heading',
                            ].includes(obj.componentType) &&
                            effect.breakdown === undefined) {
                            effect.breakdown = 'none';
                        }
                        if (effect.animationPreset?.includes('text-slide-in')) {
                            effect.animationPreset = 'slide-in';
                        }
                        else if (effect.animationPreset?.includes('slide-in-')) {
                            let newDirection = effect.animationPreset.replace('slide-in-', '');
                            if (newDirection === 'left') {
                                newDirection = 'right';
                            }
                            else if (newDirection === 'right') {
                                newDirection = 'left';
                            }
                            effect.direction = newDirection;
                            effect.animationPreset = 'slide-in';
                        }
                        switch (effect.animationPreset) {
                            case 'text-scale':
                                effect.animationPreset = 'scale-in';
                                break;
                            case 'text-fade':
                                effect.animationPreset = 'fade';
                                break;
                        }
                    }
                }
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
