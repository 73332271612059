var __jsx = React.createElement;
// import '@fontsource/noto-sans/index.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import '../App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { initFirebase } from '@playful/api';
import { Analytics } from '@playful/components';
import { getConfig } from '@playful/config';
import { theme as chakraTheme } from '@playful/design_system';
import { GlobalSubscriptionModal } from '../components/GlobalSubscriptionModal';
import { WelcomeModals } from '../components/WelcomeModals';
import { GlobalLayout } from '../layouts/GlobalLayout';
import { LoadingProvider } from '../Loading';
import { UserProvider } from '../user/UserContext';
import { loadGlobalState } from '../workbench/globalState';
import { GoogleOAuthProvider } from '@react-oauth/google';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useEffect } from 'react';
import { RouteContext } from '../hooks/RouteContext';
import usePrefersDarkMode from '../hooks/usePrefersDarkMode.js';
var ContextualRoutes = dynamic(function () {
  return import('../ContextualRoutes');
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('../ContextualRoutes')];
    }
  }
});
var bodyBackgroundColor = '#ffffff';
initFirebase();
loadGlobalState();
function HatchApp(_ref) {
  var Component = _ref.Component,
    pageProps = _ref.pageProps;
  var config = getConfig();
  // Apply the theme color to the document body so the iPhone status area
  // takes on that color when Hatch is launched from the home screen (PWA).
  useEffect(function () {
    if (document) document.body.style.backgroundColor = bodyBackgroundColor;
  }, []);
  var googleOAuthClientId = config.googleOAuthClientId;
  var titleText = 'Hatch';

  // TODO: get this dynamically
  var baseUrl = 'https://hatch.one';

  // Determine if the user prefers dark mode for swapping the favicon
  var _usePrefersDarkMode = usePrefersDarkMode(),
    prefersDarkMode = _usePrefersDarkMode.prefersDarkMode;
  var faviconSvg = prefersDarkMode ? 'favicon-dark.svg' : 'favicon-light.svg';
  return __jsx(React.Fragment, null, __jsx(Head, null, __jsx("meta", {
    charSet: "utf-8"
  }), __jsx("title", {
    key: "title"
  }, titleText), __jsx("meta", {
    name: "description",
    key: "description",
    content: "Make and publish a unique no-code website, link-in-bio page, evite or portfolio with interactive and animated features unlike anywhere else online."
  }), __jsx("meta", {
    key: "apple-mobile-web-app-capable",
    name: "apple-mobile-web-app-capable",
    content: "yes"
  }), __jsx("meta", {
    key: "apple-mobile-web-app-title",
    name: "apple-mobile-web-app-title",
    content: titleText
  }), __jsx("link", {
    key: "icon-any",
    rel: "icon",
    href: "/favicon.ico",
    sizes: "any"
  }), __jsx("link", {
    key: "icon-svg",
    rel: "icon",
    href: "/".concat(faviconSvg),
    type: "image/svg+xml"
  }), __jsx("link", {
    key: "apple-touch-icon",
    rel: "apple-touch-icon",
    href: "/apple-touch-icon.png"
  }), __jsx("meta", {
    key: "apple-mobile-web-app-status-bar-style",
    name: "apple-mobile-web-app-status-bar-style",
    content: "black-translucent"
  }), __jsx("meta", {
    key: "theme-color",
    name: "theme-color",
    content: "#000000"
  }), __jsx("meta", {
    key: "og:title",
    property: "og:title",
    content: titleText
  }), __jsx("meta", {
    key: "og:type",
    property: "og:type",
    content: "website"
  }), __jsx("meta", {
    key: "og:image",
    property: "og:image",
    content: "".concat(baseUrl, "/preview.png")
  }), __jsx("meta", {
    key: "og:image:width",
    property: "og:image:width",
    content: '1200'
  }), __jsx("meta", {
    key: "og:image:height",
    property: "og:image:height",
    content: '630'
  }), __jsx("meta", {
    key: "twitter:card",
    name: "twitter:card",
    content: "summary_large_image"
  }), __jsx("meta", {
    key: "twitter:title",
    name: "twitter:title",
    content: titleText
  }), __jsx("meta", {
    key: "twitter:site",
    name: "twitter:site",
    content: "@madewithhatch"
  }), __jsx("meta", {
    key: "twitter:image",
    name: "twitter:image",
    content: "".concat(baseUrl, "/preview.png")
  }), __jsx("meta", {
    key: "viewport",
    name: "viewport",
    content: "width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0"
  })), __jsx(React.Fragment, null, __jsx(Analytics, {
    enableLogRocket: true,
    enableHubSpot: true,
    enableGoogleAnalytics: true
  })), __jsx(ChakraProvider, {
    theme: chakraTheme
  }, __jsx(RouteContext, null, __jsx(LoadingProvider, null, __jsx(GlobalLayout, null, __jsx(GoogleOAuthProvider, {
    clientId: googleOAuthClientId
  }, __jsx(UserProvider, null, __jsx(ContextualRoutes, null), __jsx(Component, pageProps), __jsx(GlobalSubscriptionModal, null), __jsx(WelcomeModals, null))))))));
}
export default HatchApp;