import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import { Box } from '@playful/design_system';
import { Loading } from '../../Loading';
import React from 'react';
export function Scrim(props) {
  return __jsx(Box, _extends({
    zIndex: "overlay",
    bg: "rgba(255,255,255,.85)",
    position: "absolute",
    h: "full",
    w: "full",
    left: 0,
    top: 0,
    display: "flex",
    flexDir: "column",
    justifyContent: "center",
    alignItems: "center"
  }, props), __jsx(Loading, null));
}