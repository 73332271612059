import { defineStyleConfig } from '@chakra-ui/react';
export const sliderTheme = defineStyleConfig({
    defaultProps: { size: 'md', colorScheme: 'blue' },
    baseStyle: ({ colorScheme, theme }) => {
        const thumbShadowFocus = `${theme.shadows.base}, 0 0 0 0.5rem ${theme.colors['blackAlpha'][300]}`;
        return {
            container: {
                display: 'inline-block',
                position: 'relative',
                cursor: 'pointer',
                _disabled: { opacity: 0.6, cursor: 'default', pointerEvents: 'none' },
            },
            track: { overflow: 'visible', background: 'none' },
            thumb: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                marginTop: 0,
                transform: 'none',
                outline: 0,
                p: 1,
                zIndex: 1,
                borderRadius: 'full',
                bg: 'white',
                transitionProperty: 'transform',
                transitionDuration: 'normal',
                boxShadow: 'base',
                _focus: { boxShadow: thumbShadowFocus },
                _disabled: { bg: 'gray.300' },
                _active: { transform: 'scale(1.15)' },
            },
            filledTrack: {
                width: 'inherit',
                height: 'inherit',
                bg: `${colorScheme}.400`,
                '::before': {
                    position: 'absolute',
                    content: '""',
                    top: 0,
                    left: 0,
                    bg: `${colorScheme}.400`,
                    transform: 'translateX(-50%)',
                    borderRadius: 'full',
                },
                '::after': {
                    position: 'absolute',
                    content: '""',
                    top: 0,
                    right: 0,
                    bg: `${colorScheme}.400`,
                    transform: 'translateX(50%)',
                    borderRadius: 'full',
                },
            },
        };
    },
    variants: {},
    sizes: {
        lg: {
            container: { w: 'calc(100% - 2rem)', mx: 4 },
            thumb: { w: 6, h: 6, mt: -3 },
            track: { h: 8, '::before': { left: -4, right: -4 } },
            filledTrack: {
                '::before': { w: 8, h: 8 },
                '::after': { w: 8, h: 8 },
            },
        },
        md: {
            container: { w: 'calc(100% - 1.5rem)', mx: 3 },
            thumb: { w: 4, h: 4, mt: -2 },
            track: { h: 6, '::before': { left: -3, right: -3 } },
            filledTrack: {
                '::before': { w: 6, h: 6 },
                '::after': { w: 6, h: 6 },
            },
        },
        sm: {
            container: { w: 'calc(100% - 1.25rem)', mx: 2.5 },
            thumb: { w: 3, h: 3, mt: -1.5 },
            track: { h: 5, '::before': { left: -2.5, right: -2.5 } },
            filledTrack: {
                '::before': { w: 5, h: 5 },
                '::after': { w: 5, h: 5 },
            },
        },
    },
});
