// Returns an immutable version of the object passed in
export function makeImmutable(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(makeImmutable);
    }
    const immutableObj = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            immutableObj[key] = makeImmutable(obj[key]);
        }
    }
    return Object.freeze(immutableObj);
}
