import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Migrates default sizing for specific components (#3899).',
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.componentType === 'Play Kit/Button') {
                obj.fontSize = obj.fontSize ?? 16;
                obj.width = obj.width ?? 100;
                obj.height = obj.height ?? 24;
            }
            if (obj.componentType === 'Play Kit/BodyText')
                obj.fontSize = obj.fontSize ?? 16;
            if (obj.componentType === 'Play Kit/Subheading')
                obj.fontSize = obj.fontSize ?? 24;
            if (obj.componentType === 'Play Kit/Heading')
                obj.fontSize = obj.fontSize ?? 48;
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
