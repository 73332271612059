import { jsx as _jsx } from "react/jsx-runtime";
import { Popover as ChPopover, PopoverArrow as ChPopoverArrow, PopoverBody as ChPopoverBody, PopoverCloseButton as ChPopoverCloseButton, PopoverContent as ChPopoverContent, PopoverFooter as ChPopoverFooter, PopoverHeader as ChPopoverHeader, } from '@chakra-ui/popover';
/**
 * Popover component that renders a popover on hover. See [Popover](https://v1.chakra-ui.com/docs/components/overlay/popover) for the complete documentation.
 */
export function Popover(props) {
    return _jsx(ChPopover, { ...props });
}
export function PopoverArrow(props) {
    return _jsx(ChPopoverArrow, { ...props });
}
export function PopoverBody(props) {
    return _jsx(ChPopoverBody, { ...props });
}
export function PopoverCloseButton(props) {
    return _jsx(ChPopoverCloseButton, { ...props });
}
export function PopoverContent(props) {
    return _jsx(ChPopoverContent, { ...props });
}
export function PopoverFooter(props) {
    return _jsx(ChPopoverFooter, { ...props });
}
export function PopoverHeader(props) {
    return _jsx(ChPopoverHeader, { ...props });
}
export { PopoverTrigger, PopoverAnchor } from '@chakra-ui/popover';
