var __jsx = React.createElement;
import { Box, Button, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@playful/design_system';
import React from 'react';
import { useQueryStringActions } from '@playful/utils';
import useDisclosure from '../../utils/useDisclosure';
import WelcomeImage from './welcome.svg';
export var WelcomeSubscriptionModal = function WelcomeSubscriptionModal() {
  var _useDisclosure = useDisclosure(),
    isOpen = _useDisclosure.isOpen,
    closeDialog = _useDisclosure.onClose,
    onOpen = _useDisclosure.onOpen;
  var _useQueryStringAction = useQueryStringActions({
      startAction: onOpen,
      endAction: closeDialog,
      actionName: 'subscribe-success'
    }),
    onClose = _useQueryStringAction.handleEndAction;
  return __jsx(Modal, {
    size: "xs",
    isOpen: isOpen,
    onClose: onClose,
    isCentered: true
  }, __jsx(ModalOverlay, null), __jsx(ModalContent, {
    style: {
      overflow: 'hidden'
    }
  }, __jsx(Box, {
    position: "relative",
    h: "220px"
  }, __jsx(WelcomeImage, null)), __jsx(ModalHeader, {
    textAlign: "center"
  }, __jsx(Heading, {
    fontSize: "2xl",
    fontWeight: "bold"
  }, "Welcome to Hatch Pro!")), __jsx(ModalBody, null, __jsx(Text, {
    textAlign: "center",
    fontSize: "sm"
  }, "More projects, more pages, and more customization are yours! We can\u2019t wait to see what you create.")), __jsx(ModalFooter, null, __jsx(Button, {
    colorScheme: "primary",
    variant: "rebrand-pill",
    w: "100%",
    onClick: onClose
  }, "let\u2019s go!"))));
};