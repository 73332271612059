/**
 * TypeGuard to determine if an option is a flat array or an array of { title, value } objects
 */
export function isObjectOption(option) {
    return (option.title !== undefined || option.items !== undefined);
}
/**
 * TypeGuard to determine if the EditorProps are a flat type, i.e. "String" or an object:
 * {
 *     type: 'String',
 *     ...etc
 * }
 */
export function isPropertyEditorProps(editorProps) {
    return editorProps.type !== undefined;
}
/**
 * TypeGuard to determine if a given string (such as the result of `typeof value`) is a valid `PropertyType`
 */
export const isSupportedPropertyType = (propertyType) => {
    //TODO: Expand to include all `PropertyType`s
    return ['boolean', 'string', 'number', 'function', 'array'].includes(propertyType);
};
