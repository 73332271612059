/**
 * Currently this will not react to the 'dark-mode' switch
 *
 */
const globalStyles = {
    body: {
        fontFamily: 'body',
        color: 'gray.800',
        bg: 'white',
        transitionProperty: 'background-color',
        transitionDuration: 'normal',
        lineHeight: 'base',
    },
    '*::placeholder': { color: 'gray.400' },
    '*, *::before, &::after': { borderColor: 'gray.200', wordWrap: 'break-word' },
};
export default globalStyles;
