import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getConfig } from '@playful/config';
import { initializeLogRocket } from '@playful/telemetry';
import Script from 'next/script';
import { useEffect } from 'react';
function shouldEnableAnalytics() {
    if (typeof window === 'undefined')
        return false;
    // NOTE: we can't tell if we're in playwright/webdriver when doing SSR, so we're defaulting to false
    if (navigator.webdriver)
        return false;
    const params = new URLSearchParams(window.location.search);
    if (params.get('analytics') === 'false')
        return false;
    return true;
}
export function Analytics(props) {
    const { googleAnalyticsId, heapAnalyticsId, rudderStackWriteKey, rudderStackCDNURL, rudderStackAPIURL, rudderStackDataPlaneURL, hubSpotId, environment, gitBranch, } = getConfig();
    const { enableLogRocket, enableHubSpot, enableGoogleAnalytics } = props;
    const enableAnalytics = shouldEnableAnalytics();
    // Load LogRocket
    useEffect(() => {
        if (enableAnalytics && enableLogRocket) {
            initializeLogRocket();
        }
    }, [enableAnalytics, enableLogRocket]);
    if (!enableAnalytics) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [enableGoogleAnalytics && googleAnalyticsId && (_jsxs(_Fragment, { children: [_jsx(Script, { async: true, src: `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}` }), _jsx(Script, { id: 'google-analytics', dangerouslySetInnerHTML: {
                            __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${googleAnalyticsId}');
                  `,
                        } })] })), heapAnalyticsId && (_jsx(_Fragment, { children: _jsx(Script, { id: 'heapAnalyticsId', dangerouslySetInnerHTML: {
                        __html: `
                  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};
                  var r=document.createElement("script");r.type="text/javascript",r.async=!0,
                  r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                  heap.load("${heapAnalyticsId}");
                  heap.clearEventProperties();
                  heap.addEventProperties({"Play Env":"${environment}","Branch":"${gitBranch}"});
                `,
                    } }) })), enableHubSpot && hubSpotId && (_jsx(Script, { type: 'text/javascript', id: 'hs-script-loader', async: true, defer: true, src: `//js.hs-scripts.com/${hubSpotId}.js` })), rudderStackWriteKey && (_jsx(_Fragment, { children: _jsx(Script, { id: 'rudderStack', dangerouslySetInnerHTML: {
                        __html: `
              !function(){"use strict";window.RudderSnippetVersion="3.0.3";var sdkBaseUrl="${rudderStackCDNURL}/v3"
              ;var sdkName="rsa.min.js";var asyncScript=true;window.rudderAnalyticsBuildType="legacy",window.rudderanalytics=[]
              ;var e=["setDefaultInstanceKey","load","ready","page","track","identify","alias","group","reset","setAnonymousId","startSession","endSession","consent"]
              ;for(var n=0;n<e.length;n++){var t=e[n];window.rudderanalytics[t]=function(e){return function(){
              window.rudderanalytics.push([e].concat(Array.prototype.slice.call(arguments)))}}(t)}try{
              new Function('return import("")'),window.rudderAnalyticsBuildType="modern"}catch(a){}
              if(window.rudderAnalyticsMount=function(){
              "undefined"==typeof globalThis&&(Object.defineProperty(Object.prototype,"__globalThis_magic__",{get:function get(){
              return this},configurable:true}),__globalThis_magic__.globalThis=__globalThis_magic__,
              delete Object.prototype.__globalThis_magic__);var e=document.createElement("script")
              ;e.src="".concat(sdkBaseUrl,"/").concat(window.rudderAnalyticsBuildType,"/").concat(sdkName),e.async=asyncScript,
              document.head?document.head.appendChild(e):document.body.appendChild(e)
              },"undefined"==typeof Promise||"undefined"==typeof globalThis){var d=document.createElement("script")
              ;d.src="https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount",
              d.async=asyncScript,document.head?document.head.appendChild(d):document.body.appendChild(d)}else{
              window.rudderAnalyticsMount()}
              window.rudderanalytics.load("${rudderStackWriteKey}","${rudderStackDataPlaneURL}",{
                                      configUrl: "${rudderStackAPIURL}",
                                      useBeacon: true,
                                      beaconQueueOptions: {
                                        flushQueueInterval: 10000, // Flush after 10sec
                                      }
              })
              window.rudderanalytics.page()
              }();
              `,
                    } }) }))] }));
}
