import { registerMigration } from '../registry.js';
import { forEachObject } from '@playful/utils';
const migration = {
    description: 'Migrate the style object to css properties',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (!!obj.componentType && !!obj.style) {
                const hasChildren = !!obj.children && obj.children.length > 0;
                const css = cssPropertiesToString(obj.style, hasChildren);
                if (!!css) {
                    const preamble = !!obj.css ? `${obj.css}\n` : '';
                    obj.css = preamble + css;
                }
                // `style` becomes a runtime only prop which
                // gets created during component's `init` phase
                delete obj.style;
            }
        });
    },
};
registerMigration(migration, import.meta.url);
//
function cssPropertiesToString(styles, hasChildren) {
    const kebabCase = (str) => str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
    // These are the style properties that should be applied to the outer element
    // other properties are applied to the first child
    // unless the component has children (and therefore is a container)
    const outerStyleProps = [
        'zIndex',
        'cursor',
        'transform',
        'mixBlendMode',
        'pointerEvents',
        'backfaceVisibility',
        'transformOrigin',
        'filter',
    ];
    // these properties need the !important flag because moving them to css
    // changes the appearance of some components because of the order of the styles
    const importantStyles = [
        'border',
        'borderRadius',
        'userSelect',
        'background',
        'padding',
        'outline',
    ];
    // these are properties that have a style prop and a component prop that does the same thing
    // (or similar things).
    // Before this migration we assumed a value in the `style` would take precedence over the component prop
    // so now we use !important to achieve the same thing
    // This can create a situation where a component inspector stops doing anything because there is
    // an !important css overriding it.. however, this is already the case with style props secretly
    // overriding component props.
    // I explored setting the component prop to undefined and using the css value without !important
    // However, this has risk because not all of the style/prop pairs are the same.
    // For example: The background style prop vs the backgroundColor component prop
    // These *can* be used together, but they can also overwrite each other.
    // So I stuck with using !important to keep the same behavior as before and have less risk
    // of breaking older projects.
    const thisStyles = [];
    const firstChildStyles = [];
    Object.entries(styles).forEach(([key, value]) => {
        if (value === null || value === '' || key === 'componentType' || key === '_meta') {
            // Skip null, undefined values and componentType
            // gotta be careful here because a valid value is 0
            // but an empty string is not
            return;
        }
        let cssKey = kebabCase(key);
        if (cssKey.startsWith('webkit')) {
            cssKey = `-${cssKey}`;
        }
        const important = importantStyles.includes(key) ? ' !important' : '';
        const cssLine = `  ${cssKey}: ${value}${important};`;
        if (outerStyleProps.includes(key) || hasChildren) {
            thisStyles.push(cssLine);
        }
        else {
            firstChildStyles.push(cssLine);
        }
    });
    let result = '';
    if (thisStyles.length > 0) {
        result += `.this {\n${thisStyles.join('\n')}\n}\n`;
    }
    if (firstChildStyles.length > 0) {
        result += `.this > *:first-child {\n${firstChildStyles.join('\n')}\n}\n`;
    }
    return result;
}
