var __jsx = React.createElement;
import { getPrices } from '@playful/api';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React, { useCallback, useEffect, useRef } from 'react';
import useSWR from 'swr';
import { useUserProjects } from '../hooks/useProjects';
import { useRouter } from '../hooks/useRouter';
import { useUserContext } from '../user/UserContext';
import useDisclosure from '../utils/useDisclosure';
import { SubscriptionFlow } from './SubscriptionFlow';
export var GlobalSubscriptionModal = function GlobalSubscriptionModal() {
  var _modalPropsRef$curren5, _modalPropsRef$curren6, _modalPropsRef$curren7;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user,
    hasFlag = _useUserContext.hasFlag,
    userFlags = _useUserContext.userFlags;
  var _useUserProjects = useUserProjects(user.id),
    projectInfos = _useUserProjects.projectInfos;
  var _useDisclosure = useDisclosure(),
    isOpen = _useDisclosure.isOpen,
    onClose = _useDisclosure.onClose,
    onOpen = _useDisclosure.onOpen;
  var _useRouter = useRouter(),
    asPath = _useRouter.asPath;
  var modalPropsRef = useRef();
  var handleOpenModal = useCallback(function (_ref) {
    var modalProps = _ref.modalProps;
    modalPropsRef.current = modalProps;
    onOpen();
  }, [onOpen]);
  useEffect(function () {
    var _subscriptionPaywalls = subscriptionPaywalls.onOpenModal(handleOpenModal),
      dispose = _subscriptionPaywalls.dispose;
    return function () {
      dispose();
    };
  }, [handleOpenModal]);
  useEffect(function () {
    subscriptionPaywalls.setup({
      user: user,
      projects: projectInfos,
      userFlags: userFlags
    });
    return function () {
      subscriptionPaywalls.teardown();
    };
  }, [user, projectInfos, hasFlag, userFlags]);
  function handleOnClose() {
    var _modalPropsRef$curren, _modalPropsRef$curren2;
    onClose();
    (_modalPropsRef$curren = modalPropsRef.current) === null || _modalPropsRef$curren === void 0 || (_modalPropsRef$curren2 = _modalPropsRef$curren.onClose) === null || _modalPropsRef$curren2 === void 0 || _modalPropsRef$curren2.call(_modalPropsRef$curren);
    modalPropsRef.current = undefined;
  }
  function handleClickFreePlan() {
    var _modalPropsRef$curren3, _modalPropsRef$curren4;
    onClose();
    (_modalPropsRef$curren3 = modalPropsRef.current) === null || _modalPropsRef$curren3 === void 0 || (_modalPropsRef$curren4 = _modalPropsRef$curren3.handleClickFreePlan) === null || _modalPropsRef$curren4 === void 0 || _modalPropsRef$curren4.call(_modalPropsRef$curren3);
    modalPropsRef.current = undefined;
  }
  var _useSWR = useSWR('prices', getPrices, {
      revalidateOnFocus: false
    }),
    priceInfo = _useSWR.data;
  return __jsx(SubscriptionFlow, {
    returnPath: ((_modalPropsRef$curren5 = modalPropsRef.current) === null || _modalPropsRef$curren5 === void 0 ? void 0 : _modalPropsRef$curren5.returnPath) || asPath,
    returnTo: (_modalPropsRef$curren6 = modalPropsRef.current) === null || _modalPropsRef$curren6 === void 0 ? void 0 : _modalPropsRef$curren6.returnTo,
    isOpen: isOpen,
    onFreePlanClicked: handleClickFreePlan,
    onClose: handleOnClose,
    priceInfo: priceInfo,
    successModal: (_modalPropsRef$curren7 = modalPropsRef.current) === null || _modalPropsRef$curren7 === void 0 ? void 0 : _modalPropsRef$curren7.successModal
  });
};