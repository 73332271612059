// Symbolic Object ids.
export const selfObjectId = -1;
export const parentObjectId = -2;
export const pageObjectId = -3;
// Use Symbols to hide private Component properties we don't want to confuse anyone with.
export const MASTER = Symbol('MASTER');
export const INITIAL_STATE = Symbol('INITIAL_STATE');
export const PRIMARY = Symbol('PRIMARY');
export const IS_PRIMARY_BREAKPOINT = Symbol('IS_PRIMARY_BREAKPOINT');
export const VARIANT_ID = Symbol('IS_BREAKPOINT');
export const ACTIVE_BREAKPOINT = Symbol('ACTIVE_BREAKPOINT');
// don't symbolize this so that we can listen for changes to it
export const ACTIVE_BREAKPOINT_ID = 'activeBreakpointId';
export const __workbench = Symbol('__workbench');
export const DESCRIPTION = Symbol('DESCRIPTION');
