import { isEffect } from './components/index.js';
import { META } from './reactorObject.js';
// Convert e.g. "backgroundColor" to "Background Color".
export function humanizePropertyName(s) {
    // There is no hope for underscore-prefixed properties.
    if (s[0] === '_') {
        return s;
    }
    // Insert a space before all caps.
    const spaced = s.replace(/([A-Z])/g, ' $1');
    // Uppercase the first character.
    return spaced.replace(/^./, (s) => s.toUpperCase());
}
export function qualifyProperty(qualifier, property) {
    if (!qualifier)
        return property;
    return `${qualifier}.${property}`;
}
export function isQualifiedProperty(property) {
    return property.indexOf('.') !== -1;
}
// Play Kit/Spin.speed -> { qualifier: 'Play Kit/Spin', property: 'speed' }
export function parseQualifiedProperty(property) {
    if (property.indexOf('.') === -1) {
        return { qualifier: undefined, property };
    }
    const [qualifier, unqualifiedProperty] = property.split('.');
    return { qualifier, property: unqualifiedProperty };
}
export function resolveQualifiedProperty(target, property) {
    if (isQualifiedProperty(property)) {
        const { qualifier, property: unqualifiedProperty } = parseQualifiedProperty(property);
        target = target.getEffect(qualifier);
        property = unqualifiedProperty;
    }
    return { target, property };
}
export function getQualifiedPropertyValue(component, qualifiedProperty) {
    const { target, property } = resolveQualifiedProperty(component, qualifiedProperty);
    if (!target) {
        return undefined;
    }
    return target[property];
}
export function setQualifiedPropertyValue(component, qualifiedProperty, value) {
    const { target, property } = resolveQualifiedProperty(component, qualifiedProperty);
    if (!target) {
        return;
    }
    target[property] = value;
}
export function resolveTargetAndProperty(component, property) {
    let target = component;
    if (property.includes('.')) {
        if (property.indexOf('/') === -1) {
            property = 'Play Kit/' + property;
        }
        const resolved = resolveQualifiedProperty(component, property);
        property = resolved.property;
        target = resolved.target;
    }
    return { target, property };
}
export function resolveFriendlyPropertyName(component, qualifiedProperty) {
    const { target, property } = resolveTargetAndProperty(component, qualifiedProperty);
    if (!target) {
        const { qualifier } = parseQualifiedProperty(qualifiedProperty);
        // "unfriendly" but best we can do unless we look up the EffectDescriptions.
        return `${property} (${qualifier?.replace('Play Kit/', '')})`;
    }
    return getFriendlyPropertyName(target, property);
}
export function getPropertyDescription(target, property) {
    const propertyDescriptions = target[META].properties || {};
    return propertyDescriptions[property];
}
export function getFriendlyPropertyName(target, property) {
    const description = getPropertyDescription(target, property);
    if (!description) {
        return property;
    }
    let friendlyName = description.title || property;
    if (isEffect(target)) {
        friendlyName = `${friendlyName} (${getFriendlyEffectName(target)})`;
    }
    return friendlyName;
}
function getFriendlyEffectName(effect) {
    return effect[META].title ?? effect.componentType.replace('Play Kit/', '');
}
export function isColorProperty(component, qualifiedProperty) {
    const { target, property } = resolveTargetAndProperty(component, qualifiedProperty);
    if (!target) {
        return false;
    }
    const description = getPropertyDescription(target, property);
    const type = typeof description?.editor === 'string' ? description.editor : description?.editor?.type ?? '';
    return ['Color', 'TextColor'].includes(type);
}
export function getPropertyPrimitiveType(component, property) {
    const description = getPropertyDescription(component, property);
    return description?.type;
}
