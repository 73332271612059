// Extra small screen is approximately the point at which the edit/preview button text
// interferes with the workbench toolbar layout
export const extraSmallScreenThreshold = 360;
export const smallScreenThreshold = 640;
export const mediumScreenThreshold = 992;
export default {
    xs: 320, // 320-360px
    sm: extraSmallScreenThreshold, // 360-639x
    md: smallScreenThreshold, // 640-991px
    lg: mediumScreenThreshold, // 992-1279px
    xl: 1280, // 1280-1535px
    // chakra specifically requests we define the 2xl breakpoint: https://chakra-ui.com/docs/styled-system/responsive-styles
    '2xl': 1536, // 1536-1920px,
    '3xl': 1920, // 1920px+
};
