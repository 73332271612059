import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from '@chakra-ui/react';
import { useState } from 'react';
import { IconButton } from '../Button';
import { ViewIcon, ViewOffIcon } from '../Icon';
import { Input, InputGroup, InputRightElement } from './Input';
export const PasswordInput = forwardRef((props, ref) => {
    const [showPw, setShowPw] = useState(false);
    return (_jsxs(InputGroup, { children: [_jsx(Input, { ref: ref, type: showPw ? 'text' : 'password', ...props }), !props.isDisabled && (_jsx(InputRightElement, { children: _jsx(IconButton, { variant: 'ghost', isRound: true, "aria-label": 'Show Password', size: 'sm', onClick: () => setShowPw(!showPw), _hover: { bg: 'transparent' }, icon: showPw ? _jsx(ViewIcon, {}) : _jsx(ViewOffIcon, {}) }) }))] }));
});
