import { defineStyleConfig } from '@chakra-ui/react';
const baseStyle = ({ scrollBehavior, isCentered }) => {
    return {
        overlay: {
            backdropFilter: 'blur(0.25rem) invert(10%)',
        },
        dialog: {
            top: '0',
            bg: 'white',
            borderRadius: 'lg',
            my: isCentered ? 'auto' : '3.75rem',
            overflow: scrollBehavior === 'inside' ? 'hidden' : undefined,
        },
        header: { px: 6, pt: 4, pb: 2, fontWeight: 'bold', color: 'gray.700' },
        dialogContainer: {
            alignItems: 'flex-start',
        },
        closeButton: {
            bg: 'transparent',
            w: 10,
            h: 10,
            borderRadius: 'md',
            _hover: { bg: 'gray.50' },
            _focus: {
                bg: 'gray.50',
                boxShadow: 'none',
            },
            _focusVisible: {
                outline: '0.125rem solid',
                outlineColor: 'gray.800',
                outlineOffset: '0.125rem',
            },
            _active: { bg: 'gray.100' },
        },
        body: {
            px: 6,
            pb: 2,
            pt: 2,
        },
        footer: {
            pt: 2,
            pb: 6,
        },
    };
};
export const modalTheme = defineStyleConfig({
    defaultProps: { size: 'md' },
    baseStyle,
    variants: {},
    sizes: {
        xs: { dialog: { maxW: 'xs' } },
        sm: { dialog: { maxW: 'sm' } },
        md: { dialog: { maxW: 'md' } },
        lg: { dialog: { maxW: 'lg' } },
        xl: { dialog: { maxW: 'xl' } },
        '2xl': {
            dialog: { maxW: '856px' },
            body: { px: '64px', pt: '20px' },
            header: {
                pt: '40px',
                px: '64px',
                pb: '0',
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: 'center',
            },
            footer: { pb: '64px', pt: 0 },
        },
        '3xl': { dialog: { maxW: '2xl' } },
        'almost-full': {
            dialog: { maxW: '1216px' },
            closeButton: {
                bg: 'gray.100',
            },
        },
        'almost-full-mobile': {
            dialog: { maxH: 'calc(100% - var(--play-space-6))', maxW: '1216px', marginBottom: 0 },
            closeButton: {
                bg: 'gray.100',
            },
        },
        full: {
            dialog: {
                borderRadius: 0,
                mx: 0,
                maxW: '100vw',
                minH: '100vh',
                '@supports(min-height: -webkit-fill-available)': { minH: '-webkit-fill-available' },
                my: 0,
            },
        },
        'full-no-padding': {
            dialog: {
                borderRadius: 0,
                mx: 0,
                maxW: '100vw',
                minH: '100vh',
                '@supports(min-height: -webkit-fill-available)': { minH: '-webkit-fill-available' },
                my: 0,
            },
            body: {
                padding: 0,
            },
        },
        fullish: {
            dialogContainer: {
                top: '32px',
                bottom: '32px',
                height: 'auto',
                right: '32px',
                left: '32px',
                width: 'auto',
            },
            dialog: {
                maxW: '1280px',
                h: '100%',
                w: '100%',
                overflow: 'auto',
            },
        },
    },
});
