import { META } from './reactorObject.js';
// TODO: This only works if actions are sorted by inheritance with youngest first.
export function getPrimaryAction(component) {
    const actions = component[META]?.actions;
    if (!actions) {
        return undefined;
    }
    const actionNames = Object.keys(actions);
    const actionName = actionNames.find((actionName) => actions[actionName].primary);
    if (actionName) {
        return actionName;
    }
    return actionNames[0];
}
// TODO: This only works if events are sorted by inheritance with youngest first.
export function getPrimaryEvent(component) {
    // TODO: check effect events
    const events = component[META]?.events;
    if (!events) {
        return undefined;
    }
    const eventNames = Object.keys(events);
    const eventName = eventNames.find((eventName) => events[eventName].primary);
    if (eventName) {
        return eventName;
    }
    return eventNames[0];
}
// TODO: This only works if properties are sorted by inheritance with youngest first.
// Return the Component's described primary property or the first property if none is described.
// Only returns undefined when there are no properties.
export function getPrimaryProperty(component) {
    const properties = component[META]?.properties;
    if (!properties) {
        return undefined;
    }
    const propertyNames = Object.keys(properties);
    const propertyName = propertyNames.find((name) => properties[name].primary);
    if (propertyName) {
        return propertyName;
    }
    // Don't return migrated methods.
    // TODO: Remove when migrated methods are migrated to nothing.
    for (let i = 0; i < propertyNames.length; i++) {
        if (!propertyNames[i].startsWith('🦕')) {
            return propertyNames[i];
        }
    }
    return propertyNames[0];
}
