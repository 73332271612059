import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Move the pointer events out of styles and into the component.',
    async migrate(state) {
        // todo migrate the state!
        forEachObject(state, (obj) => {
            if (obj.componentType && obj.style && obj.style.pointerEvents) {
                obj.pointerEvents = obj.style.pointerEvents !== 'none';
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
