import { ID, shallowEqual, isObjectNotArray, forEachObject } from '@playful/utils';
import { isSafeToRunProject, migrateProjectState } from '@playful/migrator';
import { PRIMARY, INITIAL_STATE, IS_PRIMARY_BREAKPOINT, ACTIVE_BREAKPOINT_ID, ACTIVE_BREAKPOINT, VARIANT_ID, } from './constants.js';
import { PRIMARY_BREAKPOINT_ID, isComponent, initComponent, determineBreakpointKey, } from './components/index.js';
import { perfTracker } from './performance.js';
import { deserialize, isReactor, parseComponentType, } from './reactor.js';
import { createReactorFactory } from './reactorFactory.js';
import { dirName, pathJoin } from './util.js';
export async function loadProject(args) {
    // TODO: viewportWidth default?
    const { state, info, mainProject, resourceRoot, basePath, playkit, viewportWidth = 1200, breakpointId, } = args;
    // Calculate the max in-use reactor id
    let maxReactorId = 0;
    forEachObject(state, (obj) => {
        if (obj[ID] > maxReactorId) {
            maxReactorId = obj[ID];
        }
    });
    // Extract the pages and Components from the state
    const { pages, Components, ...projectState } = state;
    const reactorFactory = createReactorFactory(maxReactorId + 1);
    const project = reactorFactory.createReactor({
        Imports: [],
        ...projectState,
        // These are set below
        pages: undefined,
        Components: undefined,
        style: undefined,
        effects: undefined, // Ensure projects don't get effects
        customFonts: undefined,
    });
    // Setup project-level functions
    project.createReactor = reactorFactory.createReactor.bind(reactorFactory);
    project.createVariant = (variantId, componentId, properties) => {
        const c = project.createReactor({ ...properties, componentId });
        // TODO: why cant i set a symbol property in the initial properties?
        c[VARIANT_ID] = variantId;
        return c;
    };
    project.getReactorById = reactorFactory.getReactorById.bind(reactorFactory);
    project.hasReactor = reactorFactory.hasReactor.bind(reactorFactory);
    project.forEachReactor = reactorFactory.forEachReactor.bind(reactorFactory);
    project.createComponent = (state, breakpointId, breakpoints) => {
        if (project.rootView) {
            // if we have a rootView and the breakpoint, and current breakpointId are
            // not provided, use the rootView's breakpointId/breakpoints
            if (!breakpoints) {
                breakpoints = project.rootView[PRIMARY]?.breakpoints ?? {};
            }
            if (!breakpointId) {
                breakpointId = project.rootView[ACTIVE_BREAKPOINT_ID];
            }
        }
        if (breakpointId === PRIMARY_BREAKPOINT_ID) {
            breakpointId = undefined;
        }
        return createComponent(project, state, breakpointId, breakpoints);
    };
    project.getComponentById = (id) => reactorFactory.getReactorById(id);
    // Load the project state into the stored state
    const { [ID]: _projectReactorId, ...projectStateClone } = projectState;
    project[PRIMARY] = project.createVariant(PRIMARY_BREAKPOINT_ID, _projectReactorId, projectStateClone);
    // Initialize Project imports
    project.imports = {};
    // All projects have a reference to the main (root/host/global/etc) project.
    project.mainProject = mainProject || project;
    project.resourceRoot = resourceRoot || mainProject?.resourceRoot || '';
    project.basePath = basePath || mainProject?.basePath || '';
    // TODO:
    if (info) {
        // Funky cast to override readonly.
        project.info = { ...info }; // TODO: not updated as info changes (e.g. after Project save)
    }
    // Wait for all imports before updating Reactor prototypes which may come from them.
    perfTracker.startMark('imports');
    await importProjectsAndModules(project, playkit);
    perfTracker.endMark('imports');
    // Watch for changes to imports
    project.onPropertyChange(async (reactor, property, newValue, oldValue, type) => {
        property = property;
        if (property === 'Imports') {
            processImportChange(project, newValue, oldValue, playkit);
        }
    });
    // Init the project component (has to be done after Imports so that we can find Play Kit)
    perfTracker.startMark('project-init-component');
    initComponent(project, project);
    perfTracker.endMark('project-init-component');
    // Setup Components array
    const components = project.createReactor({});
    for (const name in state.Components) {
        const comp = createComponent(project, state.Components[name]);
        components[name] = comp;
    }
    project[PRIMARY].Components = components;
    // TODO: It's annoying to have to do this stuff here. Find a better way.
    // Setup pages
    project[PRIMARY].pages = project.createReactor(state.pages?.map((p) => {
        let pageBreakpointId;
        if (breakpointId) {
            // if a breakpoint ID is provided, use it.
            // however, if it's primary we need to set it to `undefined` so that it falls back to [PRIMARY].
            pageBreakpointId = breakpointId === PRIMARY_BREAKPOINT_ID ? undefined : breakpointId;
        }
        else {
            pageBreakpointId = determineBreakpointKey(viewportWidth, p);
        }
        return createComponent(project, p, pageBreakpointId, p.breakpoints);
    }) || []);
    //console.log('Loaded project', project);
    //console.log('Project state', project.getStoredState());
    project.switchBreakpoint = (breakpointId) => {
        // TODO: any reason to keep this vs just calling it on the rootView?
        const rootView = project.rootView;
        if (!rootView) {
            return;
        }
        rootView.switchBreakpoint(breakpointId);
    };
    if (state.customFonts) {
        project.customFonts = state.customFonts;
    }
    return project;
}
/**
 * Create (and init) a component from a bag of properties
 *
 * This also recurses and creates any child components.
 */
function createComponent(project, properties, breakpointId, breakpoints) {
    const component = project.createReactor({
        [ID]: properties[ID],
        componentType: properties.componentType,
    });
    component[INITIAL_STATE] = properties;
    component[PRIMARY] = project.createVariant(PRIMARY_BREAKPOINT_ID, component[ID], {
        componentType: properties.componentType,
    });
    component[PRIMARY][IS_PRIMARY_BREAKPOINT] = true;
    const Variants = {};
    for (const key in properties.variants) {
        Variants[key] = project.createVariant(key, component[ID], properties.variants[key]);
    }
    for (const key in breakpoints) {
        if (!Variants[key]) {
            Variants[key] = project.createVariant(key, component[ID]);
        }
    }
    // TODO: consider: should this be a Reactor too?
    component[PRIMARY].variants = Variants;
    if (breakpointId && component[PRIMARY].variants[breakpointId]) {
        component[ACTIVE_BREAKPOINT_ID] = breakpointId;
        component[ACTIVE_BREAKPOINT] = component[PRIMARY].variants[breakpointId];
    }
    // don't store componentType or style
    const { componentType, style, variants, ...props } = properties;
    for (const property in props) {
        let value = properties[property];
        if (['children', 'pages', 'effects'].includes(property)) {
            console.assert(!isReactor(value), 'children/pages/effects must be an array, not a Reactor');
            value = value === undefined ? [] : value;
            const arrayOfComponents = value.map((v) => createComponent(project, v, breakpointId, breakpoints));
            // createComponent is called by undo which needs children[ID]s to be stable.
            if (value[ID] !== undefined) {
                arrayOfComponents[ID] = value[ID];
            }
            // Note: this array will become a ReactorArray in promoteToComponent
            value = arrayOfComponents;
        }
        else if (Array.isArray(value)) {
            console.assert(!isReactor(value), 'property arrays must be a real arrays, not a ReactorArray');
            // Look for components in arrays
            value = value.map((v) => v.componentType ? createComponent(project, v, breakpointId, breakpoints) : v);
        }
        else if (isObjectNotArray(value)) {
            if (value[ID] !== undefined) {
                console.warn('Reactor ID in createComponent property', property);
            }
            if (value.componentType && !isComponent(value)) {
                value = createComponent(project, value, breakpointId, breakpoints);
            }
        }
        component[PRIMARY][property] = value;
    }
    initComponent(component, project);
    return component;
}
async function processImportChange(project, newValue, oldValue, playkit) {
    const newImports = newValue.reduce((obj, cur) => ({ ...obj, [cur.name]: cur }), {});
    const oldImports = oldValue.reduce((obj, cur) => ({ ...obj, [cur.name]: cur }), {});
    const allImportNames = new Set([...Object.keys(newImports), ...Object.keys(oldImports)]);
    const changedImports = new Set();
    // Diff the imports and take action
    for (const name of allImportNames) {
        const newImp = newImports[name];
        const oldImp = oldImports[name];
        if (newImp === undefined) {
            // Remove the import
            unimportProjectOrModule(oldImp, project);
            changedImports.add(name);
        }
        else if (!shallowEqual(newImp, oldImp)) {
            // Added or changed the import
            await importProjectOrModule(newImp, project, playkit);
            changedImports.add(name);
        }
    }
    // Re-init components from any change imports
    project.forEachReactor((component) => {
        if (isComponent(component)) {
            const { importPath } = parseComponentType(component.componentType);
            if (changedImports.has(importPath)) {
                initComponent(component, project);
            }
        }
        return true;
    });
}
// migrateProject alters the ProjectState in place.
export async function migrateProject(state) {
    perfTracker.startMark('migrate');
    const currentMigrations = state.appliedMigrations || [];
    await migrateProjectState(state);
    const errors = [];
    if (!isSafeToRunProject(state, errors)) {
        const error = new Error(`Unable to load project. It may have migrations applied to it that are known to another branch. ${JSON.stringify(errors)}`);
        console.error(error);
        throw error;
    }
    perfTracker.endMark('migrate');
    return {
        newMigrations: state.appliedMigrations?.filter((x) => !currentMigrations.includes(x)),
    };
}
function getImportProtocolAndPath(importInfo) {
    if (!importInfo.source) {
        return ['', ''];
    }
    const s = importInfo.source.split(':');
    return [s[0], s.slice(1).join(':')];
}
async function importProjectOrModule(importInfo, project, playkit) {
    const imports = project.imports;
    // This allows unit tests to 'shim' the import
    if (importInfo.loader) {
        return importInfo
            .loader()
            .then((module) => {
            imports[importInfo.name] = module;
        })
            .catch((err) => console.error(err));
    }
    const [protocol, path] = getImportProtocolAndPath(importInfo);
    if (!protocol) {
        return;
    }
    //console.log(`import ${importInfo.name} (${importInfo.source})`);
    switch (protocol) {
        // Handle playkit imports
        case 'playkit': {
            if (!playkit) {
                console.error(`playkit import requested but no playkit module provided`);
            }
            imports[importInfo.name] = playkit;
            return;
        }
        // Import components from subproject within this project's resource
        case 'project': {
            // resourceRoot is the root for importing the sub project
            const resourceRoot = pathJoin([project.resourceRoot, dirName(path)]);
            // Create a url for the sub project
            let url;
            if (importInfo.sourceHash) {
                url = pathJoin([project.mainProject.resourceRoot, `sha256:${importInfo.sourceHash}`]);
            }
            else {
                url = pathJoin([project.resourceRoot, path]);
            }
            return readRemoteProject(url)
                .then(({ state }) => importProjectState(state, importInfo, project, resourceRoot, playkit))
                .catch((err) => {
                console.error(err);
            });
        }
        default:
            console.error(`unknown import protocol: ${importInfo.source}`);
    }
}
function importProjectState(state, importInfo, project, resourceRoot, playkit) {
    const imports = project.imports;
    return loadProject({
        state,
        info: { title: importInfo.name },
        mainProject: project.mainProject,
        resourceRoot,
        playkit,
    }).then((importedProject) => {
        imports[importInfo.name] = importedProject;
    });
}
function unimportProjectOrModule(imp, project) {
    delete project.imports[imp.name];
}
function importProjectsAndModules(project, playkit) {
    const Imports = project.Imports;
    if (!Imports) {
        return Promise.resolve();
    }
    return Promise.allSettled(Imports.map((info) => importProjectOrModule(info, project, playkit)));
}
export async function readRemoteProject(url) {
    const response = await fetch(url);
    const jsonText = await response.text();
    const json = deserialize(jsonText);
    // Upgrade the project to the latest format.
    const { newMigrations } = await migrateProject(json);
    return { state: json, headers: response.headers, newMigrations };
}
