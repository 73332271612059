import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { customEvent } from '@playful/telemetry';
import { isObjectNotArray } from '@playful/utils';
import cloneDeep from 'lodash/cloneDeep';
import { proxy, snapshot, subscribe } from 'valtio';
import { derive, watch } from 'valtio/utils';
export var splitterSize = 1;
export var defaultFontSize = 12;
var defaultContentTab = 'kits';
var initialProjectWorkbenchState = {
  expandedNodes: [],
  lastUpdated: new Date().getTime()
};
var initialState = {
  workbench: {
    hasSeenKits: false,
    hasSeenWorkbenchTourTip: false,
    panelWidths: {
      javascriptInspector: 0
    }
  },
  projectWorkbenchStateCollection: {}
};
export var contentPanel = proxy({
  activeTab: defaultContentTab,
  isExpanded: false,
  childPanels: {},
  flashCount: 0,
  // increments if the user attempts to open a content panel already open
  tutorials: null,
  tutorialVideo: null
});
var inspectorPanelInitialState = {
  subPanel: undefined,
  isExpanded: true,
  addedEffect: undefined
};
export var inspectorPanel = proxy(inspectorPanelInitialState);
derive({
  width: function width(get) {
    var state = get(inspectorPanel);
    var width = state.isExpanded ? 312 : 0;
    if (state.subPanel === 'blocks') {
      width = 578;
    }
    return width;
  }
}, {
  proxy: inspectorPanel
});
watch(function (get) {
  var subPanel = get(inspectorPanel).subPanel;
  if (subPanel === 'blocks') {
    customEvent('vs-open');
  }
});
export function resetInspectorPanel() {
  var resetState = cloneDeep(inspectorPanelInitialState);
  Object.keys(resetState).forEach(function (key) {
    inspectorPanel[key] = resetState[key];
  });
}
export var objectTree = proxy({
  collapsedNodes: []
});
export var globalState = proxy(initialState);
export var designerHoverComponents = proxy({
  hoveredComponentIds: []
});

// Persist the global state as it changes.
// TODO: Global state we don't want persisted.
subscribe(globalState, function () {
  return saveGlobalState();
});
export function loadGlobalState() {
  if (typeof localStorage !== 'undefined') {
    var json = localStorage.getItem('globalState');
    if (json) {
      var loadedState = JSON.parse(json);
      mergeObjects(globalState, loadedState);
      mergeProjectWorkbenchState(globalState, loadedState);

      // Make sure everyone sees the kits at least once.
      if (!globalState.workbench.hasSeenKits) {
        globalState.workbench.hasSeenKits = true;
        contentPanel.activeTab = defaultContentTab;
      }
    }
  }
}
export function saveGlobalState() {
  localStorage.setItem('globalState', JSON.stringify(globalState, null, 2));
}

// Preserves the projectWorkbenchState when a project is copied
export function cloneProjectWorkbenchState(oldId, newProjectId) {
  if (globalState.projectWorkbenchStateCollection[oldId]) {
    globalState.projectWorkbenchStateCollection[newProjectId] = _objectSpread({}, globalState.projectWorkbenchStateCollection[oldId]);
  }
}

// Recursively merge object b's properties on top of object a's.
function mergeObjects(a, b) {
  for (var key in a) {
    if (key in b) {
      if (isObjectNotArray(a[key])) {
        mergeObjects(a[key], b[key]);
      } else {
        a[key] = b[key];
      }
    }
  }
}

// Merge the designState in a special-ish way to deal with the dynamic project indexing.
function mergeProjectWorkbenchState(a, b) {
  if (!b) {
    return;
  }
  for (var key in b.projectWorkbenchStateCollection) {
    if (!a.projectWorkbenchStateCollection[key]) {
      a.projectWorkbenchStateCollection[key] = initialProjectWorkbenchState;
    }
    for (var propertyKey in b.projectWorkbenchStateCollection[key]) {
      if (propertyKey in initialProjectWorkbenchState) {
        a.projectWorkbenchStateCollection[key][propertyKey] = b.projectWorkbenchStateCollection[key][propertyKey];
      }
    }
  }
}
export function toggleInspector(show) {
  var _snapshot = snapshot(inspectorPanel),
    isExpanded = _snapshot.isExpanded;
  if (isExpanded !== show) {
    customEvent(show ? 'inspector-expanded' : 'inspector-minimized');
    inspectorPanel.isExpanded = show;
  }
}
export function showInspectorPanel() {
  var subTab = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  inspectorPanel.isExpanded = true;
  inspectorPanel.subPanel = subTab;
  return;
}
export var hideContentPanel = function hideContentPanel() {
  return contentPanel.isExpanded = false;
};
export var showContentPanel = function showContentPanel() {
  return contentPanel.isExpanded = true;
};
export var toggleContentPanel = function toggleContentPanel() {
  return contentPanel.isExpanded = !contentPanel.isExpanded;
};