import { suggestName, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Add name to slideshow components if there isnt one',
    async migrate(state) {
        const pages = state.pages;
        pages?.forEach((page) => {
            const takenNames = [];
            forEachObject(page, (obj) => {
                obj.name && takenNames.push(obj.name);
            });
            forEachObject(page, (obj) => {
                if (obj.componentType === 'Play Kit/Slideshow' && !obj.name) {
                    const name = suggestName('slideshow', takenNames, true);
                    obj.name = name;
                    takenNames.push(name);
                }
            });
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
