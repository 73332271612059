import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'add unique uids to instructions',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            const needsChange = obj.instructions?.filter((i) => !i.uid || i.uid.length < 1).length > 0;
            if (needsChange) {
                // add unique ids to instructions
                const ins = obj.instructions?.map((i, index) => {
                    if (!i.uid) {
                        i.uid = nanoid(8);
                    }
                    return i;
                });
                obj.instructions = ins;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
// https://github.com/ai/nanoid/blob/main/non-secure/index.js
// This alphabet uses `A-Za-z0-9_-` symbols.
// The order of characters is optimized for better gzip and brotli compression.
// References to the same file (works both for gzip and brotli):
// `'use`, `andom`, and `rict'`
// References to the brotli default dictionary:
// `-26T`, `1983`, `40px`, `75px`, `bush`, `jack`, `mind`, `very`, and `wolf`
const urlAlphabet = 'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict';
const nanoid = (size = 21) => {
    let id = '';
    // A compact alternative for `for (var i = 0; i < step; i++)`.
    let i = size;
    while (i--) {
        // `| 0` is more compact and faster than `Math.floor()`.
        id += urlAlphabet[(Math.random() * 64) | 0];
    }
    return id;
};
registerMigration(migration, import.meta.url);
