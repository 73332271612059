import { useEffect, useState } from 'react';
export var useIsKeyboardOpen = function useIsKeyboardOpen() {
  var _useState = useState(false),
    open = _useState[0],
    setOpen = _useState[1];
  useEffect(function () {
    var _window2;
    var onResize = function onResize() {
      var _window;
      var visualViewportHeight = ((_window = window) === null || _window === void 0 || (_window = _window.visualViewport) === null || _window === void 0 ? void 0 : _window.height) || 0;
      if (visualViewportHeight < document.documentElement.clientHeight) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };
    (_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.visualViewport) === null || _window2 === void 0 || _window2.addEventListener('resize', onResize);
    return function () {
      var _window3;
      (_window3 = window) === null || _window3 === void 0 || (_window3 = _window3.visualViewport) === null || _window3 === void 0 || _window3.removeEventListener('resize', onResize);
    };
  }, []);
  return open;
};
export var useMobileScrollHacks = function useMobileScrollHacks() {
  var keyboardOpen = useIsKeyboardOpen();
  useEffect(function () {
    if (!keyboardOpen) {
      var _window4, _window4$scroll;
      (_window4 = window) === null || _window4 === void 0 || (_window4$scroll = _window4.scroll) === null || _window4$scroll === void 0 || _window4$scroll.call(_window4, 0, 0);
      setTimeout(function () {
        if (document.documentElement.scrollTop === 0) {
          var _window5, _window5$scroll;
          (_window5 = window) === null || _window5 === void 0 || (_window5$scroll = _window5.scroll) === null || _window5$scroll === void 0 || _window5$scroll.call(_window5, 0, 0);
        }
      }, 100);
    } else if (document.activeElement) {
      var _window6;
      /**
       * If the keyboard opens but Safari doesn't automatically scroll it into view,
       * manually scroll it into view.
       *
       * When the keyboard opens Safari sends a resize event where the visualViewport height
       * is the height of the viewport minus the keyboard.
       * If this height is equal to the client height we can deduce that Safari did not account
       * for the keyboard.
       * When this happens Safari will also send a followup event with the correct height but
       * without actually performing the scroll we desire.
       */
      var targetRect = document.activeElement.getBoundingClientRect();
      var halfWay = (((_window6 = window) === null || _window6 === void 0 || (_window6 = _window6.visualViewport) === null || _window6 === void 0 ? void 0 : _window6.height) || 1) * 0.95;
      if (targetRect.y > halfWay && document.documentElement.scrollTop === 0) {
        var _window7, _window7$scroll;
        (_window7 = window) === null || _window7 === void 0 || (_window7$scroll = _window7.scroll) === null || _window7$scroll === void 0 || _window7$scroll.call(_window7, 0, halfWay);
      }
    }
  }, [keyboardOpen]);
  var ignoreScroll = function ignoreScroll(event) {
    event.preventDefault();
    var el = event.target;
    if (el) {
      el.scrollTop = 0;
    }
  };
  return {
    ignoreScroll: ignoreScroll
  };
};