import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'upgrade the @ mention syntax to support spaces',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            const needsChange = obj.instructions?.filter((i) => i.prompt?.includes('@<')).length > 0;
            if (needsChange) {
                // change
                //   when @<|ID:584|> is clicked change @<|ID:584|effects|Play Kit/PhysicsProperties|impulseStrength|>
                // to
                //   when @ID:584 is clicked change @ID:584["effects"]["Play Kit/PhysicsProperties"]["impulseStrength"]
                const ins = obj.instructions?.map((i, index) => {
                    if (!i.prompt) {
                        return i;
                    }
                    i.prompt = i.prompt.replaceAll(/@<\|ID:.*?\>/g, (v) => {
                        const nv = v
                            .replace(/@<\|ID:(\d+)\|>/, '@ID:$1')
                            .replace(/@<\|ID:(\d+)\|/, "@ID:$1['")
                            .replace('|>', "']")
                            .split('|')
                            .join("']['");
                        // console.log('replacing prompt', v, 'with', nv);
                        return nv;
                    });
                    return i;
                });
                obj.instructions = ins;
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
