import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: `Remove transform styles from Projects. Previously they would have been overwritten
    by our own transform style, but we removed it when adding position fixed component
    functionality, so transforms suddenly showed up with unexpected results.`,
    async migrate(state) {
        const project = state;
        if (project.componentType === 'Play Kit/Project' && project.style?.transform) {
            delete project.style.transform;
        }
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
