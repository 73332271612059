import { useEffect, useState } from 'react';
export function useOnReactorArrayChange(reactorArray) {
    const [change, setChange] = useState();
    useEffect(() => {
        if (!reactorArray) {
            return;
        }
        function handleUpdate(reactor, property, newValue, oldValue, type) {
            setChange({ property, newValue, oldValue, type });
        }
        const listener = reactorArray?.onPropertyChange?.(handleUpdate);
        return () => {
            listener?.dispose();
        };
    }, [reactorArray]);
    if (reactorArray && change) {
        return change;
    }
    return { property: undefined, newValue: undefined, oldValue: undefined, type: undefined };
}
