import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'Migrate projects to use Sections as the only allowed children of Pages',
    draft: false,
    async migrate(state) {
        log(`Migrated ${migration.description}`);
        // get the highest ID and increment it to find the next ID
        const nextId = {
            id: findHighestId(state),
        };
        function getNextId() {
            nextId.id++;
            return nextId.id;
        }
        // collect all the pageIDs
        const pageIds = state?.pages?.map((page) => page[ID]) ?? [];
        // get ready to store the new page IDs with their new Section ID
        const pageIdObject = pageIds.reduce((acc, pageId, index) => {
            acc[pageId] = getNextId();
            return acc;
        }, {});
        forEachObject(state, (obj) => {
            if (!!obj?.javascript) {
                // if the object has javascript, replace all instances of page IDs with the new Section ID
                obj.javascript = replacePageAccessWithSectionAccess(obj.javascript, pageIdObject, pageIds);
            }
            if (!!obj?.instructions) {
                // find and replace js style references to the page in the instructions
                obj.instructions.forEach((instruction) => {
                    if (!!instruction?.javascript) {
                        instruction.javascript = replacePageAccessWithSectionAccess(instruction.javascript, pageIdObject, pageIds);
                    }
                });
            }
            if (!!obj?.formula && obj?.formula?.value) {
                // Same with formulas, replace page access with section
                obj.formula.value = replacePageAccessWithSectionAccess(obj.formula.value, pageIdObject, pageIds);
            }
            if ('targetId' in obj) {
                // If there is a `targetId` and the targetId is a page, replace it with the new Section ID
                if (obj.targetId !== -1 && pageIdObject[obj.targetId]) {
                    obj.targetId = pageIdObject[obj.targetId];
                }
            }
        });
        // If there was a block setting the background of a page, we need to retarget it back to the page.
        function retargetBackgrounds(blocks, sectionId, pageId, retargetNegativeOne = false) {
            blocks.forEach((block) => {
                if (JSON.stringify(block).includes('background') &&
                    (block.type === 'setProperty' || block.type === 'toggle')) {
                    if ((retargetNegativeOne && block.targetId === -1) || block.targetId === sectionId) {
                        block?.actions?.forEach((action) => {
                            if ((action.targetId === -1 || action.targetId === sectionId) &&
                                action.args &&
                                (action.args.backgroundColor || action.args.background)) {
                                block.targetId = pageId;
                                action.targetId = pageId;
                            }
                        });
                        block?.presets?.forEach((preset) => {
                            preset?.actions?.forEach((action) => {
                                if (((retargetNegativeOne && action.targetId === -1) ||
                                    action.targetId === sectionId) &&
                                    action.args &&
                                    (action.args.backgroundColor || action.args.background)) {
                                    block.targetId = pageId;
                                    action.targetId = pageId;
                                }
                            });
                        });
                    }
                }
                if (block.blocks) {
                    retargetBackgrounds(block.blocks, sectionId, pageId, retargetNegativeOne);
                }
            });
        }
        state.pages?.forEach((page, index) => {
            if (pageNeedsSection(page)) {
                const newSection = newSectionState(page, pageIdObject[page[ID]], getNextId);
                if (newSection?.blocks) {
                    retargetBackgrounds(newSection?.blocks, newSection[ID], page[ID], true);
                }
                if (newSection?.children) {
                    newSection?.children?.forEach((child) => {
                        if (child?.blocks) {
                            // if the child has blocks, retarget the backgrounds if they reference the section.
                            // don't retarget if they reference -1
                            retargetBackgrounds(child.blocks, newSection[ID], page[ID], false);
                        }
                    });
                }
                page.children = [newSection];
                page.instructions = undefined;
                page.blocks = undefined;
                page.javascript = undefined;
                page.effects = undefined;
                page.style = {
                    componentType: 'Play Kit/Style',
                    [ID]: getNextId(),
                };
                page.physicsEnabled = false;
            }
        });
    },
};
function findHighestId(state) {
    let highestId = 0;
    forEachObject(state, (obj) => {
        if (obj[ID] > highestId) {
            highestId = obj[ID];
        }
    });
    return highestId;
}
function newSectionState(page, id, getNextId) {
    const { [ID]: __id, name, style, apiStyle, effects, javascript, ...pageState } = page;
    let newStyle = {};
    let newApiStyle = {};
    if (style) {
        const { [ID]: __styleId, ...restStyle } = style;
        newStyle = {
            [ID]: getNextId(),
            ...restStyle,
        };
    }
    if (apiStyle) {
        const { [ID]: __styleApiId, ...restApiStyle } = apiStyle;
        newApiStyle = {
            [ID]: getNextId(),
            ...restApiStyle,
        };
    }
    return {
        ...pageState,
        [ID]: id,
        x: 0,
        y: 0,
        name: `section`,
        componentType: 'Play Kit/Section',
        backgroundColor: 'transparent',
        children: page.children ?? [],
        overflow: 'show',
        javascript,
        effects,
        style: newStyle,
        apiStyle: newApiStyle,
    };
}
function pageNeedsSection(page) {
    return ((page.children?.[0] && page.children?.[0]?.componentType !== 'Play Kit/Section') ||
        !page.children?.[0]);
}
function replacePageAccessWithSectionAccess(text, pageIdObject, pageIds) {
    if (typeof text !== 'string') {
        // sometimes javascript can be an object?!
        return text;
    }
    let newJavascript = text.replace(/get\((\d+).*?\)\.(children|appendChild|insertChild|removeChild)/g, (match, number, method) => {
        // if it a page? If so find the index of the page ID and replace it with the new Section ID
        const pageIndex = pageIds.indexOf(parseInt(number, 10));
        if (pageIndex !== -1) {
            return `get(${pageIdObject[number]} /* Section */).${method}`;
        }
        else {
            // not page, dont change anything
            return match;
        }
    });
    // replace direct references to the page.methodName with section.methodName
    newJavascript = newJavascript.replaceAll(/this\.page\.(children|appendChild|insertChild|removeChild)/g, (match, method) => {
        return `this.section.${method}`;
    });
    // replace all calls to this.page, but only if it's not dealing with the background
    newJavascript = newJavascript.replaceAll(/this\.page\b(?!.*background*.)/g, 'this.section');
    // replace all js calls to @pageName with @sectionName
    Object.keys(pageIdObject).forEach((pageId) => {
        newJavascript = newJavascript.replace(new RegExp(`get\\(${pageId}(?:\\s*\\/\\*.*?\\*\\/)?\\s*\\)(?!.*background*.)`, 'g'), `get(${pageIdObject[pageId]} /* Section */)`);
    });
    newJavascript = newJavascript.replace(/project.currentPage\b/g, 'this.section');
    return newJavascript;
}
registerMigration(migration, import.meta.url);
