import { forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'removing a single Condition from conditionalBlock.conditions, always expect JSExpression or array of Conditions',
    draft: false,
    async migrate(state) {
        forEachObject(state, (obj) => {
            if (obj.condition?.type === 'condition') {
                const condition = obj.condition;
                obj.condition = { type: 'conditions', operator: 'and', conditions: [condition] };
            }
        });
        log(`Migrated ${migration.description}`);
    },
};
registerMigration(migration, import.meta.url);
