import { defineStyleConfig } from '@chakra-ui/react';
export const linkTheme = defineStyleConfig({
    defaultProps: {
        colorScheme: 'purple',
    },
    baseStyle: ({ colorScheme }) => {
        let linkColor = 'purple.700';
        let linkHoverColor = 'purple.600';
        let linkFocusColor = 'purple.500';
        let linkDisabledColor = 'purple.400';
        if (colorScheme) {
            linkColor = `${colorScheme}.700`;
            linkHoverColor = `${colorScheme}.600`;
            linkFocusColor = `${colorScheme}.500`;
            linkDisabledColor = `${colorScheme}.400`;
        }
        return {
            transitionProperty: 'common',
            transitionDuration: 'fast',
            transitionTimingFunction: 'ease-out',
            cursor: 'pointer',
            textDecoration: 'none',
            outline: 'none',
            color: linkColor,
            _hover: {
                textDecoration: 'underline',
                color: linkHoverColor,
            },
            _focus: { boxShadow: 'none', color: linkFocusColor },
            _focusVisible: {
                outline: '0.125rem solid',
                outlineColor: 'gray.800',
                outlineOffset: '0.125rem',
            },
            _disabled: {
                color: linkDisabledColor,
            },
        };
    },
    variants: {
        underline: {
            textDecoration: 'underline',
        },
        'rebrand-link': ({ colorScheme }) => {
            return {
                color: 'purple.700',
                _hover: {
                    color: 'purple.600',
                },
                _active: {
                    color: 'purple.500',
                },
            };
        },
    },
    sizes: {
        lg: {
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 'medium',
        },
    },
});
