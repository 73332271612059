export const ToolbarButtonAlignmentToken = '__toolbar_button_alignment__';
export const ToolbarButtonLinkToken = '__toolbar_button_link__';
export const ToolbarButtonRTELinkToken = '__toolbar_button_rte_link__';
export const ToolbarButtonDeleteToken = '__toolbar_button_delete__';
export const ToolbarButtonDuplicateToken = '__toolbar_button_duplicate__';
export const ToolbarButtonOrderToken = '__toolbar_button_order__';
export const ToolbarButtonDividerToken = '__toolbar_button_divider__';
export const ToolbarButtonReplaceMediaToken = '__toolbar_button_replace_media__';
export const ToolbarCropMediaToken = '__toolbar_button_crop_media__';
export const ToolbarButtonReplaceEmbedToken = '__toolbar_button_replace_embed__';
export const ToolbarButtonSortContainerComponentsToken = '__toolbar_button_sort_container_components__';
export const ToolbarButtonBoldToken = '__toolbar_button_bold__';
export const ToolbarButtonItalicToken = '__toolbar_button_italic__';
export const ToolbarButtonUnderlineToken = '__toolbar_button_underline__';
export const ToolbarButtonColorPickerToken = '__toolbar_button_colorpicker__';
export const ToolbarButtonTextAlignToken = '__toolbar_button_textalign__';
export const ToolbarButtonListToken = '__toolbar_button_list__';
export const ToolbarButtonDrawToken = '___toolbar_button_draw__';
export const ToolbarButtonMultiSelect = '___toolbar_multi_select__';
export const ToolbarButtonContainToken = '___toolbar_button_contain__';
export const ToolbarButtonAddSectionToken = '___toolbar_button_add_section__';
export const ToolbarFontSelectToken = '___toolbar_font_select__';
export const ToolbarFontSizeToken = '___toolbar_font_size__';
export const ToolbarConvertToSectionToken = '___toolbar_convert_to_section__';
export function isObjectToolbarButtonObject(button) {
    return typeof button === 'object' && 'token' in button;
}
