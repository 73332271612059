export const MIGRATOR_DEBUG = false;
const PREFIX = 'Migrator: ';
export const debug = (...args) => MIGRATOR_DEBUG && console.debug(PREFIX, ...args);
export const error = (...args) => MIGRATOR_DEBUG && console.error(PREFIX, ...args);
export const group = (...args) => MIGRATOR_DEBUG && console.group(PREFIX, ...args);
export const groupCollapsed = (...args) => MIGRATOR_DEBUG && console.groupCollapsed(PREFIX, ...args);
export const groupEnd = () => MIGRATOR_DEBUG && console.groupEnd();
export const info = (...args) => MIGRATOR_DEBUG && console.info(PREFIX, ...args);
export const log = (...args) => MIGRATOR_DEBUG && console.log(PREFIX, ...args);
export const trace = (...args) => MIGRATOR_DEBUG && console.trace(PREFIX, ...args);
export const warn = (...args) => MIGRATOR_DEBUG && console.warn(PREFIX, ...args);
