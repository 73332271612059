import { ID, forEachObject } from '@playful/utils';
import { log } from '../debug.js';
import { registerMigration } from '../registry.js';
const migration = {
    description: 'This migration fixes an issue with the Space Explorer project which was compensating for a bug in viewGeometry',
    draft: true,
    async migrate(state) {
        log(`Migrated ${migration.description}`);
        const pageIds = state?.pages?.map((page) => page[ID]) ?? [];
        forEachObject(state, (obj) => {
            if (!!obj?.javascript && pageIds.includes(obj[ID])) {
                // SPACE EXPLORE HACKY FIX
                obj.javascript = obj.javascript.replace(/const topLeft = this\.parent\.localPointFromClient\(0,0\)\s*const x = topLeft\.x \* 0\.5\s*const y = topLeft\.y \* 0\.5/g, '' +
                    'const topLeft = this.parent.localPointFromClient(0,0)\n' +
                    'const x = topLeft.x\n' +
                    'const y = topLeft.y\n');
            }
        });
    },
};
registerMigration(migration, import.meta.url);
