import { logRocketGetSessionURL } from '../services/logrocket.js';
export function shareSession() {
    const { heap, gtag, rudderanalytics } = window;
    logRocketGetSessionURL((sessionURL) => {
        heap?.track('LogRocket', { sessionURL });
        gtag?.('event', 'sessionURL', {
            eventCategory: 'LogRocket',
            sessionURL,
        });
        rudderanalytics?.track('LogRocket', { url: sessionURL });
    });
}
